// @import dependencies
import React, { CSSProperties, useState } from 'react';
import moment from 'moment';
// @end dependencies

// @import components
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import TextComponent from 'components/commons/Text/TextComponent';
import { Link } from 'react-router-dom';
import UpdateDevicesModal from '../UpdateDevicesModal/UpdateDevicesModal';
// @end components

// @import types
// @end types

// @import services
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './DeviceCard.scss';
import PermissionsService from 'services/permissions/permissionsService';
// @end styles

interface IDeviceCardProps {
  macAddress: string;
  color: string;
  name: string;
  variable1: VariableDeviceCard;
  variable2: VariableDeviceCard;
  deviceStatus?: string;
  deviceId: string;
  lastDate?: Date;
  className?: string;
  style?: CSSProperties;
  id?: string;
}

export interface VariableDeviceCard {
  name: string;
  value: string;
}

const DeviceCard: React.FC<IDeviceCardProps> = (props) => {
  const [modalShow, setModalShow] = useState(false);

  // @INFO: Services
  const permissionsService = new PermissionsService();

  return (
    <div
      className={`device_card-layout ${props.className ? props.className : ''}`}
      style={{
        ...props.style,
        backgroundColor: props.color,
      }}
      id={props.id}
    >
      <Link to={`/device/${props.deviceId}`} style={{ textDecoration: 'none' }}>
        <div className="container-info-device">
          <div>
            <TextComponent type="label">{props.variable1.name}</TextComponent>
            <TextComponent type="h1" style={{ fontWeight: 500 }}>
              {props.variable1.value}
            </TextComponent>
            <TextComponent type="label">Estado: </TextComponent>
            <TextComponent type="h3" style={{ fontWeight: 500 }}>
              {props.deviceStatus || 'offline'}
            </TextComponent>
          </div>
          <div>
            <TextComponent type="h3" style={{ fontWeight: 500 }}>
              {props.variable2.value}
            </TextComponent>
            <TextComponent type="label">{props.variable2.name}</TextComponent>
            <TextComponent type="label">Fecha último dato</TextComponent>
            <TextComponent type="label">
              {moment(props.lastDate).format('YYYY-MM-DD HH:mm')}
            </TextComponent>
          </div>
        </div>
      </Link>
      <div className="container-name-device">
        <Link
          to={`/device/${props.deviceId}`}
          style={{ textDecoration: 'none' }}
        >
          <TextComponent type="subtitle">{props.name}</TextComponent>
        </Link>
        {permissionsService.check('devices_management_menu') && (
          <ButtonComponent
            variant="primary"
            type="button"
            style={{
              backgroundColor: props.color || 'cyan',
              borderColor: props.color || 'cyan',
              marginLeft: 'auto',
            }}
            onClick={() => setModalShow(true)}
          >
            Modificar
          </ButtonComponent>
        )}
        {modalShow && permissionsService.check('devices_management_menu') && (
          <UpdateDevicesModal
            deviceId={props.deviceId}
            macAddress={props.macAddress}
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        )}
      </div>
    </div>
  );
};

export default DeviceCard;
