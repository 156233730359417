import store from 'redux/store';
import HTTPUtil from 'utils/http/http.util';
import { DeviceCategory } from 'types/deviceCategory/deviceCategory.types';

export class DeviceCategoryService {
  private storeObject = store;

  async getAll() {
    const response = await HTTPUtil.send(
      {
        url: '/deviceCategory/all/',
        method: 'GET',
        headers: {
          Authorization: this.storeObject.getState()?.auth?.token,
        },
      },
      {
        alertOnFailed: true,
      }
    );

    if (response?.status === 200) {
      return response;
    } else {
      return undefined;
    }
  }
  getByName = async (name: string): Promise<DeviceCategory | undefined> => {
    const response = await HTTPUtil.send(
      {
        url: '/deviceCategory/ByName/',
        method: 'POST',
        data: { name },
        headers: {
          Authorization: this.storeObject.getState()?.auth?.token,
        },
      },
      {
        alertOnFailed: true,
      }
    );

    if (response?.status === 200) {
      return response.data;
    } else {
      return undefined;
    }
  };
}
