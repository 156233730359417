// @import dependencies
import React, { CSSProperties, useState } from 'react';
// @end dependencies

// @import components
import HeaderModule from 'components/modules/HeaderModule/HeaderModule';
import SidebarModule from 'components/modules/SidebarModule/SidebarModule';
// @end components

// @import types
// @end types

// @import services
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './AppLayout.scss';
// @end styles

interface IAppLayoutProps {
  children?: any;
  history?: any;
  className?: string;
  hideMenu?: boolean;
  hideProfile?: boolean;
  style?: CSSProperties;
  id?: string;
}

const AppLayout: React.FC<IAppLayoutProps> = (props) => {
  const [openSidebar, updateOpenSidebar] = useState<boolean>(false);

  return (
    <div
      className={`app_layout-layout ${props.className ? props.className : ''}`}
      style={props.style}
      id={props.id}
    >
      <div className={`sidebar-container ${openSidebar ? 'open' : ''}`}>
        <SidebarModule
          hide={!openSidebar}
          showCloseButton
          onClose={() => updateOpenSidebar(false)}
        />
      </div>
      <div className="content-page">
        <HeaderModule
          hideMenu={props.hideMenu || openSidebar}
          hideProfile={props.hideProfile}
          onClickOpen={() => updateOpenSidebar(!openSidebar)}
        />
        <div className="container-children-app-layout">{props.children}</div>
      </div>
    </div>
  );
};

export default AppLayout;
