// @import dependencies
import React, { CSSProperties, useState, useEffect } from 'react';
import shortid from 'shortid';
import { toast } from 'react-toastify';
// @end dependencies

// @import components
import TableComponent from '../../commons/TableComponent/TableComponent';
import { UpdateLogModal } from '../UpdateLogModal/UpdateLogModal';
import CheckBoxComponent from 'components/commons/CheckBoxComponent/CheckBoxComponent';
// @end components

// @import types
import * as DeviceLoggerTypes from 'types/deviceLogger/deviceLogger.types';
// @end types

// @import services
import DeviceLoggerService from 'services/deviceLogger/deviceLogger.service';
import { DeviceLoggerFunctions } from './DeviceLoggerFunctions';
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './DeviceLoggerModule.scss';
// @end styles

interface IDeviceCardProps {
  macAddress?: string;
  className?: string;
  style?: CSSProperties;
  id?: string;
}

export interface VariableDeviceCard {
  name: string;
  value: string;
}

const DeviceLoggerModule: React.FC<IDeviceCardProps> = (props) => {
  const [showModal, setShowModal] = useState(false);

  const [deviceLoggers, updateDeviceLoggers] = useState<
    DeviceLoggerTypes.DeviceLogger[]
  >([]);

  const [currentDeviceLogger, setCurrentDeviceLogger] =
    useState<DeviceLoggerTypes.DeviceLogger>();

  // @INFO Services
  const deviceLoggerService = new DeviceLoggerService();
  const deviceLoggerFunctions = new DeviceLoggerFunctions();

  useEffect(() => {
    getDeviceLogger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.macAddress]);

  const getDeviceLogger = async () => {
    const response = await deviceLoggerService.getByMacAddress(
      props.macAddress || ''
    );
    updateDeviceLoggers(response);
  };

  const handleUpdateDeviceLogger = async (
    item: DeviceLoggerTypes.DeviceLogger
  ) => {
    const log = deviceLoggers.find((d) => d._id === item._id);
    if (log) {
      setCurrentDeviceLogger({
        ...log,
        failures: log.failures.map((f) => ({ ...f })),
      });
      setShowModal(true);
    }
  };

  const handleUpdate = async (item: DeviceLoggerTypes.DeviceLogger) => {
    const response = await deviceLoggerService.update({
      id: item._id,
      macAddress: props.macAddress ?? '',
      ...item,
    });
    if (response) {
      toast.success('Logger actualizado correctamente');
    }
    getDeviceLogger();
  };

  const onHideModal = () => {
    setShowModal(false);
  };

  return (
    <div
      className={`device_logger-layout ${
        props.className ? props.className : ''
      }`}
      style={props.style}
      id={props.id}
    >
      <TableComponent
        keys={deviceLoggerFunctions.getKeysTable()}
        showEdit
        onClickEdit={handleUpdateDeviceLogger}
        rows={deviceLoggers.map(({ failures, ...rest }) => ({
          ...rest,
          failures: failures.map(({ name, label, hasPresented }) => (
            <CheckBoxComponent
              key={shortid.generate()}
              label={label}
              checked={hasPresented}
              disabled={true}
            />
          )),
        }))}
      />
      {showModal && currentDeviceLogger && (
        <UpdateLogModal
          show={showModal}
          onHide={onHideModal}
          deviceLogger={currentDeviceLogger}
          setDeviceLogger={setCurrentDeviceLogger}
          onUpdate={handleUpdate}
        />
      )}
    </div>
  );
};

export default DeviceLoggerModule;
