// @import dependencies
import React, { CSSProperties, useState, useEffect } from 'react';
// @end dependencies

// @import components
import TextComponent from 'components/commons/Text/TextComponent';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import UserService from 'services/user/user.service';
import TextFieldComponent from 'components/commons/TextFieldComponent/TextFieldComponent';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
// @end components

// @import types
import { StoreApp } from 'redux/reducers/index';
// @end types

// @import services
// @end services

// @import hooks
import { useSelector } from 'react-redux';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './DeveloperSettingsModule.scss';

// @end styles
interface IDeveloperSettingsModule {
  className?: string;
  style?: CSSProperties;
  id?: string;
  isAdmin?: boolean;
}

const DeveloperSettingsModule: React.FC<IDeveloperSettingsModule> = (props) => {
  const [role, setRole] = useState('User');
  const [integrationToken, setIntegrationToken] = useState('');
  const [wasCopied, setWasCopied] = useState(false);
  const [open, setOpen] = useState(false);
  const user = useSelector((store: StoreApp) => store.auth?.user);

  // @INFO: Services
  const userService = new UserService();

  useEffect(() => {
    user?.role && setRole(user?.role);
    getIntegrationToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.role]);

  const getIntegrationToken = async () => {
    const response = await userService.getIntegrationToken();
    if (response) {
      setIntegrationToken(response.data?.token || '');
    }
  };

  const generateIntegrationToken = async () => {
    const response = await userService.generateIntegrationToken(role);
    if (response) {
      setIntegrationToken(response.data?.token || '');
    }
  };

  const handleCopy = () => {
    setOpen(true);
    setWasCopied(true);
    navigator.clipboard.writeText(integrationToken);
  };

  return (
    <div
      className={`developer_settings-layout ${
        props.className ? props.className : ''
      }`}
      style={props.style}
      id={props.id}
    >
      <TextComponent type="h3" style={{ textAlign: 'center', width: '100%' }}>
        Opciones de desarrollador
      </TextComponent>
      <TextComponent>Generar token de integración</TextComponent>
      <div className="generate-token">
        <FormControl>
          <InputLabel id="demo-simple-select-label">Rol / Cargo</InputLabel>
          <Select
            value={role}
            label="Rol / Cargo"
            onChange={(e) => setRole(e.target.value)}
          >
            <MenuItem value={'Admin'}>Administrador</MenuItem>
            <MenuItem value={'User'}>Usuario</MenuItem>
            <MenuItem value={'SuperAdmin'}>Super Administrador</MenuItem>
          </Select>
        </FormControl>
        <ButtonComponent onClick={generateIntegrationToken}>
          Generar Token
        </ButtonComponent>
      </div>
      {integrationToken.length > 0 && (
        <TextFieldComponent
          label="Token"
          value={integrationToken}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleCopy}>
                  <ContentCopyRoundedIcon
                    color={wasCopied ? 'primary' : undefined}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message="Copiado al portapapeles"
      />
    </div>
  );
};

export default DeveloperSettingsModule;
