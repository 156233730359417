// @import dependencies
import React, { CSSProperties } from 'react';
// @end dependencies

// @import components
import BreadcrumbComponent from 'components/commons/Breadcrumb/BreadcrumbComponent';
import AppLayout from 'components/layouts/AppLayout/AppLayout';
import UserManagementModule from 'components/modules/UserManagementModule/UserManagementModule';
// @end components

// @import types
// @end types

// @import services
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './UserManagementScreen.scss';

// @end styles
interface IUserManagementProps {
  className?: string;
  style?: CSSProperties;
  id?: string;
}

const UserManagementScreen: React.FC<IUserManagementProps> = (props) => {
  return (
    <AppLayout>
      <div
        className={`user_management_screen-layout ${
          props.className ? props.className : ''
        }`}
        style={props.style}
        id={props.id}
      >
        <BreadcrumbComponent
          Title="Administración"
          Item_one="Usuarios"
          Item_two="Administración"
          disabled_Title={false}
          disabled_Item_one={false}
          disabled_Item_two={true}
        />
        <UserManagementModule />
      </div>
    </AppLayout>
  );
};

export default UserManagementScreen;
