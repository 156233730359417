import { ITypeThemeApp } from 'types/app/app.types';
import * as appTypes from 'types/app/app.types';

/**
 * @INFO actualizar el tema de la aplicación
 * @param type
 * @returns
 */
export const updateThemeAppAction =
  (type: ITypeThemeApp) => (dispatch: Function) => {
    dispatch(updateThemeApp(type));
  };

const updateThemeApp = (type: ITypeThemeApp) => ({
  type: appTypes.UPDATE_THEME,
  payload: type,
});
