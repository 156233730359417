// @import dependencies
import React, { CSSProperties, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
// @end dependencies

// @import components
import TableComponent, {
  IKeyTable,
} from 'components/commons/TableComponent/TableComponent';
import ButtonComponent from 'components/commons/Button/ButtonComponent';
// @end components

// @import types
import * as BannerTypes from 'types/banners/banners.types';
// @end types

// @import services
import BannerService from 'services/banner/banner.service';
// @end services

// @import hooks
import { Link, useHistory } from 'react-router-dom';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './BannerManagementModule.scss';
// @end styles

interface IBannerManagementModuleProps {
  className?: string;
  style?: CSSProperties;
  id?: string;
}

const keys: IKeyTable[] = [
  {
    key: 'image',
    name: 'Imagen',
    type: 'image',
  },
  {
    key: 'name',
    name: 'Nombre',
  },
  {
    key: 'description',
    name: 'Descripción',
  },
  {
    key: 'startDate',
    name: 'Fecha inicio',
    formatMoment: 'DD/MM/YYYY',
  },
  {
    key: 'finishDate',
    name: 'Fecha finalización',
    formatMoment: 'DD/MM/YYYY',
  },
];

const BannerManagementModule: React.FC<IBannerManagementModuleProps> = (
  props
) => {
  const [banners, setBanners] = useState<BannerTypes.Banner[]>([]);
  const history = useHistory();

  // @INFO: Services
  const bannerService = new BannerService();

  useEffect(() => {
    getAllBanners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllBanners = async () => {
    const response = await bannerService.getAll();
    if (response) {
      setBanners(response.data.banners);
    }
  };
  const handleDelete = async (id: string) => {
    const response = await bannerService.deleteBanner(id);
    if (response) {
      getAllBanners();
      toast.success('Banner eliminado correctamente');
    }
  };

  return (
    <div
      className={`banner_management-layout ${
        props.className ? props.className : ''
      }`}
      style={props.style}
      id={props.id}
    >
      <div className="new-user-menu">
        <Link to={'/banners/register'} className="link">
          <ButtonComponent>Crear nuevo</ButtonComponent>
        </Link>
      </div>
      <TableComponent
        keys={keys}
        rows={
          banners.length > 0
            ? banners.map((banner) => ({ ...banner, banner }))
            : []
        }
        showEdit
        showDelete
        onClickEdit={(data: any) =>
          history.push('/banners/register', { banner: data?.banner })
        }
        onClickDelete={(data: any) => handleDelete(data.banner?.id)}
      />
    </div>
  );
};

export default BannerManagementModule;
