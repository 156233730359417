// @import dependencies
import React, { CSSProperties, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import shortid from 'shortid';
// @end dependencies

// @import components
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TextComponent from 'components/commons/Text/TextComponent';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
// @end components

// @import types
import { StoreApp } from 'redux/reducers/index';
// @end types

// @import services
// @end services

// @import hooks
import { useSelector } from 'react-redux';
// @end hooks

// @import actions
// @end actions

// @import utils
import MENUITEMS from './menu';
// @end utils

// @import assets
// @end assets

// @import styles
import './SidebarModule.scss';
import PermissionsService from 'services/permissions/permissionsService';

// @end styles

interface ISidebarModuleProps {
  className?: string;
  style?: CSSProperties;
  id?: string;
  hide: boolean;
  showCloseButton?: boolean;
  onClose?: () => void;
  window?: () => Window;
}
const drawerWidth = 270;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  position: 'relative',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));
const SidebarModule: React.FC<ISidebarModuleProps> = (props) => {
  const user = useSelector((store: StoreApp) => store.auth?.user);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [menuList, setMenuList] = React.useState<boolean[]>(
    Array(MENUITEMS.filter((menu) => menu.type === 'sub').length).fill(false)
  );
  const { window } = props;
  const container =
    window !== undefined ? () => window().document.body : undefined;

  // @INFO: Services
  const permissionService = new PermissionsService();

  const handleDrawerToggle = () => {
    setOpen(!open);
    props.onClose && open && props.onClose();
  };

  useEffect(() => {
    setOpen(!props.hide);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.hide]);

  const handleCollapseList = (index: number) => {
    const collapseList: boolean[] = [];
    collapseList.push(...menuList);
    collapseList[index] = !collapseList[index];
    setMenuList(collapseList);
  };

  const drawer = (
    <div className="drawer">
      <DrawerHeader>
        <div className="container-picture-user">
          <img
            src={
              user?.userPicture?.length
                ? user.userPicture
                : 'https://static.vecteezy.com/system/resources/previews/002/275/847/original/male-avatar-profile-icon-of-smiling-caucasian-man-vector.jpg'
            }
            alt="header-user"
          />
          <TextComponent>
            {user?.firstName} {user?.lastName}
          </TextComponent>
        </div>
        <IconButton onClick={props.onClose}>
          {theme.direction === 'ltr' ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <List>
        {MENUITEMS.map((item, index) =>
          item.type === 'sub'
            ? permissionService.checkList(item.permissions || []) && (
                <div key={shortid.generate()}>
                  <ListItemButton
                    className="menu-item"
                    onClick={() => handleCollapseList(index)}
                  >
                    <ListItemIcon>
                      {<item.icon color={'primary'} />}
                    </ListItemIcon>
                    <ListItemText primary={item.title} />
                    {menuList[index] ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={menuList[index]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {item.children?.map(
                        (child) =>
                          permissionService.checkList(
                            child.permissions || []
                          ) && (
                            <NavLink
                              activeClassName="link-activate"
                              to={`${child.path}`}
                              className="link"
                              key={shortid.generate()}
                            >
                              <ListItemButton
                                className="menu-item"
                                sx={{ pl: 4 }}
                              >
                                <ListItemIcon>
                                  {child.icon && (
                                    <child.icon color={'primary'} />
                                  )}
                                </ListItemIcon>
                                <ListItemText
                                  primary={child.title}
                                  sx={{ margin: '0px 0px' }}
                                />
                              </ListItemButton>
                            </NavLink>
                          )
                      )}
                    </List>
                  </Collapse>
                </div>
              )
            : item.type === 'link' &&
              permissionService.checkList(item.permissions || []) && (
                <NavLink
                  activeClassName="link-activate"
                  to={`${item.path}`}
                  className="link"
                  key={shortid.generate()}
                >
                  <ListItem key={item.title} disablePadding>
                    <ListItemButton className="menu-item">
                      <ListItemIcon>
                        {<item.icon color={'primary'} />}
                      </ListItemIcon>
                      <ListItemText primary={item.title} />
                    </ListItemButton>
                  </ListItem>
                </NavLink>
              )
        )}
      </List>
    </div>
  );

  return (
    <div
      className={`sidebar_module-layout ${
        props.className ? props.className : ''
      }`}
      id={props.id}
    >
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={open}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          sx={{
            display: { xs: 'none', sm: 'block' },
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          {drawer}
        </Drawer>
      </Box>
    </div>
  );
};

export default SidebarModule;
