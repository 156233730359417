// @import dependencies
import React, { CSSProperties } from 'react';
// @end dependencies

// @import components
import BreadcrumbComponent from 'components/commons/Breadcrumb/BreadcrumbComponent';
import AppLayout from 'components/layouts/AppLayout/AppLayout';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import BannerRegistrationModule from 'components/modules/BannerRegistrationModule/BannerRegistrationModule';
// @end components

// @import types
import * as BannerTypes from 'types/banners/banners.types';
// @end types

// @import services
// @end services

// @import hooks
// @end hooks

// @import actions
import { useHistory } from 'react-router-dom';
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './BannerRegistrationScreen.scss';

// @end styles
interface IBannerRegistrationScreenProps {
  className?: string;
  style?: CSSProperties;
  id?: string;
  location?: {
    state?: {
      banner?: BannerTypes.Banner;
    };
  };
}

const BannerRegistrationScreen: React.FC<IBannerRegistrationScreenProps> = (
  props
) => {
  const history = useHistory();

  return (
    <AppLayout>
      <div
        className={`sign_up_page-layout ${
          props.className ? props.className : ''
        }`}
        style={props.style}
        id={props.id}
      >
        <BreadcrumbComponent
          Title="Registrar Banners"
          Item_one="Banners"
          Item_two="Registro"
          disabled_Title={false}
          disabled_Item_one={false}
          disabled_Item_two={true}
        />
        <Card sx={{ borderRadius: '10px' }} elevation={2}>
          <CardContent>
            <BannerRegistrationModule
              isUpdate={Boolean(props.location?.state?.banner)}
              initialValues={props.location?.state?.banner}
              onUpdate={() => history.push('/banners/management')}
            />
          </CardContent>
        </Card>
      </div>
    </AppLayout>
  );
};

export default BannerRegistrationScreen;
