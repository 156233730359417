import React, { CSSProperties } from 'react';
import TextComponent from 'components/commons/Text/TextComponent';

import './RadioButton.scss'

interface IRadioButtonComponentProps {
  className?: string
  style?: CSSProperties
  checked?: boolean
  ref?: any
  name?: string
  disabled?: boolean
  label?: string
  required?: boolean
  value?: string
  defaultChecked?: boolean
  defaultValue?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onClick?: () => any
}

const RadioButtonComponent: React.FC<IRadioButtonComponentProps> = (props) => {
  return(
    <div
      className={`radio_button_component-layout ${props.className ? props.className : ''}`}
      style={props.style}
    >
      <label
        className="container"
        onClick={props.onClick}
      >
        {props.label ?
          <TextComponent
            type='text'
          >
            {props.label}
          </TextComponent>
        :null}
        <input
          type="radio"
          checked={props.checked}
          name={props.name}
          onChange={props.onChange}
          ref={props.ref}
          disabled={props.disabled}
          required={props.required}
          value={props.value}
          defaultChecked={props.defaultChecked}
          defaultValue={props.defaultValue}
        />
        <span className="checkmark-radio-button"></span>
      </label>
    </div>
  );
}

export default RadioButtonComponent;
