// @import dependencies
import { combineReducers } from 'redux';
// @end dependencies

// @import reducers
import authReducer from './auth/auth.reducer';
import appReducer from './app/app.reducer';
// @end reducers

// @import types
import * as AuthTypes from 'types/auth/auth.types';
import * as AppTypes from 'types/app/app.types';
// @end types

export interface StoreApp {
  auth: AuthTypes.AuthState;
  app: AppTypes.IAppState;
}

export default combineReducers({
  auth: authReducer,
  app: appReducer,
});
