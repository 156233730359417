// @import dependencies
import React, { CSSProperties, useState, useEffect } from 'react';
// @end dependencies

// @import components
import { MdPowerSettingsNew } from 'react-icons/md';
import TextComponent from '../Text/TextComponent';
// @end components

// @import types
import { Device } from 'types/device/device.types';
import * as DeviceCategoryTypes from 'types/deviceCategory/deviceCategory.types';
import * as DataTypes from 'types/data/data.types';
// @end types

// @import services
import SocketService from 'services/socket/socketService';
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './ButtonControlComponent.scss';
import DataService from 'services/data/dataService';
// @end styles

interface IButtonControlComponentProps {
  control: DeviceCategoryTypes.DeviceCategoryControl;
  event: string;
  macAddress: string;
  device: Device;
  className?: string;
  style?: CSSProperties;
  id?: string;
  label?: string;
  lastData?: DataTypes.IData;
}

const ButtonControlComponent: React.FC<IButtonControlComponentProps> = (
  props
) => {
  const [buttonState, updateButtonState] = useState<boolean>(false);

  // @INFO Servicios
  const socketService = new SocketService();
  const dataService = new DataService();

  useEffect(() => {
    // @INFO Suscribirme al evento
    return () => {};
  }, []);

  useEffect(() => {
    if (props.lastData) {
      const _data = dataService.getDataFromRoot(
        props.control.variableKey,
        props.lastData
      );
      if (_data !== undefined) {
        if (typeof _data === 'number') {
          updateButtonState(_data === 0 ? false : true);
        } else {
          updateButtonState(_data);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.control.variableKey, props.lastData]);

  /**
   * @INFO Llega un nuevo dato del socket
   * @param _data
   */
  //   const handleNewData = (_data: any) => {
  //     if (_data?.data?.payload?.state !== undefined) {
  //       updateButtonState(_data?.data?.payload?.state);
  //     }
  //   };

  const handleClickButton = () => {
    const newState = !buttonState;
    updateButtonState(newState);
    sendDataSocket(newState);
  };

  const sendDataSocket = (state: boolean) => {
    socketService.emitEvent(`${props.device?.category?.name}`, {
      event: `${props.event}/${props.macAddress}`,
      payload: {
        state,
      },
    });
  };

  return (
    <div
      className={`button_control_component-layout ${
        props.className ? props.className : ''
      }`}
      style={{
        ...props.style,
      }}
      id={props.id}
    >
      <div className="container-button">
        <div
          className={`container-icon-button ${buttonState ? 'active' : ''}`}
          onClick={handleClickButton}
        >
          <MdPowerSettingsNew />
        </div>
      </div>
      {props.label ? (
        <TextComponent
          type="label"
          style={{
            textAlign: 'center',
          }}
        >
          {props.label}
        </TextComponent>
      ) : null}
    </div>
  );
};

export default ButtonControlComponent;
