// @import dependencies
import React, { CSSProperties } from 'react';
import { toast } from 'react-toastify';
// @end dependencies

// @import components
import TextFieldComponent from 'components/commons/TextFieldComponent/TextFieldComponent';
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import TextComponent from 'components/commons/Text/TextComponent';
import Box from '@mui/material/Box';
// @end components

// @import types
import { StoreApp } from 'redux/reducers/index';
// @end types

// @import services
import AuthService from 'services/auth/authService';
// @end services

// @import hooks
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
// @end hooks

// @import actions
import * as AuthActions from 'redux/reducers/auth/auth.actions';
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './ChangeEmailModule.scss';
// @end styles
interface IChangeEmailModule {
  className?: string;
  style?: CSSProperties;
  id?: string;
  isAdmin?: boolean;
}
interface ChangeEmailForm {
  newEmail: string;
  password: string;
}

const ChangeEmailModule: React.FC<IChangeEmailModule> = (props) => {
  const user = useSelector((store: StoreApp) => store.auth?.user);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm<ChangeEmailForm>({
    defaultValues: {
      newEmail: '',
      password: '',
    },
  });
  const dispatch = useDispatch();

  // @INFO: Services
  const authService = new AuthService();

  const onSubmit = async (data: ChangeEmailForm) => {
    const response = await authService.changeEmail({
      ...data,
      email: user?.email || '',
    });
    if (response?.data) {
      toast.success(
        'Correo actualizado correctamente, debes iniciar sesión nuevamente'
      );
      dispatch(AuthActions.logoutAction());
    }
  };

  return (
    <div
      className={`change_email-layout ${
        props.className ? props.className : ''
      }`}
      style={props.style}
      id={props.id}
    >
      <TextComponent type="h3" style={{ textAlign: 'center' }}>
        Cambiar correo
      </TextComponent>
      <TextComponent style={{ textAlign: 'center' }}>
        Selecciona una dirección de correo para acceder y recibir información de
        Campus Verde.
      </TextComponent>
      <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
        <div className="fields">
          <div style={{ width: '100%' }}>
            <TextComponent type="label" style={{ textAlign: 'left' }}>
              Correo actual
            </TextComponent>
            <TextComponent type="text" style={{ textAlign: 'left' }}>
              {user?.email}
            </TextComponent>
          </div>
          <TextFieldComponent
            label="Nuevo correo"
            type="email"
            fullWidth
            {...register('newEmail', {
              required: true,
              pattern: /^\S+@\S+$/i,
            })}
            error={errors.newEmail ? true : false}
            helperText={errors.newEmail && 'Correo no válido'}
            onChange={(e) =>
              setValue('newEmail', e.target.value, {
                shouldValidate: true,
              })
            }
          />
          <TextFieldComponent
            label="Contraseña actual"
            type="password"
            fullWidth
            {...register('password', {
              required: true,
            })}
            error={errors.password ? true : false}
            helperText={errors.password && 'Contraseña actual requerida'}
            onChange={(e) =>
              setValue('password', e.target.value, {
                shouldValidate: true,
              })
            }
          />
          <ButtonComponent type="submit" disabled={!isValid}>
            Cambiar correo
          </ButtonComponent>
        </div>
      </Box>
    </div>
  );
};

export default ChangeEmailModule;
