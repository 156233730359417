import React, { CSSProperties } from 'react';
import { AiFillCamera } from 'react-icons/ai';

import './InputFileComponent.scss';
import TextComponent from 'components/commons/Text/TextComponent';

interface IInputFileComponentProps {
  className?: string;
  style?: CSSProperties;
  alt?: string;
  src?: string;
  onLoadFile?: (file: File) => any;
}

const InputFileComponent: React.FC<IInputFileComponentProps> = (props) => {
  const [urlFile, updateUrlFile] = React.useState<string | undefined>(
    props.src ?? ''
  );

  const handleLoadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;
    const file = e.target.files[0];
    const url = URL.createObjectURL(file);
    updateUrlFile(url);
    if (props.onLoadFile) {
      props.onLoadFile(file);
    }
  };

  return (
    <div
      className={`input_file_component-layout ${
        props.className ? props.className : ''
      }`}
      style={props.style}
    >
      <label htmlFor="input_img_profile">
        <div
          className="container-image-input-file_component"
          style={{ backgroundImage: urlFile ? `url(${urlFile})` : undefined }}
        >
          {!urlFile ? (
            <TextComponent
              style={{ color: 'white', fontWeight: 600, fontSize: '1rem' }}
            >
              Sube un archivo
            </TextComponent>
          ) : null}
          <div className="on-hover-input-image">
            <AiFillCamera />
          </div>
        </div>
      </label>
      <input
        type="file"
        style={{ display: 'none' }}
        id="input_img_profile"
        onChange={handleLoadFile}
      />
    </div>
  );
};

export default InputFileComponent;
