// @import components
import DeviceCard, {
  VariableDeviceCard,
} from 'components/modules/DeviceCard/DeviceCard';
// @end components

// @import service
import ColorService from 'services/color/color.service';
// @end service

// @import types
import * as DeviceTypes from 'types/device/device.types';
import * as DeviceCategoryTypes from 'types/deviceCategory/deviceCategory.types';
// @end types

export class DevicesListFunctions {
  public colorService = new ColorService();

  public getTitleFromCategory = (
    category: DeviceCategoryTypes.TypesDeviceCategories
  ) => {
    switch (category) {
      case 'weatherStation':
        return 'Estaciones meteorológicas';
      case 'electricMeter-modbus':
        return 'Medidores eléctricos';
      case 'electricGenerator-windTurbine':
        return 'Generadores';
      case 'luminary':
        return 'Luminarias';
      case 'rfidReader':
        return 'Control de acceso vehicular';
      default:
        return 'Lista de dispositivos';
    }
  };

  public getCardFromCategory = (
    category: DeviceCategoryTypes.TypesDeviceCategories,
    device: DeviceTypes.Device,
    idx: number
  ) => {
    let variable1: VariableDeviceCard = {
      name: '',
      value: '',
    };
    let variable2: VariableDeviceCard = {
      name: '',
      value: '',
    };

    switch (category) {
      case 'weatherStation':
        variable1.name = 'Temperatura';
        variable1.value = `${device.lastData.temp || ''}°`;
        variable2.name = 'Humedad';
        variable2.value = `${device.lastData.hum || ''}%`;
        break;
      case 'electricMeter-modbus':
        variable1.name = 'Potencia Activa';
        variable1.value = `${device.lastData.TkW || ''} kW`;
        variable2.name = 'Potencia Reactiva';
        variable2.value = `${device.lastData.TkVAR || ''} kVAR`;
        break;
      case 'electricGenerator-windTurbine':
        variable1.name = 'Corriente batería';
        variable1.value = `${device.lastData.batteryCurrent || ''} A`;
        variable2.name = 'Voltaje batería';
        variable2.value = `${device.lastData.batteryVoltage || ''} V`;
        break;
      case 'luminary':
        variable1.name = 'Voltaje batería';
        variable1.value = `${
          device.lastData?.battery?.voltage
            ? Math.round(device.lastData?.battery?.voltage * 100) / 100
            : 0
        } V`;
        variable2.name = 'Modo';
        variable2.value = `${device.lastData.mode || 'Sin dato'}`;
        break;
      default:
        break;
    }

    return (
      <DeviceCard
        name={device.name}
        color={this.colorService.getColorFromIndex(idx)}
        key={device.id}
        deviceId={device.id}
        deviceStatus={device.deviceStatus}
        lastDate={device.lastData.date}
        macAddress={device.macAddress}
        variable1={variable1}
        variable2={variable2}
      />
    );
  };
}
