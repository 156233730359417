import React, { CSSProperties, Fragment, useState } from 'react';
import shortid from 'shortid';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import './TableComponent.scss';
import moment from 'moment';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';

export interface IKeyTable {
  key: string;
  name?: string;
  type?: 'switch' | 'text' | 'image' | 'copy-text' | 'list';
  formatMoment?: string;
}

export interface TableComponentProps {
  keys: IKeyTable[];
  rows: any[];
  className?: string;
  style?: CSSProperties;
  showDelete?: boolean;
  showEdit?: boolean;
  showView?: boolean;
  showSwitch?: boolean;
  showGoTo?: boolean;
  pagination?: {
    rowsPerPageOptions: number[];
  };
  onClickSwitch?: (row: number) => any;
  onClickDelete?: (item: any) => any;
  onClickEdit?: (item: any) => any;
  onClickView?: (item: any) => any;
  onClickGoTo?: (item: any) => any;
  onClickSave?: (item: any) => any;
  onChangeSwitch?: (item: any) => void;
}

const TableComponent: React.FC<TableComponentProps> = (props) => {
  const initialRowsPerPage = props.pagination?.rowsPerPageOptions;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    initialRowsPerPage && initialRowsPerPage.length > 0
      ? initialRowsPerPage[0]
      : 5
  );

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.rows.length) : 0;

  const hasActions =
    props.showDelete || props.showEdit || props.showView || props.showGoTo;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (item: any) => {
    if (props.onClickEdit) {
      props.onClickEdit(item);
    }
  };
  const handleDelete = (item: any) => {
    if (props.onClickDelete) {
      props.onClickDelete(item);
    }
  };

  return (
    <div
      className={`table_component-layout ${
        props.className ? props.className : ''
      }`}
      style={props.style}
    >
      <TableContainer
        component={Paper}
        elevation={2}
        sx={{ borderRadius: '10px' }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {props.keys.map((itemHead) => (
                <TableCell key={shortid.generate()}>
                  {itemHead.name ? itemHead.name : itemHead.key}
                </TableCell>
              ))}
              {hasActions && <TableCell>Acciones</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? props.rows.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : props.rows
            ).map((itemRow) => (
              <TableRow key={shortid.generate()}>
                {props.keys.map((itemKey) => (
                  <Fragment key={shortid.generate()}>
                    {(!itemKey.type || itemKey.type === 'text') && (
                      <TableCell key={shortid.generate()}>
                        {itemKey.formatMoment
                          ? moment(itemRow[itemKey.key]).format(
                              itemKey.formatMoment
                            )
                          : itemRow[itemKey.key]}
                      </TableCell>
                    )}
                    {itemKey.type === 'list' && (
                      <TableCell key={shortid.generate()}>
                        {itemRow[itemKey.key].map((item: any) => item)}
                      </TableCell>
                    )}
                    {itemKey.type === 'image' && (
                      <TableCell key={shortid.generate()}>
                        <img src={itemRow[itemKey.key]} alt="banner" />
                      </TableCell>
                    )}
                  </Fragment>
                ))}
                {hasActions && (
                  <TableCell>
                    <div className="container-actions-table">
                      {props.showEdit && (
                        <Tooltip title="Editar">
                          <IconButton onClick={() => handleEdit(itemRow)}>
                            <EditRoundedIcon
                              className="icon-table"
                              fontSize="medium"
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                      {props.showDelete && (
                        <Tooltip title="Eliminar">
                          <IconButton onClick={() => handleDelete(itemRow)}>
                            <DeleteForeverRoundedIcon
                              className="icon-table"
                              fontSize="medium"
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                      {props.showSwitch && (
                        <Tooltip title="Habilitar/Deshabilitar">
                          <Switch
                            defaultChecked={itemRow.checked}
                            onChange={(e, checked) =>
                              props.onChangeSwitch &&
                              props.onChangeSwitch({ ...itemRow, checked })
                            }
                          ></Switch>
                        </Tooltip>
                      )}
                    </div>
                  </TableCell>
                )}
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[
                  ...(initialRowsPerPage && initialRowsPerPage.length > 0
                    ? initialRowsPerPage
                    : [5, 10, 25]),
                  { label: 'All', value: -1 },
                ]}
                count={props.rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage="Filas por página"
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TableComponent;
