// @import dependencies
import React, { CSSProperties } from 'react';
// @end dependencies

// @import components
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import { toast } from 'react-toastify';
import TextComponent from 'components/commons/Text/TextComponent';
import TextFieldComponent from 'components/commons/TextFieldComponent/TextFieldComponent';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
// @end components

// @import types
import * as AuthTypes from 'types/auth/auth.types';
import * as UserTypes from 'types/user/user.types';
// @end types

// @import services
import AuthService from 'services/auth/authService';
// @end services

// @import hooks
import { useForm, useFieldArray } from 'react-hook-form';
import { useDispatch } from 'react-redux';
// @end hooks

// @import actions
import * as AuthActions from 'redux/reducers/auth/auth.actions';
// @end actions

// @import utils
// @end utils

// @import assets
import logo_scada from 'assets/icons/logo_scada.svg';
// @end assets

// @import styles
import './UserRegistrationModule.scss';
import UserService from 'services/user/user.service';
// @end styles
interface IUserRegistrationModuleProps {
  className?: string;
  style?: CSSProperties;
  id?: string;
  isAdmin?: boolean;
  isUpdate?: boolean;
  isMine?: boolean;
  showLogo?: boolean;
  initialValues?: Partial<UserTypes.User>;
  onUpdate?: () => void;
}

const UserRegistrationModule: React.FC<IUserRegistrationModuleProps> = (
  props
) => {
  const {
    control,
    register,
    handleSubmit,
    getValues,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm<AuthTypes.SignUp>({
    defaultValues: {
      role: props.initialValues?.role || 'User',
      firstName: props.initialValues?.firstName || '',
      lastName: props.initialValues?.lastName || '',
      username: props.initialValues?.username || '',
      email: props.initialValues?.email || '',
      password: '',
      docType: props.initialValues?.docType || 'CC',
      docNumber: props.initialValues?.docNumber || '',
      plates: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'plates',
  });

  const dispatch = useDispatch();

  // @INFO: Services
  const authService = new AuthService();
  const userService = new UserService();

  const onSubmit = async (data: AuthTypes.SignUp) => {
    if (props.isUpdate && props.isMine) {
      const { password, email, plates, ...updateData } = data;
      dispatch(AuthActions.updateUserAction(updateData));
      return;
    }
    if (props.isUpdate) {
      const { password, email, plates, ...updateData } = data;
      const response = await userService.update({
        ...updateData,
        id: props.initialValues?.id,
      });
      if (response) {
        toast.success('Actualización exitosa');
      }
      props.onUpdate && props.onUpdate();
      return;
    }
    const plates = data.plates
      .map((plate: any) => plate.value.toUpperCase())
      .filter((plate: any) => plate.length > 0);
    const response = await authService.signUp({
      ...data,
      plates: plates,
    });
    if (response) {
      reset();
      toast.success('Registro exitoso');
    }
  };

  const handleRemovePlate = (index: number) => {
    remove(index);
  };

  const handleNewPlate = () => {
    append({ value: '' });
  };

  return (
    <div
      className={`user_registration-layout ${
        props.className ? props.className : ''
      }`}
      style={props.style}
      id={props.id}
    >
      {props.showLogo && (
        <div className="text-center">
          <img
            src={logo_scada}
            alt=""
            width="200px"
            style={{ paddingLeft: '10px', paddingRight: '10px' }}
          />
        </div>
      )}
      <TextComponent type="h3" className="text-center mb-3">
        {props.isUpdate ? 'Mis datos' : 'Registro'}
      </TextComponent>
      <form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row">
          <div className="col-md-4">
            <div className="form-group">
              <TextFieldComponent
                type="text"
                {...register('firstName', {
                  required: true,
                })}
                label="Nombre"
                fullWidth
                required
                error={errors.firstName ? true : false}
                helperText={errors.firstName && 'Este campo es requerido'}
                onChange={(e) =>
                  setValue('firstName', e.target.value, {
                    shouldValidate: true,
                  })
                }
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <TextFieldComponent
                type="text"
                {...register('lastName', {
                  required: true,
                })}
                label="Apellido"
                fullWidth
                required
                error={errors.lastName ? true : false}
                helperText={errors.lastName && 'Este campo es requerido'}
                onChange={(e) =>
                  setValue('lastName', e.target.value, {
                    shouldValidate: true,
                  })
                }
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <TextFieldComponent
                type="text"
                {...register('username', {
                  required: true,
                })}
                label="Nombre de usuario"
                fullWidth
                required
                disabled={props.isUpdate}
                error={errors.username ? true : false}
                helperText={errors.username && 'Este campo es requerido'}
                onChange={(e) =>
                  setValue('username', e.target.value, {
                    shouldValidate: true,
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className={props.isUpdate ? 'col-sm-8' : 'col-sm-4'}>
            <div className="form-group">
              <TextFieldComponent
                type="email"
                {...register('email', {
                  required: true,
                  pattern: /^\S+@\S+$/i,
                })}
                label="Correo electrónico"
                error={errors.email ? true : false}
                helperText={errors.email && 'Correo no válido'}
                fullWidth
                required
                disabled={props.isUpdate}
                onChange={(e) =>
                  setValue('email', e.target.value, {
                    shouldValidate: true,
                  })
                }
              />
            </div>
          </div>
          {!props.isUpdate && (
            <div className="col-sm-4">
              <div className="form-group">
                <TextFieldComponent
                  type="password"
                  {...register('password', {
                    required: true,
                    pattern:
                      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                  })}
                  label="Contraseña"
                  error={errors.password ? true : false}
                  fullWidth
                  required
                  helperText={
                    errors.password &&
                    'La contraseña debe contener al menos 8 caracteres, una mayúscula, un número y un símbolo.'
                  }
                  onChange={(e) =>
                    setValue('password', e.target.value, {
                      shouldValidate: true,
                    })
                  }
                />
              </div>
            </div>
          )}
        </div>
        <div className="form-row">
          <div className="col-sm-4">
            <div className="form-group">
              <FormControl fullWidth>
                <InputLabel id="doc-type-label" required>
                  Tipo de documento
                </InputLabel>
                <Select
                  value={getValues().docType}
                  label="Tipo de documento"
                  onChange={(e) =>
                    setValue('docType', e.target.value, {
                      shouldValidate: true,
                    })
                  }
                >
                  <MenuItem value={'CC'}>CC Cédula de ciudadanía</MenuItem>
                  <MenuItem value={'TI'}>TI Tarjeta de identidad</MenuItem>
                  <MenuItem value={'CE'}>CE Cédula de extranjería</MenuItem>
                  <MenuItem value={'NIT'}>NIT</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <TextFieldComponent
                type="number"
                {...register('docNumber', {
                  required: true,
                })}
                label="Número documento"
                error={errors.docNumber ? true : false}
                fullWidth
                required
                helperText={errors.docNumber && 'Este campo es requerido'}
                onChange={(e) =>
                  setValue('docNumber', e.target.value, {
                    shouldValidate: true,
                  })
                }
              />
            </div>
          </div>
        </div>
        {props.isAdmin && (
          <div className="form-group">
            <div className="form-row">
              <div className="col-sm-4">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Rol / Cargo
                  </InputLabel>
                  <Select
                    value={getValues().role}
                    label="Rol / Cargo"
                    onChange={(e) =>
                      setValue('role', e.target.value, {
                        shouldValidate: true,
                      })
                    }
                  >
                    <MenuItem value={'Admin'}>Administrador</MenuItem>
                    <MenuItem value={'User'}>Usuario</MenuItem>
                    <MenuItem value={'SuperAdmin'}>
                      Super Administrador
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        )}
        {!props.isUpdate && (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <TextComponent>Datos adicionales (Opcional)</TextComponent>
            </AccordionSummary>
            <AccordionDetails>
              <Card
                sx={{
                  padding: '5px 10px',
                  borderRadius: '10px',
                }}
                elevation={5}
              >
                <CardContent>
                  <TextComponent type="subtitle" className="mb-3">
                    Datos Vehículo
                  </TextComponent>
                  {fields.map((field, index) => (
                    <div key={field.id} className="form-row mb-3">
                      <Grid
                        container
                        direction="row"
                        spacing={2}
                        alignItems="center"
                      >
                        <Grid item>
                          <TextFieldComponent
                            type="text"
                            label="Placa vehículo"
                            fullWidth
                            {...register(`plates.${index}.value`)}
                          />
                        </Grid>
                        <Grid item>
                          <IconButton
                            aria-label="delete"
                            onClick={() => handleRemovePlate(index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </div>
                  ))}
                  <div className="form-row">
                    <div className="col-sm-4">
                      <ButtonComponent
                        variant="secondary"
                        type="button"
                        style={{ color: 'white' }}
                        onClick={handleNewPlate}
                      >
                        Añadir placa vehículo
                      </ButtonComponent>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </AccordionDetails>
          </Accordion>
        )}
        <div className="form-row mt-3">
          <div className="col-sm-4">
            <ButtonComponent
              variant="primary"
              type="submit"
              disabled={!isValid}
            >
              {props.isUpdate ? 'Actualizar' : 'Registrar Usuario'}
            </ButtonComponent>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UserRegistrationModule;
