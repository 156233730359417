// @import dependencies
import React, { CSSProperties } from 'react'
// @end dependencies

// @import components
// @end components

// @import types
// @end types

// @import services
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './NotFoundPage.scss'
// @end styles

interface INotFoundPageProps {
    className?: string
    style?: CSSProperties
    id?: string
    
}

const NotFoundPage: React.FC<INotFoundPageProps> = (props) => {
    return(
        <div
            className={`not_found_page-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            <p>
                {'Pagina no encontrada :('}
            </p>
        </div>
    );
}

export default NotFoundPage;