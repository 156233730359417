// @import dependencies
import React, { CSSProperties, useState, useEffect } from 'react';
// @end dependencies

// @import components
import { Line, ChartData, Bar, Pie } from 'react-chartjs-2';
import TextComponent from '../Text/TextComponent';
// @end components

// @import hooks
import useMediaQuery from '@mui/material/useMediaQuery';
// @end hooks

// @import types
import { Chart, ChartOptions } from 'chart.js';
import * as DeviceCategoryTypes from 'types/deviceCategory/deviceCategory.types';
// @end types

// @import services
import ColorService from 'services/color/color.service';
import { useSelector } from 'react-redux';
import { StoreApp } from 'redux/reducers';
// @end services

// @import styles
import './ChartComponent.scss';
// @end styles

export interface ChartComponentProps {
  className?: string;
  style?: CSSProperties;
  label?: string;
  chartLabel?: string | string[];
  labels?: any[];
  data?: any[];
  options?: ChartOptions;
  type?: DeviceCategoryTypes.TypesChartDevice;
}

const defaultDataOptions = {
  labels: [],
  datasets: [
    {
      label: 'Data',
      data: [],
      fill: false,
      backgroundColor: '#00923f',
      borderColor: '#FBB03B',
    },
  ],
};

const ChartComponent: React.SFC<ChartComponentProps> = (props) => {
  const [data, updateData] =
    useState<ChartData<Chart.ChartData>>(defaultDataOptions);
  const mode = useSelector((store: StoreApp) => store.app?.theme);
  const isSmall = useMediaQuery('(max-width:600px)');

  const colorService = new ColorService();

  const options: ChartOptions = {
    maintainAspectRatio: !isSmall,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            fontColor: mode === 'dark' ? 'white' : '#666',
          },
          gridLines: {
            color: mode === 'dark' ? '#777' : 'rgba(0, 0, 0, 0.1)',
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            fontColor: mode === 'dark' ? 'white' : '#666',
          },
          gridLines: {
            color: mode === 'dark' ? '#777' : 'rgba(0, 0, 0, 0.1)',
          },
        },
      ],
    },
    animation: {
      duration: 0,
    },
    legend: {
      labels: {
        usePointStyle: true,
        fontColor: mode === 'dark' ? 'white' : '#666',
      },
    },
  };

  useEffect(() => {
    let dataSets: Chart.ChartDataSets[] = [];
    if (props.chartLabel && Array.isArray(props.chartLabel)) {
      props.chartLabel.forEach((_label, idx) => {
        dataSets.push({
          data: props.data && props.data[idx] ? props.data[idx] : [],
          label: _label ? _label : 'Data',
          fill: false,
          backgroundColor:
            props.type === 'pie'
              ? colorService.getAllColors()
              : colorService.getColorFromIndex(idx),
          borderColor:
            props.type === 'pie'
              ? colorService.getAllColors()
              : colorService.getColorFromIndex(idx),
          pointRadius: 0,
          pointHitRadius: 10,
        });
      });
    } else {
      dataSets = [
        {
          data: props.data?.length ? props.data : [],
          label: props.chartLabel ? props.chartLabel : 'Data',
          fill: false,
          backgroundColor: colorService.getColorFromIndex(0),
          borderColor: colorService.getColorFromIndex(0),
          pointRadius: 0,
          pointHitRadius: 10,
        },
      ];
    }
    const newData: ChartData<Chart.ChartData> = {
      labels: props.labels?.length ? props.labels : [],
      datasets: dataSets,
    };
    updateData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.chartLabel, props.labels, props.data]);

  const getChart = () => {
    switch (props.type) {
      case 'line':
        return (
          <Line
            data={data}
            options={props.options ? props.options : options}
            type="line"
          />
        );
      case 'bar-vertical':
        return (
          <Bar data={data} options={props.options ? props.options : options} />
        );
      case 'pie':
        return <Pie data={data} />;
      default:
        return (
          <Line
            data={data}
            options={props.options ? props.options : options}
            type="line"
          />
        );
    }
  };

  return (
    <div className="chart_component-layout">
      {props.label ? (
        <TextComponent
          style={{
            fontWeight: 500,
            fontSize: '0.9rem',
            textDecoration: 'underline',
          }}
        >
          {props.label}
        </TextComponent>
      ) : null}
      {getChart()}
    </div>
  );
};

export default ChartComponent;
