import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { IRouteApp } from 'types/routes/routes.types';
import AuthService from '../auth/authService';
import PermissionsService from '../permissions/permissionsService';
import NotFoundPage from 'components/screens/NotFoundPage/NotFoundPage';
import { HOME_ROUTE } from 'config/globals';

// type ITypeRoutes = '/' | '/profile' | '/home';

class RouterService {
  private permissionsService = new PermissionsService();
  private authService = new AuthService();

  /**
   * @INFO Renderizar una ruta
   * @param _route
   */
  public renderRoute = (_route: IRouteApp) => {
    // @INFO Reviso si tiene pathVariables
    let pathVariables = '';
    if (_route.pathVariables?.length) {
      pathVariables = _route.pathVariables.reduce((accum: string, item) => {
        accum = `${accum}/:${item}`;
        return accum;
      }, pathVariables);
    }
    // @INFO Reviso si es privada o publica
    if (_route.private?.state) {
      let accepted = true;
      // @INFO Revisar si tiene condiciones para la ruta
      if (_route.private.conditions?.length) {
        accepted = _route.private.conditions.reduce((accum: boolean, item) => {
          if (item === 'auth') {
            accum = this.authService.isLogin();
          }
          return accum;
        }, accepted);
      }
      // @INFO Revisar si la ruta tiene permisos
      if (_route.private.permissions?.length) {
        accepted = _route.private.permissions.reduce((accum: boolean, item) => {
          if (accum) {
            accum = this.permissionsService.check(item);
          }
          return accum;
        }, accepted);
      }
      // @INFO Reviso si tiene permiso para acceder, de lo contrario lo mando a la home o login
      if (accepted) {
        return (
          <Route
            path={_route.path + pathVariables}
            component={_route.component}
            exact={_route.exact}
          />
        );
      } else if (window.location.pathname.includes(_route.path)) {
        if (this.authService.isLogin()) {
          return <Redirect to={HOME_ROUTE} />;
        } else {
          return (
            // TODO: Revisar si hay una forma mejor de hacerlo
            <Redirect to={'/login'} />
          );
          // window.open(`${window.location.origin}/login`, '_self');
          // return null
        }
      } else {
        return (
          <Route
            path={_route.path + pathVariables}
            component={NotFoundPage}
            exact={_route.exact}
          />
        );
      }
    } else {
      return (
        <Route
          path={_route.path + pathVariables}
          component={_route.component}
          exact={_route.exact}
        />
      );
    }
  };
}

export default RouterService;
