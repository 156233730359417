import store from 'redux/store';
import HTTPUtil from 'utils/http/http.util';

// @import types
import * as DeviceLoggerTypes from 'types/deviceLogger/deviceLogger.types';
// @end types

export class DeviceLoggerService {
  private storeObject = store;

  public getByMacAddress = async (
    macAddress: string
  ): Promise<DeviceLoggerTypes.DeviceLogger[]> => {
    const response = await HTTPUtil.send(
      {
        url: '/deviceLogger',
        method: 'GET',
        params: {
          macAddress,
        },
        headers: {
          Authorization: this.storeObject.getState()?.auth?.token,
        },
      },
      {
        alertOnFailed: true,
      }
    );

    if (response?.status === 200) {
      return response.data as DeviceLoggerTypes.DeviceLogger[];
    } else {
      return [];
    }
  };

  public update = async (
    params: DeviceLoggerTypes.ParamsDeviceLoggerUpdate
  ): Promise<DeviceLoggerTypes.DeviceLogger | undefined> => {
    const response = await HTTPUtil.send(
      {
        url: '/deviceLogger',
        method: 'PUT',
        data: {
          ...params,
        },
        headers: {
          Authorization: this.storeObject.getState()?.auth?.token,
        },
      },
      {
        alertOnFailed: true,
      }
    );

    if (response?.status === 200) {
      return response.data as DeviceLoggerTypes.DeviceLogger;
    } else {
      return undefined;
    }
  };
}

export default DeviceLoggerService;
