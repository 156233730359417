// @import dependencies
import AppLayout from 'components/layouts/AppLayout/AppLayout';
import React, { CSSProperties } from 'react';
// @end dependencies

// @import components
import ProfileModule from 'components/modules/ProfileModule/ProfileModule';
// @end components

// @import types
// @end types

// @import services
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './ProfileScreen.scss';
// @end styles

interface IProfileScreenProps {
  className?: string;
  style?: CSSProperties;
  id?: string;
}

const ProfileScreen: React.FC<IProfileScreenProps> = (props) => {
  return (
    <AppLayout>
      <div
        className={`profile_screen-layout ${
          props.className ? props.className : ''
        }`}
        style={props.style}
        id={props.id}
      >
        <ProfileModule />
      </div>
    </AppLayout>
  );
};

export default ProfileScreen;
