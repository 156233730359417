import store from 'redux/store';
import {
  Notification,
  NotificationFilter,
} from 'types/notification/notification.types';
import HTTPUtil from 'utils/http/http.util';

class NotificationService {
  private storeObject = store;
  private tokenName: string = 'token';

  async getAll(filter?: NotificationFilter) {
    const response = await HTTPUtil.send(
      {
        url: '/notification/',
        method: 'GET',
        params: filter,
        headers: {
          Authorization: this.storeObject.getState()?.auth?.token,
        },
      },
      {
        alertOnFailed: true,
      }
    );

    if (response?.status === 200) {
      return response;
    } else {
      return undefined;
    }
  }
  async update(notificationId: string, params: Partial<Notification>) {
    const response = await HTTPUtil.send(
      {
        url: `/notification/${notificationId}`,
        method: 'PUT',
        data: params,
        headers: {
          Authorization: this.storeObject.getState()?.auth?.token,
        },
      },
      {
        alertOnFailed: true,
      }
    );

    if (response?.status === 200) {
      return response;
    } else {
      return undefined;
    }
  }
}

export default NotificationService;
