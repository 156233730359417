// @import dependencies
import React from 'react';
import { FiHome } from 'react-icons/fi';
// @end dependencies

// @import components
import { Breadcrumb } from 'react-bootstrap';
// @end components

// @import styles
import './BreadcrumbComponent.scss';
// @end styles

export interface BreadcrumbComponentProps {
  Title?: string;
  Item_one?: string;
  Item_two?: string;
  disabled_Title?: boolean;
  disabled_Item_one?: boolean;
  disabled_Item_two?: boolean;
}

const BreadcrumbComponent: React.FC<BreadcrumbComponentProps> = (props) => {
  return (
    <div className="container-breadcrum">
      <h4>{props.Title}</h4>
      <Breadcrumb
        style={{ backgroundColor: 'transparent' }}
        className="custom-breadcrumb"
      >
        <Breadcrumb.Item
          href={'/'}
          active={props.disabled_Title}
          className="custom-breadcrumb-item"
        >
          <FiHome />
        </Breadcrumb.Item>
        <Breadcrumb.Item
          active={props.disabled_Item_one}
          className="custom-breadcrumb-item"
        >
          {props.Item_one}
        </Breadcrumb.Item>
        <Breadcrumb.Item
          active={props.disabled_Item_two}
          className="custom-breadcrumb-item"
        >
          {props.Item_two}
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
};

export default BreadcrumbComponent;
