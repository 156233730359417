import React, { CSSProperties, useState, useEffect, ChangeEvent } from 'react'
import InputComponent from 'components/commons/Input/InputComponent';

// @import components
import ButtonComponent from '../Button/ButtonComponent';
import TextComponent from 'components/commons/Text/TextComponent';
import {BiDownload} from 'react-icons/bi';
// @end components

import './DateFilterComponent.scss'
import moment from 'moment';

interface IDateFilterComponentProps {
    className?: string
    style?: CSSProperties
    defaultSince?: Date
    defaultTo?: Date
    defaultPoints?: number
    loading?: boolean
    loadingDownload?: boolean
    showPoints?: boolean
    showDownload?: boolean
    onSearch?: (since: Date, to: Date, points?: number) => any
    onDownload?: (since: Date, to: Date, points?: number) => any
}

const DateFilterComponent: React.FC<IDateFilterComponentProps> = (props) => {

    const [since, updateSince] = useState<Date>(props.defaultSince ? props.defaultSince : new Date())
    const [to, updateTo] = useState<Date>(props.defaultTo ? props.defaultTo : new Date())
    const [points, updatePoints] = useState<number>(props.defaultPoints ? props.defaultPoints : 0)

    useEffect(() => {

    },[since, to])

    const handleChangeDay = (e: React.ChangeEvent<HTMLInputElement>, type: 'since' | 'to') => {
        const aux = new Date(e.target.value)
        aux.setDate(aux.getDate()+1)
        if(type === 'since'){
            updateSince(aux)
        }else if(type === 'to'){
            updateTo(aux)
        }
    }
   
    const handleChangeHour = (e: React.ChangeEvent<HTMLInputElement>, type: 'since' | 'to') => {
        const hours: any = Number(e.target.value.split(':')[0])
        const minutes: any = Number(e.target.value.split(':')[1])
        if(hours === undefined && minutes === undefined) return 
        let aux: Date
        if(type === 'since'){
            aux = new Date(since)
            aux.setHours(hours)
            aux.setMinutes(minutes)
            updateSince(aux)
        }else if(type === 'to'){
            aux = new Date(to)
            aux.setHours(hours)
            aux.setMinutes(minutes)
            updateTo(aux)
        }
    }

    const handleClickSearch = () => {
        if(props.onSearch){
            props.onSearch(since, to, points)
        }
    }
    
    const handleClickDownload = () => {
        if(props.onDownload){
            props.onDownload(since, to, points)
        }
    }

    return(
        <div
            className={`date_filter_component-layout ${props.className ? props.className : ''}`}
            style={props.style}
        >
            <TextComponent
                type='label'
                style={{textDecoration: 'underline', marginBottom: '8px'}}
            >
                Filtrar por fecha
            </TextComponent>
            <div
                className='container-actions'
            >
                <InputComponent
                    type='date'
                    placeholder='Desde'
                    label="Desde"
                    className='input-date-filter-component'
                    value={moment(since).format('YYYY-MM-DD')}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeDay(e, 'since')}
                />
                <InputComponent
                    type='time'
                    placeholder='Desde'
                    className='input-date-filter-component'
                    value={moment(since).format('HH:mm')}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeHour(e, 'since')}
                />
                <InputComponent
                    type='date'
                    placeholder='Desde'
                    label="Hasta"
                    className='input-date-filter-component'
                    value={moment(to).format('YYYY-MM-DD')}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeDay(e, 'to')}
                />
                <InputComponent
                    type='time'
                    placeholder='Desde'
                    className='input-date-filter-component'
                    value={moment(to).format('HH:mm')}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeHour(e, 'to')}
                />
                {props.showPoints ?
                    <InputComponent
                        type='number'
                        placeholder='# de puntos'
                        value={points.toString()}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => updatePoints(Number(e.target.value))}
                    />
                :null}
                <ButtonComponent
                    variant='primary'
                    onClick={handleClickSearch}
                    loading={props.loading}
                >
                    Filtrar
                </ButtonComponent>
                {props.showDownload ?
                    <ButtonComponent
                        variant='primary'
                        onClick={handleClickDownload}
                        loading={props.loadingDownload}
                    >
                        <ButtonComponent.Icon>
                            <BiDownload/>
                        </ButtonComponent.Icon>
                        Descargar reporte
                    </ButtonComponent>
                :null}
            </div>
        </div>
    );
}

export default DateFilterComponent;