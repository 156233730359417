// @import dependencies
import React, { CSSProperties, useEffect, useState } from 'react';
import shortid from 'shortid';
// @end dependencies

// @import components
import ChartComponent from 'components/commons/ChartComponent/ChartComponent';
// @end components

// @import types
import * as DeviceCategoryTypes from 'types/deviceCategory/deviceCategory.types';
import * as DataTypes from 'types/data/data.types';
import { IData } from 'types/data/data.types';
// @end types

// @import services
import SocketService from 'services/socket/socketService';
import DataService from 'services/data/dataService';
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './DeviceChartsModule.scss';
// @end styles

interface IDeviceChartsModuleProps {
  charts: DeviceCategoryTypes.DeviceCategoryChart[];
  eventName?: string;
  className?: string;
  style?: CSSProperties;
  id?: string;
  data?: DataTypes.IData[];
  dateFormat?: string;
  isRawLabel?: boolean;
  onNewData?: (data: any) => void;
}

interface IGraphItem {
  _id: string;
  name: string;
  show: boolean;
  chartLabel: string[];
  variableKeys: string[];
  data: any[];
  deviceData?: IData[];
  type: DeviceCategoryTypes.TypesChartDevice;
  labels?: string[];
  dateFormat?: string;
}

const DeviceChartsModule: React.FC<IDeviceChartsModuleProps> = (props) => {
  const [triggerNewData, updateTriggerNewData] = useState<any>(undefined);
  const [data, updateData] = useState<any[]>([]);
  const [charts, updateCharts] = useState<IGraphItem[]>([]);
  const [labels, updateLabels] = useState<any[]>([]);

  // @INFO Servicios
  const socketService = new SocketService();
  const dataService = new DataService();

  useEffect(() => {
    // @INFO Crear las graficas que se van a mostrar
    const newCharts: IGraphItem[] = [];
    props.charts.forEach((_chart) => {
      newCharts.push({
        name: _chart.name,
        chartLabel: _chart.variables.map(
          (_v) => `${_v.name} ${_v.unit ? _v.unit : ''}`
        ),
        show: true,
        variableKeys: _chart.variables.map((_v) => _v.variableKey),
        data: [],
        deviceData: _chart.data,
        _id: shortid.generate(),
        type: _chart.type,
        dateFormat: _chart.dateFormat,
      });
      _chart.variables.forEach((_var) => {});
    });
    updateCharts([...newCharts]);
    // @INFO Suscribirse al evento del dispositivo
    if (props.eventName) {
      socketService.listenEvent(props.eventName, handleNewData);
    }
    return () => {
      if (props.eventName) {
        socketService.removeListenEvent(props.eventName, handleNewData);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (triggerNewData) {
      updateData([...data, { ...triggerNewData }]);
      updateTriggerNewData(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerNewData]);

  useEffect(() => {
    // @INFO Organizar la data para graficar
    if (data?.length) {
      updateLabels(dataService.getDateFormate([...data], props.dateFormat));
      const newCharts = [...charts];
      newCharts.forEach((_ch, _idx) => {
        _ch.variableKeys.forEach((_vk, idxKey) => {
          if (_ch.deviceData?.length) {
            newCharts[_idx].data[idxKey] = dataService.filterVariablesFromData(
              [..._ch.deviceData],
              `${_vk}`,
              0
            );
          } else {
            newCharts[_idx].data[idxKey] = dataService.filterVariablesFromData(
              [...data],
              `${_vk}`,
              0
            );
          }
        });
        if (_ch.deviceData?.length) {
          newCharts[_idx].labels = props.isRawLabel
            ? dataService.filterVariablesFromData(_ch.deviceData, 'label', '')
            : dataService.getDateFormate(
                [..._ch.deviceData],
                _ch.dateFormat ? _ch.dateFormat : props.dateFormat
              );
        }
      });
      updateCharts([...newCharts]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (props.data) {
      updateData(props.data);
    }
  }, [props.data]);

  /**
   * @INFO Llega un nuevo dato del socket
   * @param _data
   */
  const handleNewData = (_data: any) => {
    if (_data) {
      updateTriggerNewData(_data);
      if (props.onNewData) {
        props.onNewData({ ..._data });
      }
    }
  };

  return (
    <div
      className={`device_charts_module-layout ${
        props.className ? props.className : ''
      }`}
      style={props.style}
      id={props.id}
    >
      {charts
        .filter((_g) => _g.show)
        .map((_g) => (
          <ChartComponent
            key={_g._id}
            label={_g.name}
            chartLabel={_g.chartLabel}
            data={_g.data}
            labels={_g?.labels?.length ? _g.labels : labels}
            style={{ width: '100%' }}
            type={_g.type}
          />
        ))}
      {/* ========================================================== */}
      {/* ==================== Ejemplos ============================ */}
      {/* ========================================================== */}
      {/* <ChartComponent
                label={'Prueba'}
                chartLabel={['Algo', 'Algo 2']}
                data={[[1,2,3,4,5], [5,4,3,2,1]]}
                labels={['uno', 'dos', 'tres', 'cuatro', 'cinco']}
                style={{ width: '100%' }}
            /> */}
      {/* <ChartComponent
                label={'Prueba'}
                chartLabel={'Algo solo'}
                data={[1,2,3,4,5]}
                labels={['uno', 'dos', 'tres', 'cuatro', 'cinco']}
                style={{ width: '100%' }}
            /> */}
    </div>
  );
};

export default DeviceChartsModule;
