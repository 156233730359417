// @import dependencies
import React, { CSSProperties, useEffect, useState } from 'react';
// @end dependencies

// @import components
import TableComponent, {
  IKeyTable,
} from 'components/commons/TableComponent/TableComponent';
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import Chip from '@mui/material/Chip';
import SearchComponent from '../../commons/SearchComponent/SearchComponent';
// @end components

// @import types
import * as UserTypes from 'types/user/user.types';
// @end types

// @import services
import UserService from 'services/user/user.service';
// @end services

// @import hooks
import { Link, useHistory } from 'react-router-dom';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './UserManagementModule.scss';
// @end styles

interface IUserManagementModuleProps {
  className?: string;
  style?: CSSProperties;
  id?: string;
}

const keys: IKeyTable[] = [
  {
    key: 'email',
    name: 'Correo',
  },
  {
    key: 'fullName',
    name: 'Nombre',
  },
  {
    key: 'role',
    name: 'Rol',
  },
  {
    key: 'state',
    name: 'Estado',
  },
];

const UserManagementModule: React.FC<IUserManagementModuleProps> = (props) => {
  const [users, setUsers] = useState<UserTypes.User[]>([]);
  const history = useHistory();

  // @INFO: Services
  const userService = new UserService();

  useEffect(() => {
    getAllUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllUsers = async () => {
    const response = await userService.getAll();
    if (response) {
      setUsers(response.data);
    }
  };

  const getBySearchParam = async (search: string) => {
    const response = await userService.getBySearchParam(search);
    if (response) {
      setUsers(response.data);
    }
  };

  const handleToggleActive = async (data: any) => {
    const response = await userService.toggleActive(data?.user?.id);
    if (response) {
      getAllUsers();
    }
  };

  return (
    <div
      className={`user_management-layout ${
        props.className ? props.className : ''
      }`}
      style={props.style}
      id={props.id}
    >
      <div className="new-user-menu">
        <SearchComponent onChange={getBySearchParam} />
        <Link to={'/users/register'} className="link">
          <ButtonComponent>Crear nuevo</ButtonComponent>
        </Link>
      </div>
      <TableComponent
        keys={keys}
        showEdit
        showSwitch
        onClickEdit={(data: any) =>
          history.push('/users/register', { user: data?.user })
        }
        onChangeSwitch={handleToggleActive}
        rows={users.map((user) => ({
          user,
          email: user.email,
          fullName: `${user.firstName} ${user.lastName}`,
          role: user.role,
          checked: user.isActive,
          state: user.isActive ? (
            <Chip label="Activo" color="primary" />
          ) : (
            <Chip label="Inhabilitado" color="secondary" />
          ),
        }))}
      />
    </div>
  );
};

export default UserManagementModule;
