// @import dependencies
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import TextComponent from 'components/commons/Text/TextComponent';
import React, { CSSProperties } from 'react';
import moment from 'moment';
import 'moment/locale/es';
// @end dependencies

// @import components
import DeviceChartsModule from 'components/modules/DeviceChartsModule/DeviceChartsModule';
import LoadingComponent from 'components/commons/LoadingComponent/LoadingComponent';
// @end components

// @import types
import {
  BicycleStatistics,
  ConsumptionStatisticsData,
  GenerationStatisticsData,
  Statistic,
} from 'types/statistics/statistics.types';
import { DeviceCategoryChart } from 'types/deviceCategory/deviceCategory.types';
// @end types

// @import services
import { StatisticsService } from 'services/statistics/statistics.service';
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
import Bicicletas from 'assets/images/bicicletas.png';
// @end assets

// @import styles
import './LandingModule.scss';
// @end styles

interface ILandingModuleProps {
  className?: string;
  style?: CSSProperties;
  id?: string;
}

const LandingModule: React.FC<ILandingModuleProps> = (props) => {
  const [loading, updateLoading] = React.useState<boolean>(false);

  const [consumptionChart, setConsumptionChart] =
    React.useState<DeviceCategoryChart>();

  const [bicycleData, setBicycleData] = React.useState<BicycleStatistics>({
    activeBikes: 0,
    kilometersTraveled: 0,
    co2Saved: 0,
    date: new Date(),
  });

  const currentMonth = moment().locale('es').format('MMMM');

  const [generationData, setGenerationData] =
    React.useState<Statistic<GenerationStatisticsData>>();

  const [consumptionData, setConsumptionData] =
    React.useState<Statistic<ConsumptionStatisticsData>>();

  const [generationChart, setGenerationChart] =
    React.useState<DeviceCategoryChart>();

  const [performanceChart, setPerformanceChart] =
    React.useState<DeviceCategoryChart>();

  const [consumptionVsGenerationChart, setConsumptionVsGenerationChart] =
    React.useState<DeviceCategoryChart>();

  // @INFO: Services
  const statisticsService = new StatisticsService();
  // @INFO: get statistics by category
  React.useEffect(() => {
    getStatistics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStatistics = async () => {
    updateLoading(true);
    const generalStatistics = await statisticsService.allCategories();

    const consumptionDataResponse = generalStatistics?.find(
      (statistic) => statistic.category === 'electricMeter-modbus'
    ) as Statistic<ConsumptionStatisticsData>;

    setConsumptionData(consumptionDataResponse);

    const bicycleStatistics = generalStatistics?.find(
      (statistic) => statistic.category === 'bicycle'
    ) as Statistic<BicycleStatistics>;

    const generationDataResponse = generalStatistics?.find(
      (statistic) => statistic.category === 'froniusPvGenerator'
    ) as Statistic<GenerationStatisticsData>;

    setGenerationData(generationDataResponse);

    setGenerationChart(
      generationDataResponse?.charts?.find((item) =>
        item.name.includes('Generación')
      )
    );

    setPerformanceChart(
      generationDataResponse?.charts?.find((item) =>
        item.name.includes('Rendimiento')
      )
    );

    if (consumptionDataResponse && consumptionDataResponse.charts?.length > 0) {
      setConsumptionChart(consumptionDataResponse.charts[0]);
    }
    if (bicycleStatistics && bicycleStatistics.data) {
      setBicycleData(bicycleStatistics.data);
    }

    setConsumptionVsGenerationChart({
      name: 'Consumo vs generación',
      type: 'pie',
      variables: [
        {
          name: 'Energía',
          variableKey: 'energy',
          unit: 'kWh',
        },
      ],
      data: [
        {
          energy: consumptionDataResponse?.data?.accumulatedConsumption ?? 0,
          label: 'Consumo kWh',
        },
        {
          energy: generationDataResponse?.data?.accumulatedGeneration ?? 0,
          label: 'Generación kWh',
        },
      ],
    });

    updateLoading(false);
  };

  const currencyFormat = (value: number) => {
    return `$${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
  };

  return (
    <div
      className={`landing_module-layout ${
        props.className ? props.className : ''
      }`}
      style={props.style}
      id={props.id}
    >
      <TextComponent type="h2" style={{ textAlign: 'center', margin: '30px' }}>
        Información de interés
      </TextComponent>
      <TextComponent type="h2" style={{ margin: '30px' }}>
        Estadísticas generales de {currentMonth}
      </TextComponent>
      {loading && <LoadingComponent />}
      <div className="cards-grid">
        <Card sx={{ borderRadius: '20px' }}>
          <CardHeader title="Estadísticas de bicicletas" />
          <CardMedia
            component="img"
            height="200px"
            image={Bicicletas}
            alt="Paella dish"
            sx={{ objectFit: 'contain' }}
          />
          <CardContent className="statistics">
            <div className="item-statistics">
              <TextComponent type="h3">{bicycleData.activeBikes}</TextComponent>
              <TextComponent type="caption">Bicicletas Activas</TextComponent>
            </div>
            <div className="item-statistics">
              <TextComponent type="h3">
                {bicycleData.kilometersTraveled.toFixed(2)}
              </TextComponent>
              <TextComponent type="caption">km recorridos</TextComponent>
            </div>
            <div className="item-statistics">
              <TextComponent type="h3">
                {bicycleData.co2Saved.toFixed(2)}
              </TextComponent>
              <TextComponent type="caption">ppm CO2 ahorrado</TextComponent>
            </div>
          </CardContent>
        </Card>
        <Card sx={{ borderRadius: '20px' }}>
          <CardHeader title="Consumo vs generación de energía" />
          <CardContent>
            {consumptionVsGenerationChart && (
              <DeviceChartsModule
                charts={[consumptionVsGenerationChart]}
                data={consumptionVsGenerationChart.data}
                key={consumptionVsGenerationChart.name}
                dateFormat={consumptionVsGenerationChart.dateFormat}
                style={{ gridTemplateColumns: '100%' }}
                isRawLabel
              />
            )}
          </CardContent>
        </Card>
        <Card sx={{ borderRadius: '20px' }}>
          <CardHeader title="Rendimiento aproximado generación" />
          <CardContent>
            <div className="item-statistics">
              <TextComponent type="caption">
                Rendimiento acumulado:
              </TextComponent>
              <TextComponent type="h3">
                {currencyFormat(
                  generationData?.data?.accumulatedPerformance ?? 0
                )}{' '}
                COP
              </TextComponent>
            </div>
            {performanceChart && (
              <DeviceChartsModule
                charts={[performanceChart]}
                data={performanceChart.data}
                key={performanceChart.name}
                dateFormat={performanceChart.dateFormat}
                style={{ gridTemplateColumns: '100%' }}
              />
            )}
          </CardContent>
        </Card>
        <Card sx={{ borderRadius: '20px' }}>
          <CardHeader title="Estadísticas de energía generada" />
          <CardContent>
            <div className="item-statistics">
              <TextComponent type="caption">
                Generación acumulada:
              </TextComponent>
              <TextComponent type="h3">
                {generationData?.data?.accumulatedGeneration?.toFixed(2) ?? 0}{' '}
                kWh
              </TextComponent>
            </div>
            {generationChart && (
              <DeviceChartsModule
                charts={[generationChart]}
                data={generationChart.data}
                key={generationChart.name}
                dateFormat={generationChart.dateFormat}
                style={{ gridTemplateColumns: '100%' }}
              />
            )}
          </CardContent>
        </Card>
        <Card sx={{ borderRadius: '20px' }}>
          <CardHeader title="Estadísticas de energía consumida" />
          <CardContent>
            <TextComponent type="caption">Consumo acumulado:</TextComponent>
            <TextComponent type="h3">
              {consumptionData?.data?.accumulatedConsumption?.toFixed(2) ?? 0}{' '}
              kWh
            </TextComponent>
            {consumptionChart && (
              <DeviceChartsModule
                charts={[consumptionChart]}
                data={consumptionChart.data}
                key={consumptionChart.name}
                dateFormat={consumptionChart.dateFormat}
                style={{ gridTemplateColumns: '100%' }}
              />
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default LandingModule;
