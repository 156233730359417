// @import dependencies
import LandingLayout from 'components/layouts/LandingLayout/LandingLayout';
import React, { CSSProperties } from 'react';
// @end dependencies

// @import components
import BannerCarousel from '../../commons/HomeCarousel/BannerCarousel';
import Footer from 'components/modules/Footer/Footer';
// @end components

// @import types
// @end types

// @import services
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './LandingScreen.scss';
import LandingModule from 'components/modules/LandingModule/LandingModule';
// @end styles

interface IUserRegistrationScreenProps {
  className?: string;
  style?: CSSProperties;
  id?: string;
}

const LandingScreen: React.FC<IUserRegistrationScreenProps> = (props) => {
  return (
    <LandingLayout>
      <div
        className={`landing_screen-layout ${
          props.className ? props.className : ''
        }`}
        style={props.style}
        id={props.id}
      >
        <BannerCarousel
          // onlyAvailable
          classNameItem="item-banner-home-landing"
          className="banners-home-landing"
        />
        <LandingModule />
        <Footer style={{ marginTop: '100px' }} />
      </div>
    </LandingLayout>
  );
};

export default LandingScreen;
