// @import dependencies
import React from 'react';
import shortid from 'shortid';
// @end dependencies

// @import types
import * as DeviceLoggerTypes from 'types/deviceLogger/deviceLogger.types';
// @end types

// @import components
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import CheckBoxComponent from 'components/commons/CheckBoxComponent/CheckBoxComponent';
import TextComponent from 'components/commons/Text/TextComponent';
import TextFieldComponent from 'components/commons/TextFieldComponent/TextFieldComponent';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
// @end components

// @import styles
import './UpdateModal.scss';
// @end styles

// @import services
// @end services

export interface UpdateLogModalProps {
  show: boolean;
  deviceLogger?: DeviceLoggerTypes.DeviceLogger;
  setDeviceLogger: React.Dispatch<
    React.SetStateAction<DeviceLoggerTypes.DeviceLogger | undefined>
  >;
  onHide?: () => void;
  onUpdate?: (data: DeviceLoggerTypes.DeviceLogger) => Promise<void>;
}

export const UpdateLogModal: React.FC<UpdateLogModalProps> = (props) => {
  const handleClose = () => {
    if (props.onHide) {
      props.onHide();
    }
  };

  const handleResult = (result: string) => {
    props.setDeviceLogger((log) => log && { ...log, result });
  };

  const handleDescription = (description: string) => {
    props.setDeviceLogger((log) => log && { ...log, description });
  };

  const handleCheckbox = (e: any) => {
    const { name, checked } = e.target;

    props.setDeviceLogger((log) => {
      if (log) {
        return {
          ...log,
          failures: log.failures.map((f) => {
            if (f.name === name) {
              f.hasPresented = checked;
            }
            return f;
          }),
        };
      }
    });
  };

  const handleUpdate = async () => {
    if (props.onUpdate && props.deviceLogger) {
      await props.onUpdate(props.deviceLogger);

      handleClose();
    }
  };

  const results = ['Pendiente', 'Arreglado'];

  return (
    <>
      <Dialog
        open={props.show}
        onClose={handleClose}
        fullWidth
        PaperProps={{
          style: { borderRadius: 10 },
        }}
      >
        <DialogTitle fontWeight="bold">Editar registro</DialogTitle>
        <DialogContent>
          <div className="modal-body">
            <div>
              <TextComponent type="subtitle" className="mb-1">
                Tipo de falla
              </TextComponent>
              {props.deviceLogger?.failures.map(
                ({ name, label, hasPresented }) => (
                  <CheckBoxComponent
                    key={shortid.generate()}
                    label={label}
                    name={name}
                    checked={hasPresented}
                    onChange={handleCheckbox}
                  />
                )
              )}
            </div>
            <TextFieldComponent
              label="Descripción"
              fullWidth
              value={props.deviceLogger?.description || ''}
              onChange={(e) => handleDescription(e.target.value)}
            />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Resultado</InputLabel>
              <Select
                value={props.deviceLogger?.result || 'Seleccionar'}
                label="Resultado"
                onChange={(e) => handleResult(e.target.value)}
              >
                {results.map((result: string) => (
                  <MenuItem key={shortid.generate()} value={result}>
                    {result}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <ButtonComponent variant="secondary" onClick={handleClose}>
            Cancelar
          </ButtonComponent>
          <ButtonComponent variant="primary" onClick={handleUpdate}>
            Actualizar
          </ButtonComponent>
        </DialogActions>
      </Dialog>
    </>
  );
};
