// @import dependencies
import React, { CSSProperties, useState } from 'react';
// @end dependencies

// @import components
import { Redirect } from 'react-router-dom';
import ButtonComponent from '../../commons/Button/ButtonComponent';
import TextFieldComponent from '../../commons/TextFieldComponent/TextFieldComponent';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
// @end components

// @import types
import { StoreApp } from 'redux/reducers';
// @end types

// @import services
// @end services

// @import hooks
import { useDispatch, useSelector } from 'react-redux';
// @end hooks

// @import actions
import * as AuthActions from 'redux/reducers/auth/auth.actions';
// @end actions

// @import utils
import { HOME_ROUTE } from 'config/globals';
// @end utils

// @import assets
import logo_scada from 'assets/icons/logo_scada.svg';
// @end assets

// @import styles
import './LoginModule.scss';
// @end styles

interface ILoginModuleProps {
  className?: string;
  style?: CSSProperties;
  id?: string;
}

const LoginModule: React.FC<ILoginModuleProps> = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const isAuth = useSelector((store: StoreApp) => store.auth.isLogged);
  const mode = useSelector((store: StoreApp) => store.app.theme);

  const handleClickLogin = () => {
    dispatch(AuthActions.loginAction(email, password));
  };

  return (
    <div
      className={`login_module-layout ${
        props.className ? props.className : ''
      }`}
      style={props.style}
      id={props.id}
    >
      <div className="page-wrapper">
        <div className="container-fluid p-0">
          {/* <!-- login page start--> */}
          <div className="authentication-main">
            <div className="row">
              <div className="col-md-12">
                <div className="auth-innerright">
                  <div className="authentication-box">
                    <div className="text-center">
                      <img
                        src={logo_scada}
                        alt=""
                        width="200px"
                        style={{ paddingLeft: '10px', paddingRight: '10px' }}
                      />
                    </div>
                    <div className="mt-2">
                      <div className="card-body">
                        <form className="theme-form">
                          <div className="container-fields mb-3">
                            <div
                              style={{ backgroundColor: 'transparent' }}
                              className="input-group-text"
                            >
                              <PersonRoundedIcon
                                sx={{
                                  color:
                                    mode === 'dark'
                                      ? 'primary.main'
                                      : 'var(--background-dark)',
                                }}
                              />
                            </div>
                            <TextFieldComponent
                              label="Correo electrónico"
                              type="email"
                              name="email"
                              value={email}
                              size="small"
                              fullWidth
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                          <div className="container-fields">
                            <div
                              style={{
                                backgroundColor: 'transparent',
                              }}
                              className="input-group-text"
                            >
                              <LockRoundedIcon
                                sx={{
                                  color:
                                    mode === 'dark'
                                      ? 'primary.main'
                                      : 'var(--background-dark)',
                                }}
                              />
                            </div>
                            <TextFieldComponent
                              label="Contraseña"
                              type="password"
                              name="password"
                              value={password}
                              size="small"
                              fullWidth
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </div>
                          <div className="container-buttons">
                            <ButtonComponent
                              type="button"
                              variant="primary"
                              onClick={handleClickLogin}
                            >
                              Iniciar Sesión
                            </ButtonComponent>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isAuth ? <Redirect to={HOME_ROUTE} /> : null}
    </div>
  );
};

export default LoginModule;
