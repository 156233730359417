// @import dependencies
import React, { CSSProperties, useEffect, useState } from 'react';
// @end dependencies

// @import components
import BreadcrumbComponent from 'components/commons/Breadcrumb/BreadcrumbComponent';

import { Map as LeafletMap, TileLayer, Marker, Popup } from 'react-leaflet';
import { Link } from 'react-router-dom';
import LoadingComponent from 'components/commons/LoadingComponent/LoadingComponent';
import TextComponent from '../../commons/Text/TextComponent';
import AppLayout from 'components/layouts/AppLayout/AppLayout';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import StatusMap from 'components/commons/StatusMap/StatusMap';
// @end components

// @import types
import * as DeviceTypes from 'types/device/device.types';
// @end types

// @import services
import { DashboardFunctions } from './DashboardFunctions';
import DeviceService from 'services/device/device.service';
import SocketService from 'services/socket/socketService';
// @end services

// @import hooks
import { useSelector } from 'react-redux';
import { StoreApp } from 'redux/reducers';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import styles
import './DashboardPage.scss';
// @end styles

interface IDashboardPageProps {
  className?: string;
  style?: CSSProperties;
  id?: string;
}

interface StatusData {
  macAddress: string;
  status: DeviceTypes.TypeStatusDevice;
}

const DashboardPage: React.FC<IDashboardPageProps> = (props) => {
  const [loading, updateLoading] = useState<boolean>(false);
  const [devices, updateDevices] = useState<DeviceTypes.Device[]>([]);
  const [statusDevices, updateStatusDevices] = useState<StatusData[]>([]);
  const refMap = React.useRef<LeafletMap>(null);
  const [redraw, setRedraw] = useState(true);
  const mode = useSelector((store: StoreApp) => store.app?.theme);

  // Services
  const deviceService = new DeviceService();
  const dashboardFunctions = new DashboardFunctions();
  const socketService = new SocketService();

  useEffect(() => {
    getDevices();
    // Suscribirme al evento
    socketService.listenEvent('deviceStatus', handleNewData);
    return () => {
      socketService.removeListenEvent('deviceStatus', handleNewData);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (devices?.length) {
      const _status: StatusData[] = [];
      devices.forEach((device) => {
        _status.push({
          macAddress: device.macAddress,
          status: device.deviceStatus,
        });
      });
      updateStatusDevices(_status);
    }
  }, [devices, devices?.length]);

  useEffect(() => {
    setRedraw(false);
    setTimeout(() => {
      setRedraw(true);
    }, 1);
  }, [mode]);

  const getDevices = async () => {
    updateLoading(true);
    const response = await deviceService.getAll();
    updateDevices(response);
    updateLoading(false);
  };

  const handleNewData = (data?: StatusData) => {
    if (data?.macAddress) {
      updateStatusDevices((statusDevices) => {
        const idx = statusDevices.findIndex(
          (d) => d.macAddress === data.macAddress
        );
        const newStatus = [...statusDevices];
        if (idx >= 0) {
          newStatus[idx].status = data.status;
        }
        return newStatus;
      });
    }
  };
  return (
    <AppLayout>
      <div
        className={`dashboard_page-layout ${
          props.className ? props.className : ''
        }`}
        style={props.style}
        id={props.id}
      >
        <BreadcrumbComponent
          Title="VISTA PRINCIPAL"
          Item_one="Dashboard"
          Item_two="Vista principal"
          disabled_Title={true}
          disabled_Item_one={false}
          disabled_Item_two={true}
        />
        <Card>
          <CardContent className="container-map-dashboard">
            {loading && <LoadingComponent />}
            {redraw && (
              <LeafletMap
                ref={refMap}
                center={[1.2317661, -77.2936107]}
                zoom={18}
                maxZoom={19}
              >
                <TileLayer
                  attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  className={mode === 'dark' ? 'map-tiles' : ''}
                />
                {devices
                  .filter(
                    (device) =>
                      device.location?.latitude && device.location?.longitude
                  )
                  .map((device) => (
                    <Marker
                      key={device.id}
                      icon={dashboardFunctions.getIconByCategory(
                        device.category.name
                      )}
                      position={[
                        device.location.latitude,
                        device.location.longitude,
                      ]}
                    >
                      <StatusMap
                        device={device}
                        status={
                          statusDevices.find(
                            (s) => s.macAddress === device.macAddress
                          )?.status as DeviceTypes.TypeStatusDevice
                        }
                      />
                      <Popup>
                        <div className="marker-popup-content">
                          <TextComponent>{device.name}</TextComponent>
                          <TextComponent>
                            Estado:{' '}
                            {
                              statusDevices.find(
                                (s) => s.macAddress === device.macAddress
                              )?.status
                            }
                          </TextComponent>
                          <Link to={`device/${device.id}`}>
                            <ButtonComponent
                              variant="primary"
                              style={{
                                backgroundColor: 'var(--primary)',
                                borderColor: 'var(--primary)',
                              }}
                            >
                              Ver más
                            </ButtonComponent>
                          </Link>
                        </div>
                      </Popup>
                    </Marker>
                  ))}
              </LeafletMap>
            )}
          </CardContent>
        </Card>
      </div>
    </AppLayout>
  );
};

export default DashboardPage;
