// @import dependencies
import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import shortid from 'shortid';
// @end dependencies

// @import components
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
// @end components

// @import types
import { StoreApp } from 'redux/reducers';
// @end types

// @import services
// @end services

// @import hooks
import { useDispatch, useSelector } from 'react-redux';
import { updateThemeAppAction } from 'redux/reducers/app/app.actions';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
import logo_scada from 'assets/icons/logo_scada.svg';
// @end assets

// @import styles
import { styled } from '@mui/material/styles';
import './LandingHeaderModule.scss';
// @end styles

interface IHeaderModuleProps {
  className?: string;
  style?: CSSProperties;
  id?: string;
  hideMenu?: boolean;
  hideProfile?: boolean;
  onClickOpen?: () => void;
}

const drawerWidth = 270;
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  position: 'relative',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'center',
}));

const LandingHeaderModule: React.FC<IHeaderModuleProps> = (props) => {
  const mode = useSelector((store: StoreApp) => store.app?.theme);
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleChangeTheme = () => {
    if (mode === 'light') {
      dispatch(updateThemeAppAction('dark'));
    } else if (mode === 'dark') {
      dispatch(updateThemeAppAction('light'));
    }
  };

  const drawer = (
    <div className="drawer">
      <DrawerHeader>
        <IconButton onClick={handleDrawerToggle}>
          <CloseRoundedIcon />
        </IconButton>
      </DrawerHeader>
      <List>
        <Link to={'/'} className="link" key={shortid.generate()}>
          <ListItemButton className="menu-item">
            <ListItemText
              primary={'Inicio'}
              sx={{ margin: '0px 0px', textAlign: 'center' }}
            />
          </ListItemButton>
        </Link>
        <Link to={'/'} className="link" key={shortid.generate()}>
          <ListItemButton className="menu-item">
            <ListItemText
              primary={'Acerca de'}
              sx={{ margin: '0px 0px', textAlign: 'center' }}
            />
          </ListItemButton>
        </Link>
        <Link to={'/login'} className="link" key={shortid.generate()}>
          <ListItemButton className="menu-item">
            <ListItemText
              primary={'SCADA'}
              sx={{ margin: '0px 0px', textAlign: 'center' }}
            />
          </ListItemButton>
        </Link>
        <Link to={'/bicicletas'} className="link" key={shortid.generate()}>
          <ListItemButton className="menu-item">
            <ListItemText
              primary={'Bicicletas'}
              sx={{ margin: '0px 0px', textAlign: 'center' }}
            />
          </ListItemButton>
        </Link>
        <Link to={'/repositorio'} className="link" key={shortid.generate()}>
          <ListItemButton className="menu-item">
            <ListItemText
              primary={'Repositorio'}
              sx={{ margin: '0px 0px', textAlign: 'center' }}
            />
          </ListItemButton>
        </Link>
        <Link to={'/login'} className="link" key={shortid.generate()}>
          <ListItemButton className="menu-item">
            <ListItemText
              primary={'Iniciar sesión'}
              sx={{ margin: '0px 0px', textAlign: 'center' }}
            />
          </ListItemButton>
        </Link>
        <Link to={'/register'} className="link" key={shortid.generate()}>
          <ListItemButton className="menu-item">
            <ListItemText
              primary={'Registrarse'}
              sx={{ margin: '0px 0px', textAlign: 'center' }}
            />
          </ListItemButton>
        </Link>
      </List>
    </div>
  );

  const headerItems = (
    <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: '10px' }}>
      <Link className="link" to={'/'}>
        <Button key={shortid.generate()} className="item">
          Inicio
        </Button>
      </Link>
      <Button key={shortid.generate()} className="item">
        Acerca de
      </Button>
      <Link className="link" to={'/login'}>
        <Button key={shortid.generate()} className="item">
          SCADA
        </Button>
      </Link>
      <Link
        className="link"
        to={{
          pathname: 'https://campusverde.udenar.edu.co/bicicletas',
        }}
        target="_parent"
      >
        <Button key={shortid.generate()} className="item">
          Bicicletas
        </Button>
      </Link>
      <Link
        className="link"
        to={{
          pathname: 'https://campusverde.udenar.edu.co/repositorio',
        }}
        target="_parent"
      >
        <Button key={shortid.generate()} className="item">
          Repositorio
        </Button>
      </Link>
      <Link className="link" to={'/login'}>
        <Button sx={{ color: 'white' }} variant="contained">
          Iniciar sesión
        </Button>
      </Link>
      <Link className="link" to={'/register'}>
        <Button sx={{ color: 'white' }} variant="contained">
          Registrarse
        </Button>
      </Link>
    </Box>
  );

  return (
    <div
      className={`header_module-layout ${
        props.className ? props.className : ''
      }`}
      style={props.style}
      id={props.id}
    >
      <Box className="box" sx={{ bgcolor: 'background.paper' }}>
        <div className="start-header">
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <Tooltip title="Menú">
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Link to={'/'}>
            <img src={logo_scada} className="img-header-scada-icon" alt="" />
          </Link>
        </div>
        <div className="end-header">
          {headerItems}
          <Tooltip title="Cambiar tema">
            <IconButton sx={{ height: '40px' }} onClick={handleChangeTheme}>
              {mode === 'light' ? (
                <DarkModeRoundedIcon />
              ) : (
                <LightModeRoundedIcon color="primary" />
              )}
            </IconButton>
          </Tooltip>
        </div>
      </Box>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={open}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </div>
  );
};

export default LandingHeaderModule;
