// @import components
import L from 'leaflet';
// @end components

// @import assets
import Medidor from 'assets/images/meter.png';
import Lamp from 'assets/images/light.png';
import Warning from 'assets/images/warning.png';
import Weather from 'assets/images/weather.png';
import Wind from 'assets/images/wind.png';
import Rfid from 'assets/images/rfid.png';
import Fronius from 'assets/images/fronius.png';
import SunWt from 'assets/images/sun_wt.jpg';
// @end assets

// @import service
import ColorService from 'services/color/color.service';
// @end service

// @import types
// @end types

const meterIcon = L.icon({
  iconUrl: Medidor,
  iconSize: [24, 24],
  iconAnchor: [24, 24],
});
const windTurbineIcon = L.icon({
  iconUrl: Wind,
  iconSize: [24, 24],
  iconAnchor: [24, 24],
});
const luminaryIcon = L.icon({
  iconUrl: Lamp,
  iconSize: [24, 24],
  iconAnchor: [24, 24],
});
const warningIcon = L.icon({
  iconUrl: Warning,
  iconSize: [24, 24],
  iconAnchor: [24, 24],
});
const weatherStationIcon = L.icon({
  iconUrl: Weather,
  iconSize: [24, 24],
  iconAnchor: [24, 24],
});
const rfidReaderIcon = L.icon({
  iconUrl: Rfid,
  iconSize: [24, 24],
  iconAnchor: [24, 24],
});

const froniusIcon = L.icon({
  iconUrl: Fronius,
  iconSize: [24, 24],
  iconAnchor: [24, 24],
});

const sunWtIcon = L.icon({
  iconUrl: SunWt,
  iconSize: [24, 24],
  iconAnchor: [24, 24],
});

export class DashboardFunctions {
  public colorService = new ColorService();

  public getIconByCategory = (category: string) => {
    switch (category) {
      case 'weatherStation':
        return weatherStationIcon;
      case 'electricMeter-modbus':
        return meterIcon;
      case 'electricGenerator-windTurbine':
        return windTurbineIcon;
      case 'luminary':
        return luminaryIcon;
      case 'rfidReader':
        return rfidReaderIcon;
      case 'froniusPvGenerator':
        return froniusIcon;
      case 'sunWtGenerator':
        return sunWtIcon;
      default:
        return warningIcon;
    }
  };
}
