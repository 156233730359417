// @import dependencies
import React, { CSSProperties } from 'react';
// @end dependencies

// @import components
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
// @end components

// @import types
import { StoreApp } from 'redux/reducers/index';
// @end types

// @import services
// @end services

// @import hooks
import { useDispatch, useSelector } from 'react-redux';
// @end hooks

// @import actions
import * as AuthActions from 'redux/reducers/auth/auth.actions';
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './UserMenuModule.scss';
// @end styles

interface IUserMenuModuleProps {
  className?: string;
  style?: CSSProperties;
  id?: string;
}

const UserMenuModule: React.FC<IUserMenuModuleProps> = (props) => {
  const authenticated = useSelector((store: StoreApp) => store.auth?.isLogged);
  const user = useSelector((store: StoreApp) => store.auth?.user);
  const dispatch = useDispatch();

  const handleClickLogout = () => {
    dispatch(AuthActions.logoutAction());
  };

  return (
    <div
      className={`user_menu_module-layout ${
        props.className ? props.className : ''
      }`}
      style={props.style}
      id={props.id}
    >
      <div className="onhover-dropdown">
        <div className="media align-items-center">
          <img
            src={
              authenticated && user?.userPicture?.length
                ? user.userPicture
                : 'https://static.vecteezy.com/system/resources/previews/002/275/847/original/male-avatar-profile-icon-of-smiling-caucasian-man-vector.jpg'
            }
            alt="header-user"
          />
        </div>
        <ul className="profile-dropdown onhover-show-div profile-dropdown-hover">
          <Card elevation={2} sx={{ borderRadius: '10px' }}>
            <CardContent>
              <Link className="link" to={`/users/profile/general`}>
                <Button
                  className="menu-item"
                  startIcon={<AccountCircleRoundedIcon />}
                >
                  Mi cuenta
                </Button>
              </Link>
              <Link
                className="link"
                to="#"
                onClick={handleClickLogout}
                href="#javascript"
              >
                <Button className="menu-item" startIcon={<LogoutRoundedIcon />}>
                  Cerrar sesión
                </Button>
              </Link>
            </CardContent>
          </Card>
        </ul>
      </div>
    </div>
  );
};

export default UserMenuModule;
