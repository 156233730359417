import React, { useEffect } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import RouterService from 'services/router/routerService';
import { routesApp } from 'routes/routes';
import AuthService from 'services/auth/authService';
import { useDispatch, useSelector } from 'react-redux';
import { StoreApp } from 'redux/reducers/index';
import * as AuthActions from 'redux/reducers/auth/auth.actions';
import { ToastContainer } from 'react-toastify';
import LoadingPage from 'components/screens/LoadingPage/LoadingPage';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ColorService from 'services/color/color.service';
import storeService from 'services/store/storeService';
import { ITypeThemeApp } from 'types/app/app.types';
import { updateThemeAppAction } from 'redux/reducers/app/app.actions';

import 'react-toastify/dist/ReactToastify.css';
import './MasterLayout.scss';

export interface MasterLayoutProps {}

const MasterLayout: React.FC<MasterLayoutProps> = (props) => {
  const routerService = new RouterService();
  const authService = new AuthService();
  const hasData = useSelector((store: StoreApp) => store.auth.hasData);
  const dispatch = useDispatch();
  const mode = useSelector((store: StoreApp) => store.app?.theme);

  // @INFO: services
  const colorService = new ColorService();

  const customTheme = createTheme({
    palette: colorService.getPalette(mode),
  });

  useEffect(() => {
    storeService?.subscribe('app.theme', updateTheme, false);
    return () => {
      storeService?.unsubscribe('app.theme', updateTheme);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const token = authService.getToken();
    if (token && !hasData) {
      // @INFO Si hay token y no hay data hago el login con token
      dispatch(AuthActions.loginByTokenAction({ token: String(token) }));
    }

    // @INFO Establecer el tema por defecto
    const currentTheme = colorService.getThemeColorStorage();
    if (currentTheme) {
      dispatch(updateThemeAppAction(currentTheme));
    } else {
      dispatch(updateThemeAppAction('light'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateTheme = (type: ITypeThemeApp) => {
    if (!type) return;
    colorService.setThemeColorStorage(type);
  };

  return (
    <ThemeProvider theme={customTheme}>
      <div className="master_layout-layout">
        <Box sx={{ bgcolor: 'background.default', color: 'text.primary' }}>
          {authService.getToken() && !hasData ? (
            <LoadingPage />
          ) : (
            <>
              <BrowserRouter>
                <Switch>
                  {routesApp.map((itemRoute) =>
                    routerService.renderRoute(itemRoute)
                  )}
                </Switch>
              </BrowserRouter>
              <ToastContainer />
            </>
          )}
        </Box>
      </div>
    </ThemeProvider>
  );
};

export default MasterLayout;
