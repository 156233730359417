import React, { CSSProperties } from 'react';

import './CheckBoxComponent.scss';
import TextComponent from 'components/commons/Text/TextComponent';

interface ICheckBoxComponentProps {
  className?: string;
  style?: CSSProperties;
  checked?: boolean;
  label?: string;
  ref?: any;
  name?: string;
  disabled?: boolean;
  required?: boolean;
  value?: string;
  defaultChecked?: boolean;
  defaultValue?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onClick?: () => any;
}

const CheckBoxComponent: React.FC<ICheckBoxComponentProps> = (props) => {
  return (
    <div
      className={`check_box_component-layout ${
        props.className ? props.className : ''
      }`}
      style={props.style}
    >
      <label className="container">
        {props.label ? <TextComponent>{props.label}</TextComponent> : null}
        <input
          type="checkbox"
          checked={props.checked}
          name={props.name}
          onChange={props.onChange}
          ref={props.ref}
          disabled={props.disabled}
          required={props.required}
          value={props.value}
          defaultChecked={props.defaultChecked}
          defaultValue={props.defaultValue}
        />
        <span className="checkmark-check"></span>
      </label>
    </div>
  );
};

export default CheckBoxComponent;
