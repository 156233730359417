// @import dependencies
import React, { CSSProperties } from 'react';
import { toast } from 'react-toastify';
// @end dependencies

// @import components
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import TextComponent from 'components/commons/Text/TextComponent';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import DirectionsCarRoundedIcon from '@mui/icons-material/DirectionsCarRounded';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ListItemIcon from '@mui/material/ListItemIcon';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import UpdateUserVehicleModal from '../UpdateUserVehicleModal/UpdateUserVehicleModal';
import AddIcon from '@mui/icons-material/AddRounded';
// @end components

// @import types
import * as UserTypes from 'types/user/user.types';
// @end types

// @import services
import UserService from 'services/user/user.service';
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './UserVehicleModule.scss';
import Fab from '@mui/material/Fab';
// @end styles
interface IProfileModuleProps {
  className?: string;
  style?: CSSProperties;
  id?: string;
  onClose?: () => void;
  userId?: string;
}

const UserVehicleModule: React.FC<IProfileModuleProps> = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShowNew, setModalShowNew] = React.useState(false);
  const [user, setUser] = React.useState<UserTypes.User>();
  const [vehicleId, setVehicleId] = React.useState<string>();

  // @INFO: Services
  const userService = new UserService();

  const handleClose = () => {
    props.onClose && props.onClose();
  };
  React.useEffect(() => {
    handleGetById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetById = async () => {
    if (props.userId) {
      const response = await userService.getById(props.userId);
      if (response) {
        setUser(response);
      }
    }
  };

  const handleRemoveVehicle = async (vehicleId: string) => {
    if (user?.id) {
      const response = await userService.removeVehicle(user.id, vehicleId);
      if (response) {
        toast.success('Borrado correctamente');
        handleGetById();
      }
    }
  };

  return (
    <div
      className={`user_vehicle_module-layout ${
        props.className ? props.className : ''
      }`}
      style={props.style}
      id={props.id}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <TextComponent type="subtitle" className="ml-3">
            {user?.firstName} {user?.lastName}
          </TextComponent>
        </Toolbar>
      </AppBar>
      <div className="content">
        <img src={user?.userPicture} alt="header-user" />
        <div className="title_vehicles">
          <TextComponent type="subtitle">Vehículos asociados</TextComponent>
          <Fab
            className="fab"
            onClick={() => setModalShowNew(true)}
            color="primary"
            aria-label="add"
          >
            <AddIcon />
          </Fab>
        </div>
        {user?.configuration?.associatedVehicles &&
        user.configuration.associatedVehicles.length > 0 ? (
          <List sx={{ width: '100%', maxWidth: 360 }}>
            {user?.configuration?.associatedVehicles?.map((vehicle, index) => (
              <div key={vehicle.id}>
                <ListItem>
                  <IconButton onClick={() => handleRemoveVehicle(vehicle.id)}>
                    <DeleteRoundedIcon />
                  </IconButton>
                  <ListItemButton
                    onClick={() => {
                      setVehicleId(vehicle.id);
                      setModalShow(true);
                    }}
                  >
                    <ListItemIcon>
                      <DirectionsCarRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary={vehicle.name} />
                    <ArrowForwardIosRoundedIcon />
                  </ListItemButton>
                </ListItem>
                {index >= 0 && <Divider />}
              </div>
            ))}
          </List>
        ) : (
          <TextComponent className="mt-3">
            Aun no tienes vehículos asociados...
          </TextComponent>
        )}
        {modalShow && (
          <UpdateUserVehicleModal
            deviceId={vehicleId}
            userId={user?.id}
            show={modalShow}
            onHide={() => {
              setModalShow(false);
              handleGetById();
            }}
          ></UpdateUserVehicleModal>
        )}
        {modalShowNew && (
          <UpdateUserVehicleModal
            show={modalShowNew}
            userId={user?.id}
            onHide={() => {
              setModalShowNew(false);
              handleGetById();
            }}
          />
        )}
      </div>
    </div>
  );
};

export default UserVehicleModule;
