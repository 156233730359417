import { IRouteApp } from 'types/routes/routes.types';

// @import Screens
import LoginPage from 'components/screens/LoginPage/LoginPage';
import UserManagementScreen from 'components/screens/UserManagementScreen/UserManagementScreen';
import DashboardPage from 'components/screens/DashboardPage/DashboardPage';
import DeviceRegistrationPage from 'components/screens/DeviceRegistrationPage/DeviceRegistrationPage';
import DevicesListPage from 'components/screens/DevicesListPage/DevicesListPage';
import DevicePage from 'components/screens/DevicePage/DevicePage';
import UserRegistrationScreen from 'components/screens/UserRegistrationScreen/UserRegistrationScreen';
import ProfileScreen from 'components/screens/ProfileScreen/ProfileScreen';
import SignUpScreen from 'components/screens/SignUpScreen/SignUpScreen';
import LandingScreen from 'components/screens/LandingScreen/LandingScreen';
import { HOME_ROUTE } from '../config/globals';
import TagAssignmentScreen from 'components/screens/TagAssignmentScreen/TagAssignmentScreen';
import VehicleValidationScreen from 'components/screens/VehicleValidationScreen/VehicleValidationScreen';
import BannerManagementScreen from 'components/screens/BannerManagementScreen/BannerManagementScreen';
import BannerRegistrationScreen from '../components/screens/BannerRegistrationScreen/BannerRegistrationScreen';
// @end

export const routesApp: IRouteApp[] = [
  {
    component: LandingScreen,
    path: '/',
    exact: true,
  },
  {
    component: LoginPage,
    path: '/login',
    exact: true,
  },
  {
    component: UserRegistrationScreen,
    path: '/register',
    exact: true,
  },
  {
    component: DashboardPage,
    path: HOME_ROUTE,
    exact: true,
    private: {
      state: true,
      conditions: ['auth'],
      permissions: ['dashboard_screen'],
    },
  },
  {
    component: UserManagementScreen,
    path: '/users/management',
    exact: true,
    private: {
      state: true,
      conditions: ['auth'],
      permissions: ['users_management_screen'],
    },
  },
  {
    component: SignUpScreen,
    path: '/users/register',
    exact: true,
    private: {
      state: true,
      conditions: ['auth'],
      permissions: ['users_register_screen'],
    },
  },
  {
    component: DevicesListPage,
    path: '/watch',
    pathVariables: ['category'],
    exact: true,
    private: {
      state: true,
      conditions: ['auth'],
      permissions: ['devices_list_screen'],
    },
  },
  {
    component: DevicePage,
    path: '/device',
    pathVariables: ['id'],
    exact: true,
    private: {
      state: true,
      conditions: ['auth'],
      permissions: ['device_screen'],
    },
  },
  {
    component: DeviceRegistrationPage,
    path: '/devices/registration',
    exact: true,
    private: {
      state: true,
      conditions: ['auth'],
      permissions: ['devices_registration_screen'],
    },
  },
  {
    component: ProfileScreen,
    path: '/users/profile',
    pathVariables: ['child'],
    exact: true,
    private: {
      state: true,
      conditions: ['auth'],
      permissions: ['profile_screen'],
    },
  },
  {
    component: TagAssignmentScreen,
    path: '/vehicleAccess/tag-assignment',
    exact: true,
    private: {
      state: true,
      conditions: ['auth'],
      permissions: ['tag_assignment_screen'],
    },
  },
  {
    component: VehicleValidationScreen,
    path: '/vehicleAccess/validation',
    exact: true,
    private: {
      state: true,
      conditions: ['auth'],
      permissions: ['vehicle_validation_screen'],
    },
  },
  {
    component: BannerManagementScreen,
    path: '/banners/management',
    exact: true,
    private: {
      state: true,
      conditions: ['auth'],
      permissions: ['banners_management_screen'],
    },
  },
  {
    component: BannerRegistrationScreen,
    path: '/banners/register',
    exact: true,
    private: {
      state: true,
      conditions: ['auth'],
      permissions: ['banner_register_screen'],
    },
  },
];
