// @import dependencies
import React, { CSSProperties } from 'react';
// @end dependencies

// @import components
// @end components

// @import types
// @end types

// @import services
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
import aliadosImg from 'assets/icons/aliados2.svg';
// @end assets

// @import styles
import './Footer.scss';
// @end styles

interface IFooterProps {
  className?: string;
  style?: CSSProperties;
  id?: string;
}

const Footer: React.FC<IFooterProps> = (props) => {
  return (
    <div
      className={`footer-layout ${props.className ? props.className : ''}`}
      style={props.style}
      id={props.id}
    >
      <img src={aliadosImg} alt="" />
    </div>
  );
};

export default Footer;
