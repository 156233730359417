import moment from "moment"
import store from "redux/store"
import { IData, ITypesFilterData } from "types/data/data.types"

class DataService{

    private token: string | undefined
    private storeObject = store
    
    constructor(){
        this.token = this.storeObject.getState().auth.token
    }

    /**
     * @INFO Filtrar variables desde los datos
     * @param _data 
     * @param key 
     */
    public filterVariablesFromData = (_data: IData[], key: ITypesFilterData | string, defaultValue?: any) => {
        let response: any[] = []
        // @INFO Validaciones
        if(!_data?.length) return response
        if(!key || typeof key !== 'string') return response
        // @INFO Rescatar los valores según la key
        response = _data.reduce((accum: any[], item) => {
            const aux = this.getDataFromRoot(key, {...item})
            if(aux !== undefined && aux !== null){
                accum.push(aux)
            }else{
                accum.push(defaultValue !== undefined ? defaultValue : 0)
            }
            return accum
        },[])
        return response
    }   

    /**
     * @INFO Obtener la data de la variable para graficar
     * @param _data 
     * @param key 
     */
    public getVariableDataForChart = (_data: IData[], key: ITypesFilterData | string) => {
        let response: any = {
            labels: [],
            data: []
        }
        // @INFO Validaciones
        if(!_data?.length) return response
        if(!key || typeof key !== 'string') return response
        // @INFO Organizar la data
        const varData = this.filterVariablesFromData(_data, key, 0)
        const varDate = this.filterVariablesFromData(_data, 'date', '')
        response.labels = [...varDate]
        response.data = [...varData]
        return response 
    }

    /**
     * @INFO Obtener la fecha de los datos formateada
     * @param _data 
     * @param key 
     */
    public getDateFormate = (_data: IData[], format?: string) => {
        let response: any[] = [] 
        // @INFO Validaciones
        if(!_data?.length) return response
        // Encontrar y formatear la data
        const dateVector = this.filterVariablesFromData(_data, 'date', '')
        response = dateVector.reduce((accum: any[], item: any) => {
            const aux = new Date(item)
            const newFormat = moment(aux).format(format ? format : "MMM DD HH:mm:ss")
            accum.push(newFormat)
            return accum
        },[])
        return response
    }

    /**
     * @INFO Encontrar información desde una ruta
     * @param root 
     * @param data 
     * @returns 
     */
    public getDataFromRoot = (root: string, data: any) => {
        const roots = root.split('.')
        const value = roots.reduce((acum: any, item: string) => {
            if(acum === undefined || acum === null) return undefined
            acum = acum[item]
            return acum
        }, {...data})
        return value
    }
}

export default DataService