export class DeviceLoggerFunctions {
  getKeysTable = () =>
    [
      {
        key: 'date',
        name: 'Fecha',
        formatMoment: 'DD/MM/YYYY HH:mm:ss',
      },
      {
        key: 'status',
        name: 'Estado',
      },
      {
        key: 'failures',
        type: 'list',
        name: 'Tipos de fallas',
      },
      {
        key: 'description',
        name: 'Descripción',
      },
      {
        key: 'result',
        name: 'Resultado',
      },
    ] as any;
}
