// @import dependencies
import React, { CSSProperties } from 'react'
// @end dependencies

// @import components
// @end components

// @import types
// @end types

// @import services
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
import FACEBOOK_IMG from 'assets/icons/redes/FACEBOOK.svg';
import INSTAGRAM_IMG from 'assets/icons/redes/INSTAGRAM.svg';
import LINKEDIN_IMG from 'assets/icons/redes/LINKEDIN.svg';
import TWITTER_IMG from 'assets/icons/redes/TWITTER.svg';
import YOUTUBE_IMG from 'assets/icons/redes/YOUTUBE.svg';
// @end assets

// @import styles
import './NetworkButtons.scss'
// @end styles

interface INetworkButtonsProps {
    className?: string
    style?: CSSProperties
    id?: string
    
}

const NetworkButtons: React.FC<INetworkButtonsProps> = (props) => {
    return(
        <div
            className={`network_buttons-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            <img src={FACEBOOK_IMG} alt="" />
            <img src={INSTAGRAM_IMG} alt="" />
            <img src={LINKEDIN_IMG} alt="" />
            <img src={TWITTER_IMG} alt="" />
            <img src={YOUTUBE_IMG} alt="" />
        </div>
    );
}

export default NetworkButtons;