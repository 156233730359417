// @import dependencies
import React, { CSSProperties, useState, useEffect } from 'react';
// @end dependencies

// @import components
import RadioButtonComponent from 'components/commons/RadioButton/RadioButton';
import TextComponent from 'components/commons/Text/TextComponent';
// @end components

// @import types
import * as DeviceCategoryTypes from 'types/deviceCategory/deviceCategory.types';
import { Device } from 'types/device/device.types';
import * as DataTypes from 'types/data/data.types';
// @end types

// @import services
import SocketService from 'services/socket/socketService';
import DataService from 'services/data/dataService';
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './RadioControlModule.scss';
// @end styles

interface IRadioControlModuleProps {
  control: DeviceCategoryTypes.DeviceCategoryControl;
  device: Device;
  className?: string;
  style?: CSSProperties;
  id?: string;
  lastData?: DataTypes.IData;
}

const RadioControlModule: React.FC<IRadioControlModuleProps> = (props) => {
  const [value, updateValue] = useState<string>();

  // @INFO Servicios
  const socketService = new SocketService();
  const dataService = new DataService();

  useEffect(() => {
    // @INFO Suscribirme al evento
    return () => {};
  }, []);

  useEffect(() => {
    if (props.lastData) {
      const _data = dataService.getDataFromRoot(
        props.control.variableKey,
        props.lastData
      );
      if (_data !== undefined) {
        updateValue(_data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.control.variableKey, props.lastData]);

  const handleClick = (_value: string) => {
    updateValue(_value);
    sendDataSocket(_value);
  };

  const sendDataSocket = (_value: string) => {
    socketService.emitEvent(`${props.device?.category?.name}`, {
      event: `${props.control.event}/${props.device?.macAddress}`,
      payload: _value,
    });
  };

  return (
    <div
      className={`radio_control_module-layout ${
        props.className ? props.className : ''
      }`}
      style={props.style}
      id={props.id}
    >
      <div className="container-radios-list-control">
        {props.control?.items?.map((i) => (
          <RadioButtonComponent
            label={i.label}
            key={i.value}
            value={i.value}
            name={`radio_control_${props.control.variableKey}`}
            checked={value === i.value}
            onClick={() => handleClick(i.value)}
          />
        ))}
      </div>
      {props.control?.name ? (
        <TextComponent
          type="label"
          style={{
            textAlign: 'center',
          }}
        >
          {props.control.name}
        </TextComponent>
      ) : null}
    </div>
  );
};

export default RadioControlModule;
