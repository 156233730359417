// @import dependencies
import React, { CSSProperties } from 'react'
// @end dependencies

// @import components
import { ScaleLoader } from 'react-spinners';
import TextComponent from '../Text/TextComponent';
// @end components

// @import types
// @end types

// @import services
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './LoadingComponent.scss'
// @end styles

interface ILoadingComponentProps {
    className?: string
    style?: CSSProperties
    id?: string
    
}

const LoadingComponent: React.FC<ILoadingComponentProps> = (props) => {
    return(
        <div
            className={`loading_component-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            <TextComponent>
                Cargando...
            </TextComponent>
            <ScaleLoader
                height={20}
                width={10}
                radius={4}
                margin={5}
                color='var(--primary)'
            />
        </div>
    );
}

export default LoadingComponent;