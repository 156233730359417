// @import dependencies
import React, { CSSProperties } from 'react';
import shortid from 'shortid';
// @end dependencies

// @import components
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import TextComponent from 'components/commons/Text/TextComponent';
import { PuffLoader } from 'react-spinners';
// @end components

// @import types
import * as DeviceTypes from 'types/device/device.types';
import * as UserTypes from 'types/user/user.types';
// @end types

// @import services
import SocketService from 'services/socket/socketService';
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './VehicleValidationModule.scss';
// @end styles

interface IProfileModuleProps {
  className?: string;
  style?: CSSProperties;
  id?: string;
  isAdmin?: boolean;
}

interface UserVehicle {
  user: UserTypes.User;
  vehicle: DeviceTypes.Device;
  reader: DeviceTypes.Device;
}

const VehicleValidationModule: React.FC<IProfileModuleProps> = (props) => {
  const [userVehicles, setUserVehicles] = React.useState<UserVehicle[]>([]);

  // @INFO Servicios
  const socketService = new SocketService();

  React.useEffect(() => {
    socketService.listenEvent('vehicleValidation', handleVehicleValidation);
    return () => {
      socketService.removeListenEvent(
        'vehicleValidation',
        handleVehicleValidation
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleVehicleValidation = (data: any) => {
    if (data.user && data.vehicle) {
      addUserVehicle({
        user: data.user,
        vehicle: data.vehicle,
        reader: data.reader,
      });
    }
  };

  const addUserVehicle = (userVehicle: UserVehicle) => {
    setUserVehicles((userVehicles) => {
      if (!existUserVehicle(userVehicles, userVehicle)) {
        return [...userVehicles, userVehicle];
      } else {
        return userVehicles;
      }
    });
    setTimeout(() => {
      removeUserVehicle(userVehicle);
    }, 20000);
  };
  const removeUserVehicle = (userVehicle: UserVehicle) => {
    setUserVehicles((userVehicles) =>
      userVehicles.filter(
        (uv) =>
          uv.user.id !== userVehicle.user.id ||
          uv.vehicle.id !== userVehicle.vehicle.id
      )
    );
  };

  const existUserVehicle = (userVehicles: UserVehicle[], item: UserVehicle) => {
    const exist = userVehicles.find(
      (uv) => uv.user.id === item.user.id && uv.vehicle.id === item.vehicle.id
    );
    return Boolean(exist);
  };

  return (
    <div
      className={`tag_assignment_module-layout ${
        props.className ? props.className : ''
      }`}
      style={props.style}
      id={props.id}
    >
      <TextComponent type="h2">Validación de vehículos</TextComponent>
      <div className="loading">
        <TextComponent>Escuchando...</TextComponent>
        <PuffLoader color="var(--primary)" size={100} />
      </div>
      <div className="cards-grid">
        {userVehicles.map(({ user, vehicle, reader }) => (
          <Card
            key={shortid.generate()}
            sx={{ borderRadius: '20px', maxWidth: 345 }}
          >
            <CardMedia
              component="img"
              height="170"
              image={user.userPicture}
              alt=""
            />
            <CardContent>
              <TextComponent type="h2">
                {user.firstName} {user.lastName}
              </TextComponent>
              <TextComponent type="subtitle">
                C.C. {user.docNumber}
              </TextComponent>
              <TextComponent>Placa vehículo:</TextComponent>
              <TextComponent type="h1" style={{ textAlign: 'center' }}>
                {vehicle.name}
              </TextComponent>
              <TextComponent type="h2" style={{ textAlign: 'center' }}>
                {reader.name}
              </TextComponent>
            </CardContent>
            <CardActions sx={{ justifyContent: 'center' }}>
              <IconButton
                onClick={() => removeUserVehicle({ user, vehicle, reader })}
              >
                <CloseRoundedIcon fontSize="large" />
              </IconButton>
            </CardActions>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default VehicleValidationModule;
