import store from 'redux/store';
import HTTPUtil from 'utils/http/http.util';

class BannerService {
  private storeObject = store;

  async createBanner(file: FormData) {
    const response = await HTTPUtil.send(
      {
        url: '/banner',
        method: 'POST',
        data: file,
        headers: {
          Authorization: this.storeObject.getState()?.auth?.token,
        },
      },
      {
        alertOnFailed: true,
      }
    );

    if (response?.status === 200) {
      return response;
    } else {
      return undefined;
    }
  }

  async updateBanner(id: string, file: FormData) {
    const response = await HTTPUtil.send(
      {
        url: `/banner/${id}`,
        method: 'PUT',
        data: file,
        headers: {
          Authorization: this.storeObject.getState()?.auth?.token,
        },
      },
      {
        alertOnFailed: true,
      }
    );

    if (response?.status === 200) {
      return response;
    } else {
      return undefined;
    }
  }

  async getAll() {
    const response = await HTTPUtil.send(
      {
        url: '/banner',
        method: 'GET',
        headers: {
          Authorization: this.storeObject.getState()?.auth?.token,
        },
      },
      {
        alertOnFailed: true,
      }
    );

    if (response?.status === 200) {
      return response;
    } else {
      return undefined;
    }
  }
  async deleteBanner(id: string) {
    const response = await HTTPUtil.send(
      {
        url: `/banner/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: this.storeObject.getState()?.auth?.token,
        },
      },
      {
        alertOnFailed: true,
      }
    );

    if (response?.status === 200) {
      return response;
    } else {
      return undefined;
    }
  }
}

export default BannerService;
