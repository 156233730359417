import React, { CSSProperties } from 'react';
import { Carousel } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import BannerService from 'services/banner/banner.service';
import * as BannerTypes from 'types/banners/banners.types';

import './BannerCarousel.scss';

interface IBannerProps {
  className?: string;
  classNameItem?: string;
  style?: CSSProperties;
  styleItem?: CSSProperties;
  id?: string;
  onlyAvailable?: boolean;
}

const BannerCarousel: React.FC<IBannerProps> = (props) => {
  const [banners, updateBanners] = React.useState<BannerTypes.Banner[]>([]);

  // @INFO Servicios
  const bannerService = new BannerService();

  React.useEffect(() => {
    getBanners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // @INFO Obtener la lista de banners
  const getBanners = async () => {
    const response = await bannerService.getAll();
    if (response) {
      updateBanners(response.data.banners);
    }
  };

  return banners.length ? (
    <Carousel
      className={`home_carousel-layout ${
        props.className ? props.className : ''
      }`}
      style={props.style}
      nextLabel={''}
      prevLabel={''}
      // activeIndex={0}
      onChange={() => {}}
      id={props.id}
      controls={false}
    >
      {banners?.length
        ? banners.map((_item) => (
            <Carousel.Item
              className={`item-carousel-home ${
                props.classNameItem ? props.classNameItem : ''
              }`}
              style={props.styleItem}
              key={_item.id}
            >
              <Link
                to={{
                  pathname: _item?.action?.route || '/',
                }}
                target="_blank"
              >
                <img src={_item.image} alt="prueba" />
              </Link>
            </Carousel.Item>
          ))
        : null}
    </Carousel>
  ) : null;
};

export default BannerCarousel;
