import store from 'redux/store';
import HTTPUtil from 'utils/http/http.util';

// @import types
import * as DeviceTypes from 'types/device/device.types';
// @end types

export class DeviceService {
  private storeObject = store;

  public createDevice = async (params: DeviceTypes.ParamsDeviceCreate) => {
    const response = await HTTPUtil.send(
      {
        url: '/device/',
        method: 'POST',
        data: params,
        headers: {
          Authorization: this.storeObject.getState()?.auth?.token,
        },
      },
      {
        alertOnFailed: true,
      }
    );

    if (response?.status === 200) {
      return response;
    } else {
      return undefined;
    }
  };

  public getDevicesByCategory = async (
    params: DeviceTypes.ParamsDevicesGetByCategory
  ): Promise<DeviceTypes.Device[]> => {
    const response = await HTTPUtil.send(
      {
        url: '/device/ByCategory',
        method: 'POST',
        data: {
          ...params,
        },
        headers: {
          Authorization: this.storeObject.getState()?.auth?.token,
        },
      },
      {
        alertOnFailed: true,
      }
    );

    if (response?.status === 200) {
      return response.data as DeviceTypes.Device[];
    } else {
      return [];
    }
  };
  public getAll = async (): Promise<DeviceTypes.Device[]> => {
    const response = await HTTPUtil.send(
      {
        url: '/device/all',
        method: 'GET',
        headers: {
          Authorization: this.storeObject.getState()?.auth?.token,
        },
      },
      {
        alertOnFailed: true,
      }
    );

    if (response?.status === 200) {
      return response.data as DeviceTypes.Device[];
    } else {
      return [];
    }
  };

  public getById = async (
    params: DeviceTypes.ParamsDeviceGetById
  ): Promise<DeviceTypes.Device | undefined> => {
    const response = await HTTPUtil.send(
      {
        url: `/device/ByIdDevice/${params.id}`,
        method: 'POST',
        data: {
          ...params,
        },
        headers: {
          Authorization: this.storeObject.getState()?.auth?.token,
        },
      },
      {
        alertOnFailed: true,
      }
    );

    if (response?.status === 200) {
      return response.data as DeviceTypes.Device;
    } else {
      return undefined;
    }
  };

  public updateDevice = async (
    params: DeviceTypes.ParamsDeviceUpdate
  ): Promise<DeviceTypes.Device | undefined> => {
    const response = await HTTPUtil.send(
      {
        url: `/device/${params.id}`,
        method: 'PUT',
        data: {
          ...params,
        },
        headers: {
          Authorization: this.storeObject.getState()?.auth?.token,
        },
      },
      {
        alertOnFailed: true,
      }
    );

    if (response?.status === 200) {
      return response.data as DeviceTypes.Device;
    } else {
      return undefined;
    }
  };
}

export default DeviceService;
