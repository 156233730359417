// @import dependencies
import AirIcon from '@mui/icons-material/AirRounded';
import AppRegistrationIcon from '@mui/icons-material/AppRegistrationRounded';
import CloudIcon from '@mui/icons-material/CloudRounded';
import DirectionsCarRoundedIcon from '@mui/icons-material/DirectionsCarRounded';
import DynamicFormIcon from '@mui/icons-material/DynamicFormRounded';
import ElectricBikeIcon from '@mui/icons-material/ElectricBikeRounded';
import ElectricMeterIcon from '@mui/icons-material/ElectricMeterRounded';
import HomeIcon from '@mui/icons-material/HomeRounded';
import HowToRegIcon from '@mui/icons-material/HowToRegRounded';
import LightbulbCircleIcon from '@mui/icons-material/LightbulbCircleRounded';
import LocalOfferRoundedIcon from '@mui/icons-material/LocalOfferRounded';
import SettingsInputAntennaRoundedIcon from '@mui/icons-material/SettingsInputAntennaRounded';
import SolarPowerRoundedIcon from '@mui/icons-material/SolarPowerRounded';
import StackedLineChartRoundedIcon from '@mui/icons-material/StackedLineChartRounded';
import ViewCarouselRoundedIcon from '@mui/icons-material/ViewCarouselRounded';
import WindPowerIcon from '@mui/icons-material/WindPowerRounded';
import { HOME_ROUTE } from '../../../config/globals';
// @end dependencies

interface Menu {
  title: string;
  icon?: any;
  path?: string;
  type: string;
  permissions?: string[];
  children?: Menu[];
}

const MENUITEMS: Menu[] = [
  {
    title: 'Dashboard',
    path: HOME_ROUTE,
    icon: HomeIcon,
    type: 'link',
  },
  {
    title: 'Sistemas',
    icon: CloudIcon,
    type: 'sub',
    permissions: ['systems_menu'],
    children: [
      {
        title: 'Estaciones',
        path: '/watch/weatherStation',
        icon: AirIcon,
        type: 'link',
      },
      {
        title: 'Medidores',
        icon: ElectricMeterIcon,
        path: '/watch/electricMeter-modbus',
        type: 'link',
      },
      {
        title: 'Medidores Udenar',
        icon: ElectricMeterIcon,
        path: '/watch/electricMeter-mqtt',
        type: 'link',
      },
      {
        title: 'Generadores',
        path: '/watch/electricGenerator-windTurbine',
        icon: WindPowerIcon,
        type: 'link',
      },
      {
        title: 'Generadores Fronius',
        path: '/watch/froniusPvGenerator',
        icon: SolarPowerRoundedIcon,
        type: 'link',
      },
      {
        title: 'Generadores Sun',
        path: '/watch/sunWtGenerator',
        icon: WindPowerIcon,
        type: 'link',
      },
      {
        title: 'Luminarias',
        icon: LightbulbCircleIcon,
        path: '/watch/luminary',
        type: 'link',
      },
      {
        title: 'Estadísticas Vehículos',
        icon: StackedLineChartRoundedIcon,
        path: '/watch/vehicleAccessControl',
        type: 'link',
      },
      {
        title: 'Validación Vehículos',
        icon: DirectionsCarRoundedIcon,
        path: '/vehicleAccess/validation',
        permissions: ['vehicle_validation_menu'],
        type: 'link',
      },
      {
        title: 'Lectores RFID',
        icon: SettingsInputAntennaRoundedIcon,
        path: '/watch/rfidReader',
        type: 'link',
      },
      {
        title: 'Bicicletas',
        icon: ElectricBikeIcon,
        path: '/watch/bicycle',
        type: 'link',
      },
    ],
  },
  {
    title: 'Administración',
    type: 'sub',
    icon: AppRegistrationIcon,
    permissions: ['management_menu'],
    children: [
      {
        title: 'Registrar Dispositivos',
        icon: DynamicFormIcon,
        path: '/devices/registration',
        permissions: ['devices_management_menu'],
        type: 'link',
      },
      {
        title: 'Administrar Usuarios',
        icon: HowToRegIcon,
        path: '/users/management',
        permissions: ['users_management_menu'],
        type: 'link',
      },
      {
        title: 'Administrar Banners',
        icon: ViewCarouselRoundedIcon,
        path: '/banners/management',
        permissions: ['banners_management_menu'],
        type: 'link',
      },
      {
        title: 'Asignación de Tags',
        icon: LocalOfferRoundedIcon,
        path: '/vehicleAccess/tag-assignment',
        permissions: ['tags_assignment_menu'],
        type: 'link',
      },
    ],
  },
];

export default MENUITEMS;
