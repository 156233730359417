import React from 'react'
import { ScaleLoader } from 'react-spinners';

import './LoadingPage.scss'
import LogoUdenarIcon from 'assets/icons/LogoUdenarIcon/LogoUdenarIcon';

export interface LoadingPageProps {
    history?: any
    path?: string
}
 
const LoadingPage: React.SFC<LoadingPageProps> = (props) => {

    return (
        <div className="loading_page-layout">
            <LogoUdenarIcon/>
            <ScaleLoader
                color='var(--primary)'
                margin={6}
                width={8}
                radius={6}
                height={35}
            />
        </div>
    );
}
 
export default LoadingPage;