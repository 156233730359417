import * as AppTypes from 'types/app/app.types';

const initialState: AppTypes.IAppState = {
  theme: 'light',
};

const appReducer = (
  state: AppTypes.IAppState = initialState,
  action: any
): AppTypes.IAppState => {
  switch (action.type) {
    case AppTypes.UPDATE_THEME:
      return {
        ...state,
        theme: action.payload,
      };
    default:
      return { ...state };
  }
};

export default appReducer;
