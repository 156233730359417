import store from 'redux/store';
import HTTPUtil from 'utils/http/http.util';

// @import types
import * as StatisticTypes from 'types/statistics/statistics.types';
// @end types

export class StatisticsService {
  private storeObject = store;

  public byDevice = async (
    id: string
  ): Promise<StatisticTypes.Statistic | undefined> => {
    const response = await HTTPUtil.send(
      {
        url: `/statistics/device/${id}`,
        method: 'GET',
        headers: {
          Authorization: this.storeObject.getState()?.auth?.token,
        },
      },
      {
        alertOnFailed: true,
      }
    );

    if (response?.status === 200) {
      return response.data as any;
    } else {
      return undefined;
    }
  };

  public byCategory = async (
    id: string
  ): Promise<StatisticTypes.Statistic | undefined> => {
    const response = await HTTPUtil.send(
      {
        url: `/statistics/category/${id}`,
        method: 'GET',
      },
      {
        alertOnFailed: true,
      }
    );

    if (response?.status === 200) {
      return response.data as any;
    } else {
      return undefined;
    }
  };

  allCategories = async (): Promise<StatisticTypes.Statistic[] | undefined> => {
    const response = await HTTPUtil.send(
      {
        url: '/statistics/all-categories',
        method: 'GET',
      },
      {
        alertOnFailed: true,
      }
    );

    if (response?.status === 200) {
      return response.data as any;
    } else {
      return undefined;
    }
  };
}
