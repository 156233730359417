// @import dependencies
import React, { CSSProperties } from 'react';
// @end dependencies

// @import components
import { toast } from 'react-toastify';
import BreadcrumbComponent from 'components/commons/Breadcrumb/BreadcrumbComponent';
import AppLayout from 'components/layouts/AppLayout/AppLayout';
import DeviceFormModule from '../../modules/DeviceFormModule/DeviceFormModule';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
// @end components

// @import types
import * as DeviceTypes from 'types/device/device.types';
// @end types

// @import services
import { DeviceService } from 'services/device/device.service';
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './DeviceRegistrationPage.scss';
import 'react-toastify/dist/ReactToastify.css';
// @end styles

interface IDeviceRegistrationPageProps {
  className?: string;
  style?: CSSProperties;
  id?: string;
}
const DeviceRegistrationPage: React.FC<IDeviceRegistrationPageProps> = (
  props
) => {
  // @INFO Servicios
  const deviceService = new DeviceService();

  const handleSubmit = async (data: DeviceTypes.ParamsDeviceCreate) => {
    const response = await deviceService.createDevice(data);
    if (response) {
      toast.success('Se registro el dispositivo');
    }
  };

  return (
    <AppLayout>
      <div
        className={`device_registration_page-layout ${
          props.className ? props.className : ''
        }`}
        style={props.style}
        id={props.id}
      >
        <BreadcrumbComponent
          Title="Registrar Dispositivos"
          Item_one="Dispositivos"
          Item_two="Registro"
          disabled_Title={false}
          disabled_Item_one={false}
          disabled_Item_two={true}
        />
        <Card sx={{ borderRadius: '10px' }} elevation={2}>
          <CardContent>
            <DeviceFormModule onSubmit={handleSubmit} />
          </CardContent>
        </Card>
      </div>
    </AppLayout>
  );
};

export default DeviceRegistrationPage;
