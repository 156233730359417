import * as UserTypes from 'types/user/user.types';

export const LOGIN = '[auth] LOGIN';
export const LOGIN_BY_TOKEN = '[auth] LOGIN_BY_TOKEN';
export const LOGOUT = '[AUTH] LOGOUT';

export interface AuthState {
  user?: UserTypes.User;
  token?: string;
  isLogged: boolean;
  hasData: boolean;
}

export interface LoginAction {
  user: UserTypes.User;
  token: string;
}

export interface SignUp {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  password: string;
  role: string;
  docType: string;
  docNumber: string;
  plates: { value: string }[];
}

export interface ChangePassword {
  email: string;
  currentPassword: string;
  newPassword: string;
}
export interface ChangeEmail {
  email: string;
  newEmail: string;
  password: string;
}
