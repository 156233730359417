// @import dependencies
import React, { useState } from 'react';
// @end dependencies

// @import components
import TextField, { StandardTextFieldProps } from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
// @end components

// @import types
// @end types

// @import services
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './TextFieldComponent.scss';
// @end styles

export interface TextFieldComponentProps extends StandardTextFieldProps {
  endAdornment?: any;
}

const TextFieldComponent = React.forwardRef<any, TextFieldComponentProps>(
  (props, ref) => {
    const [show, setShow] = useState(false);
    const passwordProps: TextFieldComponentProps =
      props.type === 'password'
        ? {
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShow(!show)}>
                    {show ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            },
          }
        : {};

    return (
      <TextField
        {...props}
        ref={ref}
        type={
          props.type === 'password' ? (show ? 'text' : 'password') : props.type
        }
        {...passwordProps}
      />
    );
  }
);

export default TextFieldComponent;
