import store from 'redux/store';
import HTTPUtil from 'utils/http/http.util';
import * as UserTypes from 'types/user/user.types';
import { AssignVehicle } from 'types/user/user.types';

class UserService {
  private storeObject = store;

  async update(params: Partial<UserTypes.User>) {
    const response = await HTTPUtil.send(
      {
        url: '/user/',
        method: 'PUT',
        data: params,
        headers: {
          Authorization: this.storeObject.getState()?.auth?.token,
        },
      },
      {
        alertOnFailed: true,
      }
    );

    if (response?.status === 200) {
      return response;
    } else {
      return undefined;
    }
  }

  async updatePicture(file: FormData) {
    const response = await HTTPUtil.send(
      {
        url: '/user/pic',
        method: 'POST',
        data: file,
        headers: {
          Authorization: this.storeObject.getState()?.auth?.token,
        },
      },
      {
        alertOnFailed: true,
      }
    );

    if (response?.status === 200) {
      return response;
    } else {
      return undefined;
    }
  }

  async assignVehicle(userId: string, params: AssignVehicle) {
    const response = await HTTPUtil.send(
      {
        url: `/user/${userId}/vehicle/`,
        method: 'POST',
        data: params,
        headers: {
          Authorization: this.storeObject.getState()?.auth?.token,
        },
      },
      {
        alertOnFailed: true,
      }
    );

    if (response?.status === 200) {
      return response;
    } else {
      return undefined;
    }
  }
  async removeVehicle(userId: string, vehicleId: string) {
    const response = await HTTPUtil.send(
      {
        url: `/user/${userId}/vehicle/${vehicleId}`,
        method: 'DELETE',
        headers: {
          Authorization: this.storeObject.getState()?.auth?.token,
        },
      },
      {
        alertOnFailed: true,
      }
    );

    if (response?.status === 200) {
      return response;
    } else {
      return undefined;
    }
  }

  async toggleActive(id: string) {
    const response = await HTTPUtil.send(
      {
        url: `/user/toggleActive/${id}`,
        method: 'PATCH',
        headers: {
          Authorization: this.storeObject.getState()?.auth?.token,
        },
      },
      {
        alertOnFailed: true,
      }
    );

    if (response?.status === 200) {
      return response;
    } else {
      return undefined;
    }
  }

  async getAll() {
    const response = await HTTPUtil.send(
      {
        url: '/user/all/',
        method: 'GET',
        headers: {
          Authorization: this.storeObject.getState()?.auth?.token,
        },
      },
      {
        alertOnFailed: true,
      }
    );

    if (response?.status === 200) {
      return response;
    } else {
      return undefined;
    }
  }
  async getById(userId: string) {
    const response = await HTTPUtil.send(
      {
        url: `/user/${userId}`,
        method: 'GET',
        headers: {
          Authorization: this.storeObject.getState()?.auth?.token,
        },
      },
      {
        alertOnFailed: true,
      }
    );

    if (response?.status === 200) {
      return response.data;
    } else {
      return undefined;
    }
  }

  async getIntegrationToken() {
    const response = await HTTPUtil.send(
      {
        url: '/user/integrationToken/',
        method: 'GET',
        headers: {
          Authorization: this.storeObject.getState()?.auth?.token,
        },
      },
      {
        alertOnFailed: false,
      }
    );

    if (response?.status === 200) {
      return response;
    } else {
      return undefined;
    }
  }
  async generateIntegrationToken(role: string) {
    const response = await HTTPUtil.send(
      {
        url: '/user/integrationToken/',
        method: 'POST',
        data: {
          role,
        },
        headers: {
          Authorization: this.storeObject.getState()?.auth?.token,
        },
      },
      {
        alertOnFailed: true,
      }
    );

    if (response?.status === 200) {
      return response;
    } else {
      return undefined;
    }
  }

  async getBySearchParam(search: string) {
    const response = await HTTPUtil.send(
      {
        url: '/user/bySearchParam/',
        method: 'POST',
        data: { search },
        headers: {
          Authorization: this.storeObject.getState()?.auth?.token,
        },
      },
      {
        alertOnFailed: true,
      }
    );

    if (response?.status === 200) {
      return response;
    } else {
      return undefined;
    }
  }
}

export default UserService;
