// @import dependencies
import React, { useEffect, CSSProperties } from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
// @end dependencies

// @import types
// @end types

// @import components
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import TextFieldComponent from 'components/commons/TextFieldComponent/TextFieldComponent';
import Box from '@mui/material/Box';
// @end components

// @import services
import DeviceService from 'services/device/device.service';
import { DeviceCategoryService } from 'services/deviceCategories/deviceCategory.service';
import UserService from 'services/user/user.service';
// @end services

// @import styles
import './UpdateUserVehicleModal.scss';
// @end styles

export interface UpdateUserVehicleModalProps {
  className?: string;
  style?: CSSProperties;
  deviceId?: string;
  userId?: string;
  show: boolean;
  onHide: () => void;
}

interface UpdateVehicleForm {
  plate: string;
  rfidTag: string;
}

const UpdateUserVehicleModal: React.FC<UpdateUserVehicleModalProps> = (
  props
) => {
  const { register, handleSubmit, watch, setValue } =
    useForm<UpdateVehicleForm>({
      defaultValues: {
        plate: '',
        rfidTag: '',
      },
    });

  const plate = watch('plate');
  const rfidTag = watch('rfidTag');

  // @INFO Servicios
  const deviceService = new DeviceService();
  const deviceCategoryService = new DeviceCategoryService();
  const userService = new UserService();

  useEffect(() => {
    (async () => {
      if (props.deviceId) {
        const response = await deviceService.getById({
          id: props.deviceId,
        });
        if (!response) {
          return;
        }
        setValue('plate', response?.name ?? '');
        setValue('rfidTag', response?.macAddress ?? '');
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.deviceId]);

  const handleUpdate = async (data: UpdateVehicleForm) => {
    if (props.deviceId) {
      const response = await deviceService.updateDevice({
        id: props.deviceId,
        name: data.plate,
        macAddress: data.rfidTag,
      });
      if (response) {
        toast.success('Actualizado correctamente');
        props.onHide();
      }
    } else {
      const category = await deviceCategoryService.getByName('rfidTag');
      if (category && props.userId) {
        const response = await userService.assignVehicle(props.userId, {
          plate: data.plate,
          rfidTag: data.rfidTag,
        });
        if (response) {
          toast.success('Vehículo agregado correctamente');
          props.onHide();
        }
      }
    }
  };

  return (
    <Dialog
      className={`update_user_vehicle_module-layout ${
        props.className ? props.className : ''
      }`}
      open={props.show}
      onClose={props.onHide}
      PaperProps={{
        style: { borderRadius: 10 },
      }}
    >
      <DialogTitle fontWeight="bold">
        {props.deviceId ? ' Modificar información' : 'Agregar nuevo'}
      </DialogTitle>
      <DialogContent>
        <Box component="form" noValidate onSubmit={handleSubmit(handleUpdate)}>
          <div className="content">
            <TextFieldComponent
              label="Placa"
              value={plate}
              {...register('plate')}
            />
            <TextFieldComponent
              label="Tag RFID"
              value={rfidTag}
              {...register('rfidTag')}
            />
            <ButtonComponent variant="primary" type="submit">
              {!props.deviceId ? 'Crear' : 'Actualizar'}
            </ButtonComponent>
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <ButtonComponent variant="primary" onClick={props.onHide}>
          Close
        </ButtonComponent>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateUserVehicleModal;
