import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers';

const store = createStore(
    rootReducer,
    compose( applyMiddleware(thunk),
        typeof window === 'object' &&
        // @ts-ignore
        typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined' ?
        // @ts-ignore
        window.__REDUX_DEVTOOLS_EXTENSION__() :
        (f: any) => f
    )
)

export default store;