import React, {CSSProperties, ChangeEvent} from 'react'
import TextComponent from 'components/commons/Text/TextComponent';
import './InputComponent.scss'

export interface InputComponentProps {
    className?: string
    style?: CSSProperties
    value?: string
    type?: 'text' | 'number' | 'date' | 'password' | 'time' | 'datetime-local' | 'datetime'
    name?: string
    ref?: any
    placeholder?: string
    disabled?: boolean
    required?: boolean
    children?: any
    valid?: boolean
    validText?: string
    invalid?: boolean
    invalidText?: string
    label?: string
    register?: any
    defaultValue?: string
    onChange?: (e: ChangeEvent<HTMLInputElement>)=>any
    onEnter?: (value?: string)=>any
}

interface IInputComponentSubComponents{
    Icon: React.FC<IIconInputProps>
}
 
const InputComponent: React.FC<InputComponentProps> & IInputComponentSubComponents = (props) => {

    const handleKeyUp = (e: any) => {
        if(props.onEnter){
            if(e.key === 'Enter' || e.keyCode === 13){
                props.onEnter(e.target.value)
            }
        }
    }

    return ( 
        <div>
            {props.label ?
                <TextComponent
                    type='label'
                >
                    {props.label}
                </TextComponent>
            :null}
            <div 
                className={`input-group input_component-layout ${props.className ? props.className : ''}`}
                style={props.style}
            >
                {props.children}
                <input 
                    type={props.type ? props.type : 'text'} 
                    className={`form-control ${props.valid ? 'is-valid' : ''} ${props.invalid ? 'is-invalid' : ''} ${props.children ? 'icon-spacing' : ''}`} 
                    placeholder={props.placeholder}
                    ref={props.ref}
                    onChange={props.onChange}
                    name={props.name}
                    value={props.value}
                    disabled={props.disabled}
                    required={props.required}
                    defaultValue={props.defaultValue}
                    onKeyUp={(e) => handleKeyUp(e)}
                    {...(props.register ? props.register(props.name ? props.name : '', {required: props.required ? 'Este campo es requerido' : undefined}): {})}
                />
            {props.valid && props.validText ? 
            <div className="valid-feedback">
                    {props.validText}
                </div>
                : null}
            {props.invalid && props.invalidText ? 
            <div className="invalid-feedback">
                    {props.invalidText}
                </div>
                : null}
            </div>
        </div> 
    );
}

interface IIconInputProps{
    children?: any
    className?: string
    style?: CSSProperties
}

const Icon: React.FC<IIconInputProps> = (props) => {
    return(
        <div className="container_icon-input">
            {props.children}
        </div>
    )
}

InputComponent.Icon = Icon
 
export default InputComponent;