import store from 'redux/store';
import { ChangeEmail, ChangePassword, SignUp } from 'types/auth/auth.types';
import HTTPUtil from 'utils/http/http.util';
import socketUtil from 'utils/socket/socket.util';

class AuthService {
  private storeObject = store;
  private tokenName: string = 'token';

  async signUp(params: SignUp) {
    const response = await HTTPUtil.send(
      {
        url: '/user/',
        method: 'POST',
        data: params,
        headers: {
          Authorization: this.storeObject.getState()?.auth?.token,
        },
      },
      {
        alertOnFailed: true,
      }
    );

    if (response?.status === 200) {
      return response;
    } else {
      return undefined;
    }
  }

  public login = async (email: string, password: string) => {
    const response = await HTTPUtil.send(
      {
        url: '/user/login',
        method: 'POST',
        data: {
          email,
          password,
        },
      },
      {
        alertOnFailed: true,
      }
    );

    if (response?.status === 200) {
      return response;
    } else {
      return undefined;
    }
  };

  public changePassword = async (data: ChangePassword) => {
    const response = await HTTPUtil.send(
      {
        url: '/user/changePassword',
        method: 'POST',
        data,
        headers: {
          Authorization: this.storeObject.getState()?.auth?.token,
        },
      },
      {
        alertOnFailed: true,
      }
    );

    if (response?.status === 200) {
      return response;
    } else {
      return undefined;
    }
  };

  public changeEmail = async (data: ChangeEmail) => {
    const response = await HTTPUtil.send(
      {
        url: '/user/changeEmail',
        method: 'POST',
        data,
        headers: {
          Authorization: this.storeObject.getState()?.auth?.token,
        },
      },
      {
        alertOnFailed: true,
      }
    );

    if (response?.status === 200) {
      return response;
    } else {
      return undefined;
    }
  };

  public loginByToken = async (token: string) => {
    const response = await HTTPUtil.send(
      {
        url: '/user/loginByToken',
        method: 'POST',
        data: {
          token,
        },
        headers: {
          Authorization: token,
        },
      },
      {
        alertOnFailed: true,
      }
    );

    if (response?.status === 200) {
      socketUtil.connectIO(response.data.token);
      return response;
    } else {
      return undefined;
    }
  };

  /**
   * @INFO Guardar token
   * @param token
   */
  public setToken = (token: string) => {
    localStorage.setItem(this.tokenName, token);
  };

  /**
   * @INFO Obtener token
   */
  public getToken = () => {
    let token: string | undefined = this.storeObject.getState().auth.token;
    if (!token) {
      // @ts-ignore
      token = localStorage.getItem(this.tokenName);
    }
    return token;
  };

  /**
   * @INFO Eliminar el token del local storage
   */
  public deleteToken = () => {
    localStorage.removeItem(this.tokenName);
  };

  /**
   * @INFO Verificar si hay un usuario con sesión activa en la app
   */
  public isLogin = () => {
    const isLogin = this.storeObject.getState().auth.isLogged;
    if (isLogin) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * @INFO Revisar si la data del usuario ya ha cargado
   */
  public hasData = () => {
    const hasData = this.storeObject.getState().auth.hasData;
    if (hasData) {
      return true;
    } else {
      return false;
    }
  };
}

export default AuthService;
