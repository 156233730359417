// @import dependencies
import AppLayout from 'components/layouts/AppLayout/AppLayout';
import Footer from 'components/modules/Footer/Footer';
import UserRegistrationModule from 'components/modules/UserRegistrationModule/UserRegistrationModule';
import React, { CSSProperties } from 'react';
// @end dependencies

// @import components
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
// @end components

// @import types
// @end types

// @import services
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './UserRegistrationScreen.scss';
// @end styles

interface IUserRegistrationScreenProps {
  className?: string;
  style?: CSSProperties;
  id?: string;
}

const UserRegistrationScreen: React.FC<IUserRegistrationScreenProps> = (
  props
) => {
  return (
    <AppLayout hideMenu hideProfile>
      <div
        className={`register_page-layout ${
          props.className ? props.className : ''
        }`}
        style={props.style}
        id={props.id}
      >
        <Card sx={{ borderRadius: '10px' }} elevation={2}>
          <CardContent>
            <UserRegistrationModule showLogo />
          </CardContent>
        </Card>
        <Footer />
      </div>
    </AppLayout>
  );
};

export default UserRegistrationScreen;
