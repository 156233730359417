// @import dependencies
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React, { CSSProperties } from 'react';
import moment from 'moment';
// @end dependencies

// @import components
import CircleIcon from '@mui/icons-material/Circle';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextComponent from 'components/commons/Text/TextComponent';
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import NotificationsOffRoundedIcon from '@mui/icons-material/NotificationsOffRounded';
// @end components

// @import types
import { Notification } from 'types/notification/notification.types';
// @end types

// @import services
import NotificationService from 'services/notificationService/notificationService';
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './NotificationsModule.scss';
// @end styles

interface INotificationsModuleProps {
  className?: string;
  style?: CSSProperties;
  id?: string;
}

const NotificationsModule: React.FC<INotificationsModuleProps> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [notifications, setNotifications] = React.useState<Notification[]>([]);
  const [unreadNumber, setUnreadNumber] = React.useState(0);
  const [limit, setLimit] = React.useState(5);

  // @INFO: Services
  const notificationService = new NotificationService();

  React.useEffect(() => {
    handleGetAllNotifications(5);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetAllNotifications = async (limitData: number) => {
    const response = await notificationService.getAll({
      limit: limitData,
    });
    if (response && response.data) {
      setNotifications(response.data.notifications);
      setUnreadNumber(response.data.unreadNumber);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewMore = () => {
    setLimit(limit + 5);
    handleGetAllNotifications(limit + 5);
  };

  const handleClickItem = async (id: string) => {
    const response = await notificationService.update(id, {
      read: true,
    });
    if (response) {
      handleGetAllNotifications(limit);
    }
  };

  return (
    <div
      className={`notifications_module-layout ${
        props.className ? props.className : ''
      }`}
      style={props.style}
      id={props.id}
    >
      <Tooltip title="Notificaciones">
        <div className="icon_header-layout">
          {unreadNumber > 0 && (
            <div className="number-icon-header"> {unreadNumber}</div>
          )}
          <IconButton
            sx={{ height: '40px' }}
            onClick={handleClick}
            size="small"
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <NotificationsRoundedIcon />
          </IconButton>
        </div>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        disableScrollLock={false}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'auto',
            borderRadius: '10px',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiMenuItem-root': {
              whiteSpace: 'normal',
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <TextComponent type="subtitle" style={{ margin: '10px 20px' }}>
          Notificaciones
        </TextComponent>

        {notifications.length > 0 ? (
          notifications.map((notification) => (
            <MenuItem
              key={notification.id}
              sx={{ maxWidth: '500px', gap: '10px' }}
              onClick={() => handleClickItem(notification.id)}
            >
              <CircleIcon
                sx={{ fontSize: '10px' }}
                color={notification.read ? 'disabled' : 'primary'}
              />
              <div className="notification-item">
                <div className="title">
                  <TextComponent style={{ fontWeight: 'bolder' }}>
                    {notification.title}
                  </TextComponent>
                  <TextComponent style={{ fontWeight: 'bolder' }}>
                    {moment(notification.date).format('DD/MM/YYYY')}
                  </TextComponent>
                </div>
                <TextComponent>
                  {notification.description} <br />
                  {moment(notification.date).format('hh:mm a')}
                </TextComponent>
              </div>
            </MenuItem>
          ))
        ) : (
          <div>
            <NotificationsOffRoundedIcon
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
              }}
            />
            <MenuItem sx={{ width: '300px', gap: '10px' }}>
              <TextComponent style={{ textAlign: 'center', width: '100%' }}>
                No hay notificaciones
              </TextComponent>
            </MenuItem>
          </div>
        )}

        {notifications.length > 0 && (
          <ButtonComponent
            style={{ margin: '0 auto', display: 'flex' }}
            onClick={handleViewMore}
          >
            Ver más
          </ButtonComponent>
        )}
      </Menu>
    </div>
  );
};

export default NotificationsModule;
