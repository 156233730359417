import { Device } from 'types/device/device.types';
export const UPDATE_USER = '[auth] UPDATE_USER';
export interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  idClient: string;
  role: string;
  permissionRole?: PermissionRole;
  token: string;
  userPicture: string;
  username: string;
  docNumber?: string;
  docType?: string;
  isActive: boolean;
  configuration?: UserConfiguration;
}

export interface UserConfiguration {
  associatedVehicles?: Device[];
  receiveNotifications?: {
    email?: boolean;
  };
}
export interface UpdateUserAction {
  user: User;
}

export interface PermissionRole {
  role: string;
  appPermissions: {
    name: string;
    description: string;
  }[];
  routes: string[];
}

export interface AssignVehicle {
  plate: string;
  rfidTag?: string;
}
