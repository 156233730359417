// @import dependencies
import React, { CSSProperties } from 'react';
// @end dependencies

// @import components
import TextComponent from 'components/commons/Text/TextComponent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import DirectionsCarRoundedIcon from '@mui/icons-material/DirectionsCarRounded';
// @end components

// @import types
import { StoreApp } from 'redux/reducers/index';
// @end types

// @import services
// @end services

// @import hooks
import { useSelector } from 'react-redux';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './MyVehiclesModule.scss';
// @end styles
interface IMyVehiclesModule {
  className?: string;
  style?: CSSProperties;
  id?: string;
  isAdmin?: boolean;
}

const MyVehiclesModule: React.FC<IMyVehiclesModule> = (props) => {
  const user = useSelector((store: StoreApp) => store.auth?.user);

  return (
    <div
      className={`my_vehicles-layout ${props.className ? props.className : ''}`}
      style={props.style}
      id={props.id}
    >
      <TextComponent type="h3" style={{ textAlign: 'center' }}>
        Mis vehículos
      </TextComponent>
      <TextComponent style={{ textAlign: 'center' }}>
        Aquí puedes visualizar tus vehículos asociados.
      </TextComponent>
      <List>
        {user?.configuration?.associatedVehicles?.map((vehicle) => (
          <ListItem disablePadding key={vehicle.id}>
            <ListItemIcon>
              <DirectionsCarRoundedIcon />
            </ListItemIcon>
            <ListItemText primary={vehicle.name} />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default MyVehiclesModule;
