// @import dependencies
import React, { CSSProperties, useState, useEffect } from 'react';
// @end dependencies

// @import components
import TextComponent from 'components/commons/Text/TextComponent';
import AppLayout from 'components/layouts/AppLayout/AppLayout';
import LoadingComponent from 'components/commons/LoadingComponent/LoadingComponent';
// @end components

// @import types
import * as DeviceTypes from 'types/device/device.types';
import * as DeviceCategoryTypes from 'types/deviceCategory/deviceCategory.types';
// @end types

// @import services
import DeviceService from 'services/device/device.service';
import { DevicesListFunctions } from './DevicesListFunctions';
// @end services

// @import hooks
import { useHistory, useParams } from 'react-router-dom';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './DevicesListPage.scss';
// @end styles

interface IDevicesListPageProps {
  className?: string;
  style?: CSSProperties;
  id?: string;
}

const DevicesListPage: React.FC<IDevicesListPageProps> = (props) => {
  const [devices, updateDevices] = useState<DeviceTypes.Device[]>([]);
  const [loading, updateLoading] = useState<boolean>(false);
  const history = useHistory();
  const params = useParams<{
    category: DeviceCategoryTypes.TypesDeviceCategories;
  }>();

  // @INFO Servicios
  const deviceService = new DeviceService();
  const devicesListPageFunctions = new DevicesListFunctions();

  useEffect(() => {
    getDevices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.category]);

  useEffect(() => {
    if (
      devices?.length === 1 &&
      (params?.category === 'bicycle' ||
        params?.category === 'vehicleAccessControl')
    ) {
      history.push(`/device/${devices[0].id}`);
    }
  }, [devices, devices?.length, history, params?.category]);

  const getDevices = async () => {
    updateLoading(true);
    const response = await deviceService.getDevicesByCategory({
      category: params.category,
    });
    updateDevices(response);
    updateLoading(false);
  };

  return (
    <AppLayout>
      <div
        className={`devices_list_page-layout ${
          props.className ? props.className : ''
        }`}
        style={props.style}
        id={props.id}
      >
        <TextComponent type="h3">
          {devicesListPageFunctions.getTitleFromCategory(params.category)}
        </TextComponent>
        {!loading ? (
          <div className="container-cards">
            {devices?.length
              ? devices.map((d, idx) =>
                  devicesListPageFunctions.getCardFromCategory(
                    params.category,
                    d,
                    idx
                  )
                )
              : null}
          </div>
        ) : (
          <LoadingComponent />
        )}
      </div>
    </AppLayout>
  );
};

export default DevicesListPage;
