// @import dependencies
import React, { CSSProperties } from 'react';
import { useParams, NavLink } from 'react-router-dom';
// @end dependencies

// @import components
import ImgProfileModule from 'components/modules/ImgProfileModule/ImgProfileModule';
import TextComponent from 'components/commons/Text/TextComponent';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ChangePasswordModule from 'components/modules/ProfileModule/ChangePasswordModule/ChangePasswordModule';
import ChangeEmailModule from 'components/modules/ProfileModule/ChangeEmailModule/ChangeEmailModule';
import Box from '@mui/material/Box';
import UserRegistrationModule from 'components/modules/UserRegistrationModule/UserRegistrationModule';
import MyVehiclesModule from './MyVehiclesModule/MyVehiclesModule';
import DeveloperSettingsModule from './DeveloperSettingsModule/DeveloperSettingsModule';
import NotificationsModule from './NotificationsModule/NotificationsModule';
// @end components

// @import types
import { StoreApp } from 'redux/reducers/index';
import * as AuthActions from 'redux/reducers/auth/auth.actions';
// @end types

// @import services
import UserService from 'services/user/user.service';
import AuthService from 'services/auth/authService';
import PermissionsService from 'services/permissions/permissionsService';
// @end services

// @import hooks
import { useSelector, useDispatch } from 'react-redux';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './ProfileModule.scss';
// @end styles
interface IProfileModuleProps {
  className?: string;
  style?: CSSProperties;
  id?: string;
  isAdmin?: boolean;
}

const ProfileModule: React.FC<IProfileModuleProps> = (props) => {
  const authenticated = useSelector((store: StoreApp) => store.auth?.isLogged);
  const user = useSelector((store: StoreApp) => store.auth?.user);
  const params = useParams<{ child: string }>();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // @INFO: Services
  const userService = new UserService();
  const authService = new AuthService();
  const permissionsService = new PermissionsService();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePicImage = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    const response = await userService.updatePicture(formData);
    if (response) {
      const token = authService.getToken();
      if (token) {
        dispatch(AuthActions.loginByTokenAction({ token: String(token) }));
      }
    }
  };

  return (
    <div
      className={`profile_module-layout ${
        props.className ? props.className : ''
      }`}
      style={props.style}
      id={props.id}
    >
      <Box
        sx={{
          width: { xs: '100%', sm: '90%' },
        }}
      >
        <div className="header-profile">
          <ImgProfileModule
            onLoadFile={handlePicImage}
            src={
              authenticated && user?.userPicture?.length ? user.userPicture : ''
            }
          />
          <div>
            <TextComponent>
              {user?.firstName} {user?.lastName}
            </TextComponent>
            <TextComponent>{user?.email}</TextComponent>
          </div>
        </div>
        <Box
          sx={{
            display: { xs: 'block', sm: 'none' },
          }}
        >
          <Button
            id="basic-button"
            startIcon={<ArrowDropDownIcon />}
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            Ajustes
          </Button>
          <Menu
            className="pop-menu"
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <NavLink
              activeClassName="link-activate"
              className="link"
              to="/users/profile/general"
            >
              <MenuItem className="menu-item">
                <ListItemText primary="General" />
              </MenuItem>
            </NavLink>
            <NavLink
              activeClassName="link-activate"
              className="link"
              to="/users/profile/password"
            >
              <MenuItem className="menu-item">
                <ListItemText primary="Cambiar contraseña" />
              </MenuItem>
            </NavLink>
            <NavLink
              activeClassName="link-activate"
              className="link"
              to="/users/profile/email"
            >
              <MenuItem className="menu-item">
                <ListItemText primary="Cambiar correo" />
              </MenuItem>
            </NavLink>
            <NavLink
              activeClassName="link-activate"
              className="link"
              to="/users/profile/vehicles"
            >
              <MenuItem className="menu-item">
                <ListItemText primary="Mis vehículos" />
              </MenuItem>
            </NavLink>
            {permissionsService.check('notifications_settings_profile') && (
              <NavLink
                activeClassName="link-activate"
                className="link"
                to="/users/profile/notifications"
              >
                <MenuItem className="menu-item">
                  <ListItemText primary="Notificaciones" />
                </MenuItem>
              </NavLink>
            )}
            {permissionsService.check('developer_settings_profile') && (
              <NavLink
                activeClassName="link-activate"
                className="link"
                to="/users/profile/developer"
              >
                <MenuItem className="menu-item">
                  <ListItemText primary="Opciones desarrollador" />
                </MenuItem>
              </NavLink>
            )}
          </Menu>
        </Box>
        <div className="profile-menu">
          <Box
            className="profile-menu-items"
            sx={{
              display: { xs: 'none', sm: 'block' },
            }}
          >
            <List>
              <NavLink
                activeClassName="link-activate"
                className="link"
                to="/users/profile/general"
              >
                <ListItemButton>
                  <ListItemText primary="General" />
                </ListItemButton>
              </NavLink>
              <NavLink
                activeClassName="link-activate"
                className="link"
                to="/users/profile/password"
              >
                <ListItemButton>
                  <ListItemText primary="Cambiar contraseña" />
                </ListItemButton>
              </NavLink>
              <NavLink
                activeClassName="link-activate"
                className="link"
                to="/users/profile/email"
              >
                <ListItemButton>
                  <ListItemText primary="Cambiar correo" />
                </ListItemButton>
              </NavLink>
              <NavLink
                activeClassName="link-activate"
                className="link"
                to="/users/profile/vehicles"
              >
                <ListItemButton>
                  <ListItemText primary="Mis vehículos" />
                </ListItemButton>
              </NavLink>
              {permissionsService.check('notifications_settings_profile') && (
                <NavLink
                  activeClassName="link-activate"
                  className="link"
                  to="/users/profile/notifications"
                >
                  <ListItemButton>
                    <ListItemText primary="Notificaciones" />
                  </ListItemButton>
                </NavLink>
              )}
              {permissionsService.check('developer_settings_profile') && (
                <NavLink
                  activeClassName="link-activate"
                  className="link"
                  to="/users/profile/developer"
                >
                  <ListItemButton>
                    <ListItemText primary="Opciones desarrollador" />
                  </ListItemButton>
                </NavLink>
              )}
            </List>
          </Box>
          <Container>
            <Card sx={{ borderRadius: '10px' }} elevation={2}>
              <CardContent>
                {params.child && params.child === 'general' && (
                  <UserRegistrationModule
                    isAdmin={user?.role === 'SuperAdmin'}
                    isUpdate
                    isMine
                    initialValues={user}
                  />
                )}

                {params.child && params.child === 'password' && (
                  <ChangePasswordModule />
                )}
                {params.child && params.child === 'email' && (
                  <ChangeEmailModule />
                )}
                {params.child && params.child === 'vehicles' && (
                  <MyVehiclesModule />
                )}
                {params.child &&
                  params.child === 'developer' &&
                  permissionsService.check('developer_settings_profile') && (
                    <DeveloperSettingsModule />
                  )}
                {params.child &&
                  params.child === 'notifications' &&
                  permissionsService.check(
                    'notifications_settings_profile'
                  ) && <NotificationsModule />}
              </CardContent>
            </Card>
          </Container>
        </div>
      </Box>
    </div>
  );
};

export default ProfileModule;
