// @import dependencies
import React, { CSSProperties, useState, useEffect } from 'react';
// @end dependencies

// @import components
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import TextFieldComponent from 'components/commons/TextFieldComponent/TextFieldComponent';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import TextComponent from 'components/commons/Text/TextComponent';
// @end components

// @import types
import * as DeviceTypes from 'types/device/device.types';
// @end types

// @import services
import { DeviceCategoryService } from 'services/deviceCategories/deviceCategory.service';
// @end services

// @import hooks
import { useForm } from 'react-hook-form';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './DeviceFormModule.scss';
// @end styles

interface IDeviceRegistrationPageProps {
  className?: string;
  style?: CSSProperties;
  id?: string;
  isUpdate?: boolean;
  onSubmit: (data: DeviceTypes.ParamsDeviceCreate) => void;
  initialValues?: Partial<DeviceTypes.ParamsDeviceCreate>;
}

interface DeviceForm extends Omit<DeviceTypes.Device, 'category'> {
  category: string;
}

const DeviceFormModule: React.FC<IDeviceRegistrationPageProps> = (props) => {
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm<DeviceForm>({
    defaultValues: {
      macAddress: props.initialValues?.macAddress || '',
      category: props.initialValues?.category || '',
      name: props.initialValues?.name || '',
      description: props.initialValues?.description || '',
      location: {
        latitude: props.initialValues?.location?.latitude || undefined,
        longitude: props.initialValues?.location?.longitude || undefined,
      },
      configuration: {
        sampleTime: props.initialValues?.configuration?.sampleTime || undefined,
      },
    },
  });

  const [categories, setCategories] = useState([]);

  // @INFO Servicios
  const deviceCategoryService = new DeviceCategoryService();

  useEffect(() => {
    (async () => {
      const respuesta = await deviceCategoryService.getAll();
      setCategories(respuesta.data);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data: DeviceForm) => {
    props.onSubmit(data);
    if (!props.isUpdate) {
      reset();
    }
  };

  return (
    <div>
      <TextComponent className="text-center" type="h3">
        Ingrese los siguientes datos
      </TextComponent>
      <Box
        component="form"
        sx={{ mt: '20px' }}
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="form-row">
          <div className="col-md-6">
            <div className="form-group">
              <TextFieldComponent
                type="text"
                fullWidth
                label="ID"
                {...register('macAddress', {
                  required: true,
                })}
                error={errors.macAddress ? true : false}
                helperText={errors.macAddress && 'Este campo es requerido'}
                onChange={(e) =>
                  setValue('macAddress', e.target.value, {
                    shouldValidate: true,
                  })
                }
                required
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <FormControl fullWidth>
                <InputLabel id="category-label" required>
                  Categoría de dispositivo
                </InputLabel>
                {categories.length > 0 && (
                  <Select
                    value={getValues().category}
                    label="Categoría de dispositivo"
                    {...register('category', {
                      required: true,
                    })}
                    error={errors.category ? true : false}
                    onChange={(e) =>
                      setValue('category', e.target.value, {
                        shouldValidate: true,
                      })
                    }
                  >
                    {categories.map((category: any) => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.description}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <TextFieldComponent
                label="Nombre"
                type="text"
                fullWidth
                {...register('name', {
                  required: true,
                })}
                error={errors.name ? true : false}
                helperText={errors.name && 'Este campo es requerido'}
                onChange={(e) =>
                  setValue('name', e.target.value, {
                    shouldValidate: true,
                  })
                }
                required
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <TextFieldComponent
                label="Descripción"
                multiline
                maxRows={2}
                fullWidth
                {...register('description')}
                onChange={(e) =>
                  setValue('description', e.target.value, {
                    shouldValidate: true,
                  })
                }
              ></TextFieldComponent>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <TextFieldComponent
                label="Longitud"
                type="number"
                fullWidth
                {...register('location.longitude', {
                  valueAsNumber: true,
                })}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <TextFieldComponent
                label="Latitud"
                type="number"
                fullWidth
                {...register('location.latitude', {
                  valueAsNumber: true,
                })}
              />
            </div>
          </div>
        </div>
        <Divider />
        <TextComponent className="mt-3 mb-3" type="h3">
          Configuraciones:
        </TextComponent>
        <div className="col-md-6">
          <div className="form-group">
            <TextFieldComponent
              type="number"
              label="Tiempo de muestreo (s)"
              {...register('configuration.sampleTime', {
                valueAsNumber: true,
              })}
              error={errors.configuration?.sampleTime ? true : false}
              helperText={
                errors.configuration?.sampleTime && 'Este campo es requerido'
              }
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-sm-4">
            <ButtonComponent type="submit" disabled={!isValid}>
              {props.isUpdate ? 'Actualizar' : 'Registrar'}
            </ButtonComponent>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default DeviceFormModule;
