// @import dependencies
import React, { CSSProperties } from 'react';
import { toast } from 'react-toastify';
// @end dependencies

// @import components
import TextComponent from 'components/commons/Text/TextComponent';
import TextFieldComponent from 'components/commons/TextFieldComponent/TextFieldComponent';
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import Box from '@mui/material/Box';
// @end components

// @import types
import { StoreApp } from 'redux/reducers/index';
// @end types

// @import services
import AuthService from 'services/auth/authService';
// @end services

// @import hooks
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
// @end hooks

// @import actions
import * as AuthActions from 'redux/reducers/auth/auth.actions';
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './ChangePasswordModule.scss';
// @end styles
interface IChangePasswordModule {
  className?: string;
  style?: CSSProperties;
  id?: string;
}

interface ChangePasswordForm {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
}

const ChangePasswordModule: React.FC<IChangePasswordModule> = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isValid },
  } = useForm<ChangePasswordForm>({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
    },
  });
  const newPassword = watch('newPassword', '');
  const user = useSelector((store: StoreApp) => store.auth?.user);
  const dispatch = useDispatch();

  // @INFO: Services
  const authService = new AuthService();

  const onSubmit = async (data: ChangePasswordForm) => {
    const response = await authService.changePassword({
      ...data,
      email: user?.email || '',
    });
    if (response?.data) {
      toast.success(
        'Contraseña actualizada correctamente, debes volver a iniciar sesión'
      );
      dispatch(AuthActions.logoutAction());
    }
  };

  return (
    <div
      className={`change_password-layout ${
        props.className ? props.className : ''
      }`}
      style={props.style}
      id={props.id}
    >
      <TextComponent type="h3" style={{ textAlign: 'center' }}>
        Cambiar contraseña
      </TextComponent>
      <TextComponent style={{ textAlign: 'center' }}>
        Escoge una contraseña única para mantener segura tu cuenta
      </TextComponent>
      <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
        <div className="fields">
          <TextFieldComponent
            label="Contraseña actual"
            type="password"
            fullWidth
            {...register('currentPassword', {
              required: true,
            })}
            error={errors.currentPassword ? true : false}
            helperText={errors.currentPassword && 'Contraseña actual requerida'}
            onChange={(e) =>
              setValue('currentPassword', e.target.value, {
                shouldValidate: true,
              })
            }
          />
          <TextFieldComponent
            label="Nueva contraseña"
            type="password"
            fullWidth
            {...register('newPassword', {
              required: true,
              pattern:
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            })}
            error={errors.newPassword ? true : false}
            helperText={
              errors.newPassword &&
              'La contraseña debe contener al menos 8 caracteres, una mayúscula, un número y un símbolo.'
            }
            onChange={(e) =>
              setValue('newPassword', e.target.value, {
                shouldValidate: true,
              })
            }
          />
          <TextFieldComponent
            label="Confirmar nueva contraseña"
            type="password"
            fullWidth
            {...register('newPasswordConfirmation', {
              required: true,
              validate: (value) =>
                value === newPassword || 'La contraseñas no coinciden',
            })}
            error={errors.newPasswordConfirmation ? true : false}
            helperText={
              errors.newPasswordConfirmation && 'Las contraseñas no coinciden'
            }
            onChange={(e) =>
              setValue('newPasswordConfirmation', e.target.value, {
                shouldValidate: true,
              })
            }
          />
          <ButtonComponent type="submit" disabled={!isValid}>
            Cambiar contraseña
          </ButtonComponent>
        </div>
      </Box>
    </div>
  );
};

export default ChangePasswordModule;
