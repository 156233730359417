// @import dependencies
import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
// @end dependencies

// @import components
import UserMenuModule from '../UserMenuModule/UserMenuModule';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import NetworkButtons from 'components/commons/NetworkButtons/NetworkButtons';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import NotificationsModule from 'components/modules/NotificationsModule/NotificationsModule';
// @end components

// @import types
import { StoreApp } from 'redux/reducers';
// @end types

// @import services
// @end services

// @import hooks
import { useDispatch, useSelector } from 'react-redux';
import { updateThemeAppAction } from 'redux/reducers/app/app.actions';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
import logo_scada from 'assets/icons/logo_scada.svg';
// @end assets

// @import styles
import './HeaderModule.scss';
import { HOME_ROUTE } from '../../../config/globals';
// @end styles

interface IHeaderModuleProps {
  className?: string;
  style?: CSSProperties;
  id?: string;
  hideMenu?: boolean;
  hideProfile?: boolean;
  redirectPathLogo?: string;
  onClickOpen?: () => void;
}

const HeaderModule: React.FC<IHeaderModuleProps> = (props) => {
  const mode = useSelector((store: StoreApp) => store.app?.theme);
  const dispatch = useDispatch();

  const openCloseSidebar = () => {
    if (props.onClickOpen) {
      props.onClickOpen();
    }
  };

  const handleChangeTheme = () => {
    if (mode === 'light') {
      dispatch(updateThemeAppAction('dark'));
    } else if (mode === 'dark') {
      dispatch(updateThemeAppAction('light'));
    }
  };

  return (
    <div
      className={`header_module-layout ${
        props.className ? props.className : ''
      }`}
      style={props.style}
      id={props.id}
    >
      <Box className="box" sx={{ bgcolor: 'background.paper' }}>
        <div className="start-header">
          {!props.hideMenu && (
            <Tooltip title="Menú">
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => openCloseSidebar()}
              >
                <MenuIcon />
              </IconButton>
            </Tooltip>
          )}
          <Link to={props.redirectPathLogo ?? HOME_ROUTE}>
            <img src={logo_scada} className="img-header-scada-icon" alt="" />
          </Link>
        </div>
        <NetworkButtons
          className="network-buttons"
          style={{
            margin: '0px auto',
          }}
        />
        <div className="end-header">
          {!props.hideProfile && <NotificationsModule />}
          <Tooltip title="Cambiar tema">
            <IconButton sx={{ height: '40px' }} onClick={handleChangeTheme}>
              {mode === 'light' ? (
                <DarkModeRoundedIcon />
              ) : (
                <LightModeRoundedIcon color="primary" />
              )}
            </IconButton>
          </Tooltip>
          {!props.hideProfile && (
            <div className="profile-header-icon">
              <UserMenuModule />
            </div>
          )}
        </div>
      </Box>
    </div>
  );
};

export default HeaderModule;
