import React, { CSSProperties } from 'react';
import './ButtonComponent.scss';
import { Button } from '@mui/material';

export interface ButtonComponentProps {
  children?: any;
  className?: string;
  style?: CSSProperties;
  disabled?: boolean;
  variant?: 'primary' | 'secondary';
  loading?: boolean;
  type?: 'submit' | 'button' | 'reset';
  onClick?: () => void;
}

interface IIconButtonComponentProps {
  children?: any;
}

interface ISubComponentsButton {
  Icon: React.FC<IIconButtonComponentProps>;
}

const ButtonComponent: React.FC<ButtonComponentProps> &
  ISubComponentsButton = (props) => {
  return (
    <Button
    className={`btn-c btn-custom ${props.className ? props.className : ''}`}
      onClick={props.onClick}
      style={props.style}
      color={props.variant}
      variant="contained"
      disabled={props.disabled || props.loading}
      type={props.type}
    >
      {props.loading ? (
        <div
          className="spinner-border spinner-loading-button mr-2"
          role="status"
        > </div>
      ) : (
        <div></div>
      )}
      {props.children}
    </Button>
  );
};

const Icon: React.FC<IIconButtonComponentProps> = (props) => {
  return <div className="container-icon-button">{props.children}</div>;
};

ButtonComponent.Icon = Icon;

export default ButtonComponent;
