// @import dependencies
import React, { CSSProperties, useState, useEffect } from 'react';
// @end dependencies

// @import components
import { Circle } from 'react-leaflet';
// @end components

// @import types
import * as DeviceTypes from 'types/device/device.types';
// @end types

// @import services
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './StatusMap.scss';
// @end styles

interface IStatusMapProps {
  status: DeviceTypes.TypeStatusDevice;
  device: DeviceTypes.Device;
  className?: string;
  style?: CSSProperties;
  id?: string;
  toogleTime?: number;
}

const StatusMap: React.FC<IStatusMapProps> = (props) => {
  const [toogleColor, updateToogleColor] = useState<boolean>(false);
  const [change, updateChange] = useState<any>({});

  useEffect(() => {
    const interval = setInterval(
      () => {
        updateChange({});
      },
      props.toogleTime !== undefined ? props.toogleTime : 500
    );

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateToogleColor(!toogleColor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [change]);

  const getIcon = () => {
    if (props.status === 'online') {
      return (
        <Circle
          key={`${props.device.id}-circle`}
          center={[
            props.device.location.latitude,
            props.device.location.longitude,
          ]}
          color="green"
          fillOpacity={1}
          radius={2.5}
        />
      );
    } else if (props.status === 'offline') {
      if (toogleColor) {
        return (
          <Circle
            key={`${props.device.id}-circle`}
            center={[
              props.device.location.latitude,
              props.device.location.longitude,
            ]}
            color="red"
            fillOpacity={1}
            radius={2.5}
          />
        );
      } else {
        return (
          <Circle
            key={`${props.device.id}-circle`}
            center={[
              props.device.location.latitude,
              props.device.location.longitude,
            ]}
            color="gray"
            fillOpacity={1}
            radius={2.5}
          />
        );
      }
    } else {
      return (
        <Circle
          key={`${props.device.id}-circle`}
          center={[
            props.device.location.latitude,
            props.device.location.longitude,
          ]}
          color="gray"
          fillOpacity={1}
          radius={2.5}
        />
      );
    }
  };

  return getIcon();
};

export default StatusMap;
