// @import dependencies
import React, { CSSProperties } from 'react';
// @end dependencies

// @import components
import SearchComponent from 'components/commons/SearchComponent/SearchComponent';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import TextComponent from 'components/commons/Text/TextComponent';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import UserVehicleModule from './UserVehiclesModule/UserVehicleModule';
// @end components

// @import types
import * as UserTypes from 'types/user/user.types';
// @end types

// @import services
import UserService from 'services/user/user.service';
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './TagAssignmentModule.scss';
// @end styles
interface IProfileModuleProps {
  className?: string;
  style?: CSSProperties;
  id?: string;
  isAdmin?: boolean;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TagAssignmentModule: React.FC<IProfileModuleProps> = (props) => {
  const [users, setUsers] = React.useState<UserTypes.User[]>([]);
  const [user, setUser] = React.useState<UserTypes.User>();
  const [open, setOpen] = React.useState(false);

  // @INFO: Services
  const userService = new UserService();

  const handleClickOpen = (user: UserTypes.User) => {
    setUser(user);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getBySearchParam = async (search: string) => {
    const response = await userService.getBySearchParam(search);
    if (response) {
      setUsers(response.data);
    }
  };

  return (
    <div
      className={`tag_assignment_module-layout ${
        props.className ? props.className : ''
      }`}
      style={props.style}
      id={props.id}
    >
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <UserVehicleModule userId={user?.id} onClose={handleClose} />
      </Dialog>
      <TextComponent type="h2">Asignación de tags RIFD</TextComponent>
      <div className="content-screen">
        <SearchComponent label="Buscar usuario" onChange={getBySearchParam} />
        {users.length > 0 && (
          <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          >
            {users.map((user) => (
              <div key={user.id}>
                <ListItem>
                  <ListItemButton onClick={() => handleClickOpen(user)}>
                    <ListItemAvatar>
                      <Avatar src={user.userPicture} alt=""></Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${user.firstName} ${user.lastName}`}
                      secondary={user.email}
                    />
                    <ArrowForwardIosRoundedIcon />
                  </ListItemButton>
                </ListItem>
                <Divider variant="inset" component="li" />
              </div>
            ))}
          </List>
        )}
      </div>
    </div>
  );
};

export default TagAssignmentModule;
