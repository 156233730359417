// @import dependencies
import Footer from 'components/modules/Footer/Footer';
import HeaderModule from 'components/modules/HeaderModule/HeaderModule';
import LoginModule from 'components/modules/LoginModule/LoginModule';
import React, { CSSProperties } from 'react';
// @end dependencies

// @import components
// @end components

// @import types
// @end types

// @import services
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './LoginPage.scss';
// @end styles

interface ILoginPageProps {
  className?: string;
  style?: CSSProperties;
  id?: string;
}

const LoginPage: React.FC<ILoginPageProps> = (props) => {
  return (
    <div
      className={`login_page-layout ${props.className ? props.className : ''}`}
      style={props.style}
      id={props.id}
    >
      <HeaderModule hideMenu hideProfile redirectPathLogo="/" />
      <LoginModule className="login-module-container" />
      <Footer />
    </div>
  );
};

export default LoginPage;
