import React, { CSSProperties, useEffect } from 'react';
import './SearchComponent.scss';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import Box from '@mui/material/Box';
import { useForm } from 'react-hook-form';
import { useDebounce } from 'hooks/useDebounce/useDebounce';

export interface SearchComponentProps {
  children?: any;
  className?: string;
  style?: CSSProperties;
  label?: string;
  placeholder?: string;
  onChange?: (param: string) => void;
}

interface SearchForm {
  search: string;
}

const SearchComponent: React.FC<SearchComponentProps> = (props) => {
  const { register, watch, handleSubmit } = useForm<SearchForm>({
    defaultValues: {
      search: '',
    },
  });
  const searchTerm = watch('search');
  const debouncedSearchTerm = useDebounce({ value: searchTerm, delay: 500 });

  useEffect(
    () => {
      if (debouncedSearchTerm) {
        props.onChange && props.onChange(debouncedSearchTerm);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearchTerm]
  );

  const onSubmit = (data: SearchForm) => {
    props.onChange && props.onChange(data.search);
  };

  return (
    <Box
      className={`search-component-layout ${
        props.className ? props.className : ''
      }`}
      style={props.style}
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <TextField
        label={props.label ? props.label : 'Buscar'}
        variant="outlined"
        placeholder={props.placeholder ? props.placeholder : 'Parámetro...'}
        size="small"
        {...register('search')}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton type="submit" color="primary">
                <SearchRoundedIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default SearchComponent;
