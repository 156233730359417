// @import dependencies
import React, { CSSProperties, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
// @end dependencies

// @import components
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import TextFieldComponent from 'components/commons/TextFieldComponent/TextFieldComponent';
import Box from '@mui/material/Box';
import TextComponent from 'components/commons/Text/TextComponent';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import InputFileComponent from 'components/commons/InputFileComponent/InputFileComponent';
// @end components

// @import types
import * as BannerTypes from 'types/banners/banners.types';
import { CreateBanner } from 'types/banners/banners.types';
// @end types

// @import services
import BannerService from 'services/banner/banner.service';
// @end services

// @import hooks
import { useForm } from 'react-hook-form';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './BannerRegistrationModule.scss';
// @end styles

interface IBannerRegistrationProps {
  className?: string;
  style?: CSSProperties;
  id?: string;
  isUpdate?: boolean;
  initialValues?: Partial<BannerTypes.Banner>;
  onUpdate?: () => void;
}

const BannerRegistrationModule: React.FC<IBannerRegistrationProps> = (
  props
) => {
  const [startDate, setStartDate] = React.useState<moment.Moment | null>(
    moment(props.initialValues?.startDate)
  );
  const [finishDate, setFinishDate] = React.useState<moment.Moment | null>(
    props.initialValues?.startDate
      ? moment(props.initialValues?.finishDate)
      : moment().add(7, 'days')
  );

  const [file, setFile] = useState<File>();

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm<CreateBanner>({
    defaultValues: props.initialValues,
  });

  // @INFO Servicios
  const bannerService = new BannerService();

  const handleChangeStartDate = (newValue: moment.Moment | null) => {
    setStartDate(newValue);
  };
  const handleChangeFinishDate = (newValue: moment.Moment | null) => {
    setFinishDate(newValue);
  };

  const onSubmit = async (data: CreateBanner) => {
    const formData = new FormData();
    if (file) formData.append('file', file);
    if (data.name) formData.append('name', data.name);
    if (data.action) formData.append('action', JSON.stringify(data.action));
    if (data.description) formData.append('description', data.description);
    if (startDate) formData.append('startDate', startDate.toISOString());
    if (finishDate) formData.append('finishDate', finishDate.toISOString());

    if (props.isUpdate && props.initialValues?.id) {
      const response = await bannerService.updateBanner(
        props.initialValues.id,
        formData
      );
      if (response) {
        toast.success('Banner actualizado correctamente');
        props.onUpdate && props.onUpdate();
      }
    } else {
      const response = await bannerService.createBanner(formData);
      if (response) {
        toast.success('Banner creado correctamente');
        reset();
      }
    }
  };

  return (
    <div
      className={`banner_register_module-layout ${
        props.className ? props.className : ''
      }`}
      style={props.style}
      id={props.id}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TextComponent className="text-center" type="h3">
          Ingrese los siguientes datos
        </TextComponent>
        <Box
          component="form"
          sx={{ mt: '20px' }}
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="form-row">
            <div className="col-md-6">
              <div className="form-group">
                <TextFieldComponent
                  type="text"
                  label="Título"
                  fullWidth
                  {...register('name')}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <TextFieldComponent
                  type="text"
                  label="Descripción"
                  fullWidth
                  {...register('description')}
                />
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-6">
              <div className="form-group">
                <DesktopDatePicker
                  label="Fecha de inicio"
                  inputFormat="DD/MM/YYYY"
                  value={startDate}
                  onChange={handleChangeStartDate}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <DesktopDatePicker
                  label="Fecha de finalización"
                  inputFormat="DD/MM/YYYY"
                  value={finishDate}
                  onChange={handleChangeFinishDate}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-6">
              <div className="form-group">
                <TextFieldComponent
                  type="text"
                  label="Url imagen"
                  fullWidth
                  placeholder="https://example.com/image.png"
                  {...register('image')}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <TextFieldComponent
                  type="text"
                  label="Enlace redirección"
                  fullWidth
                  placeholder="https://example.com/yourpath"
                  {...register('action.route')}
                />
              </div>
            </div>
          </div>
          <TextComponent>Ó carga una imagen</TextComponent>
          <InputFileComponent
            src={props.initialValues?.image}
            onLoadFile={(file) => setFile(file)}
          />
          <ButtonComponent type="submit" disabled={!isValid}>
            {props.isUpdate ? 'Actualizar' : 'Registrar'}
          </ButtonComponent>
        </Box>
      </LocalizationProvider>
    </div>
  );
};

export default BannerRegistrationModule;
