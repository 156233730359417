// @import dependencies
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
// @end dependencies

// @import types
import * as DeviceTypes from 'types/device/device.types';
// @end types

// @import components
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import DeviceFormModule from 'components/modules/DeviceFormModule/DeviceFormModule';
// @end components

// @import services
import DeviceService from 'services/device/device.service';
// @end services

// @import styles
import './UpdateDevicesModal.scss';
// @end styles

export interface UpdateDevicesModalProps {
  macAddress: string;
  deviceId: string;
  show: boolean;
  onHide: () => void;
}

const UpdateDevicesModal: React.FC<UpdateDevicesModalProps> = (props) => {
  const [device, setDevice] = useState<DeviceTypes.Device | undefined>(
    undefined
  );

  // @INFO Servicios
  const deviceService = new DeviceService();

  useEffect(() => {
    (async () => {
      const response = await deviceService.getById({
        id: props.deviceId,
      });
      if (!response) {
        return;
      }
      setDevice(response);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdate = async (data: DeviceTypes.ParamsDeviceCreate) => {
    const response = await deviceService.updateDevice({
      id: props.deviceId,
      ...data,
    });
    if (response) {
      toast.success('Actualizado correctamente');
    }
  };

  return (
    <Dialog
      open={props.show}
      onClose={props.onHide}
      fullWidth
      PaperProps={{
        style: { borderRadius: 10 },
      }}
    >
      <DialogTitle fontWeight="bold">Modificar información</DialogTitle>
      <DialogContent>
        {device && (
          <DeviceFormModule
            isUpdate={true}
            initialValues={{
              macAddress: device?.macAddress,
              name: device?.name,
              description: device?.description,
              category: device?.category.id,
              location: {
                latitude: device?.location?.latitude || 0,
                longitude: device?.location?.longitude || 0,
              },
              configuration: {
                sampleTime: device?.configuration.sampleTime,
              },
            }}
            onSubmit={handleUpdate}
          />
        )}
      </DialogContent>
      <DialogActions>
        <ButtonComponent variant="primary" onClick={props.onHide}>
          Close
        </ButtonComponent>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateDevicesModal;
