import store from 'redux/store';
import HTTPUtil from 'utils/http/http.util';

// @import types
import * as MeasuresTypes from 'types/measures/measures.types';
import * as DataTypes from 'types/data/data.types';
// @end types

class MeasuresService{

    private storeObject = store;

    public getDevicesByCategory = async (params: MeasuresTypes.ParamsGetMeasures): Promise<DataTypes.IData[]> => {
        const response = await HTTPUtil.send(
            {
              url: `/measure`,
              method: 'GET',
              data: {
                ...params
              },
              params,
              headers: {
                  Authorization: this.storeObject.getState()?.auth?.token
              }
            },
            {
              alertOnFailed: true,
            }
        );
    
        if (response?.status === 200) {
            return response.data;
        } else {
            return [];
        }
    }
    
    public generateExcel = async (params: MeasuresTypes.ParamsGenerateExcel): Promise<MeasuresTypes.ResponseGenerateExcel | undefined> => {
        const response = await HTTPUtil.send(
            {
              url: `/generate-excel`,
              method: 'GET',
              data: {
                ...params
              },
              params,
              headers: {
                  Authorization: this.storeObject.getState()?.auth?.token
              }
            },
            {
              alertOnFailed: true,
            }
        );
    
        if (response?.status === 200) {
            return response.data;
        } else {
            return undefined;
        }
    }
}

export default MeasuresService;