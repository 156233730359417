// @import dependencies
import React, { CSSProperties, useState, useEffect } from 'react';
import shortid from 'shortid';
// @end dependencies

// @import components
import DateFilterComponent from 'components/commons/DateFilterComponent/DateFilterComponent';
import DeviceChartsModule from 'components/modules/DeviceChartsModule/DeviceChartsModule';
import AppLayout from 'components/layouts/AppLayout/AppLayout';
import TextComponent from 'components/commons/Text/TextComponent';
import ButtonControlComponent from 'components/commons/ButtonControlComponent/ButtonControlComponent';
import RadioControlModule from 'components/modules/RadioControlModule/RadioControlModule';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from 'components/commons/TabPanelComponent/TabPanelComponent';
import DeviceLoggerModule from 'components/modules/DeviceLoggerModule/DeviceLoggerModule';
// @end components

// @import types
import * as DeviceTypes from 'types/device/device.types';
import * as DataTypes from 'types/data/data.types';
import * as StatisticTypes from 'types/statistics/statistics.types';
import { Forecast } from 'types/forecast/forecast.types';
// @end types

// @import services
import DeviceService from 'services/device/device.service';
import MeasuresService from 'services/measures/measures.service';
import { StatisticsService } from 'services/statistics/statistics.service';
import { ForecastService } from 'services/forecastService/forecast.service';
// @end services

// @import hooks
import { useParams } from 'react-router-dom';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './DevicePage.scss';
import LoadingComponent from 'components/commons/LoadingComponent/LoadingComponent';
import PermissionsService from 'services/permissions/permissionsService';
// @end styles

interface IDevicePageProps {
  className?: string;
  style?: CSSProperties;
  id?: string;
}

type TypeTabsDetails =
  | 'data'
  | 'statistics'
  | 'control'
  | 'logger'
  | 'forecast';

interface TabsDetails {
  value: TypeTabsDetails;
  label: string;
}

const DevicePage: React.FC<IDevicePageProps> = (props) => {
  // Fecha inicial de búsqueda
  const auxSinceDate = new Date();
  auxSinceDate.setHours(auxSinceDate.getHours() - 6);
  const auxToDate = new Date();

  // Estados del componente
  const [device, updateDevice] = useState<DeviceTypes.Device | undefined>(
    undefined
  );
  const [sinceDate, updateSinceDate] = useState<Date>(auxSinceDate);
  const [toDate, updateToDate] = useState<Date>(auxToDate);
  const [statistics, updateStatistics] = useState<
    StatisticTypes.Statistic | undefined
  >(undefined);
  const [forecast, updateForecast] = useState<Forecast | undefined>(undefined);
  const [data, updateData] = useState<DataTypes.IData[]>([]);
  const [lastData, updateLastData] = useState<DataTypes.IData | undefined>(
    undefined
  );
  const [loadingFilter, updateLoadingFilter] = useState<boolean>(false);
  const [loadingDownload, updateLoadingDownload] = useState<boolean>(false);
  const [tabs, updateTabs] = useState<TabsDetails[]>([]);
  const [value, setValue] = React.useState(0);
  const [loading, updateLoading] = useState<boolean>(false);
  const params = useParams<{ id: string }>();

  // @INFO Servicios
  const deviceService = new DeviceService();
  const measuresService = new MeasuresService();
  const statisticsService = new StatisticsService();
  const permissionsService = new PermissionsService();
  const forecastService = new ForecastService();

  useEffect(() => {
    if (params.id) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    await Promise.all([getDevice(params.id), getStatistics(params.id)]);
    await getForecast(params.id);
  };

  useEffect(() => {
    if (device?.id) {
      handleSearch(sinceDate, toDate);
      updateLastData(device?.lastData);
      // @INFO: push tabs
      updateTabs([]);
      if (device?.category?.configuration?.hasDataChartsTab) {
        updateTabs((tabs) => [
          ...tabs,
          {
            value: 'data',
            label: 'Datos',
          },
        ]);
      }
      if (device?.category?.configuration?.hasStatisticsTab) {
        updateTabs((tabs) => [
          ...tabs,
          {
            value: 'statistics',
            label: 'Estadísticas',
          },
        ]);
      }
      if (device?.category?.configuration?.hasForecastTab) {
        updateTabs((tabs) => [
          ...tabs,
          {
            value: 'forecast',
            label: 'Pronósticos',
          },
        ]);
      }
      if (
        device?.category?.configuration?.hasControlsTab &&
        permissionsService.check('devices_management_menu')
      ) {
        updateTabs((tabs) => [
          ...tabs,
          {
            value: 'control',
            label: 'Controles',
          },
        ]);
      }
      if (
        device?.category?.configuration?.hasDeviceLoggerTab &&
        permissionsService.check('devices_management_menu')
      ) {
        updateTabs((tabs) => [
          ...tabs,
          {
            value: 'logger',
            label: 'Logger',
          },
        ]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device?.id]);

  const getDevice = async (id: string) => {
    const response = await deviceService.getById({ id });
    updateDevice(response);
  };

  const getStatistics = async (id: string) => {
    updateLoading(true);
    const response = await statisticsService.byDevice(id);
    if (response) {
      updateStatistics(response);
    }
    updateLoading(false);
  };

  const getForecast = async (id: string) => {
    updateLoading(true);
    const response = await forecastService.byDevice(id);
    if (response) {
      updateForecast(response);
    }
    updateLoading(false);
  };

  const handleSearch = async (from: Date, to: Date) => {
    if (!device?.macAddress) return;
    updateSinceDate(from);
    updateToDate(to);
    updateLoadingFilter(true);
    const response = await measuresService.getDevicesByCategory({
      macAddress: device?.macAddress,
      from,
      to,
    });
    if (response && response.length) {
      updateData(response);
    } else {
      updateData([]);
    }
    updateLoadingFilter(false);
  };

  const handleDownload = async (from: Date, to: Date) => {
    if (!device?.macAddress) return;
    updateSinceDate(from);
    updateToDate(to);
    updateLoadingDownload(true);
    const response = await measuresService.generateExcel({
      macAddress: device?.macAddress,
      from,
      to,
    });
    if (response && response?.file) {
      window.open(response.file, '_blank', 'noreferrer');
    }
    updateLoadingDownload(false);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  return (
    <AppLayout>
      <div
        className={`device_page-layout ${
          props.className ? props.className : ''
        }`}
        style={props.style}
        id={props.id}
      >
        <div>
          <TextComponent type="h2">
            {device?.name ? device.name : 'Cargando...'}
          </TextComponent>
          {device?.description ? (
            <TextComponent>{device.description}</TextComponent>
          ) : null}
        </div>
        <div>
          {loading && <LoadingComponent />}
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              variant="scrollable"
              scrollButtons="auto"
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              TabIndicatorProps={{
                style: { transition: 'none' },
              }}
            >
              {tabs.map((tab, index) => (
                <Tab
                  label={tab.label}
                  {...a11yProps(index)}
                  style={{ textTransform: 'none' }}
                  key={shortid.generate()}
                />
              ))}
            </Tabs>
          </Box>
          {tabs.map((tab, index) =>
            tab.value === 'data' ? (
              <TabPanel key={shortid.generate()} value={value} index={index}>
                <DateFilterComponent
                  defaultSince={sinceDate}
                  defaultTo={toDate}
                  onSearch={handleSearch}
                  onDownload={handleDownload}
                  loading={loadingFilter}
                  loadingDownload={loadingDownload}
                  showDownload
                />
                <TextComponent type="h3">Gráficas</TextComponent>
                {device && device?.category?.configuration?.charts?.length && (
                  <DeviceChartsModule
                    eventName={device.configuration.eventData}
                    charts={device.category.configuration.charts}
                    data={data}
                  />
                )}
              </TabPanel>
            ) : tab.value === 'statistics' ? (
              <TabPanel key={shortid.generate()} value={value} index={index}>
                <div className="container-charts-list">
                  {statistics ? (
                    statistics.charts?.map((c) => (
                      <DeviceChartsModule
                        charts={[c]}
                        data={c.data}
                        key={c.name}
                        dateFormat={c.dateFormat}
                        style={{ gridTemplateColumns: '100%' }}
                        onNewData={(data) => updateLastData(data)}
                      />
                    ))
                  ) : (
                    <TextComponent>
                      No hay estadísticas disponibles
                    </TextComponent>
                  )}
                </div>
              </TabPanel>
            ) : tab.value === 'forecast' ? (
              <TabPanel key={shortid.generate()} value={value} index={index}>
                <div className="container-charts-list">
                  {forecast ? (
                    forecast.charts?.map((c) => (
                      <DeviceChartsModule
                        charts={[c]}
                        data={c.data}
                        key={c.name}
                        dateFormat={c.dateFormat}
                        style={{ gridTemplateColumns: '100%' }}
                        onNewData={(data) => updateLastData(data)}
                      />
                    ))
                  ) : (
                    <TextComponent>
                      No hay pronósticos disponibles
                    </TextComponent>
                  )}
                </div>
              </TabPanel>
            ) : tab.value === 'control' ? (
              <TabPanel key={shortid.generate()} value={value} index={index}>
                <div className="container-controls-list">
                  {device?.category?.configuration?.controls
                    ?.filter((c) => c.type === 'button')
                    ?.map((control) => (
                      <ButtonControlComponent
                        event={control.event}
                        label={control.name}
                        control={control}
                        macAddress={device?.macAddress}
                        key={control.event}
                        device={device}
                        lastData={lastData}
                      />
                    ))}
                  {device?.category?.configuration?.controls
                    ?.filter((c) => c.type === 'radio')
                    ?.map((control) => (
                      <RadioControlModule
                        control={control}
                        key={control.event}
                        device={device}
                        lastData={lastData}
                      />
                    ))}
                </div>
              </TabPanel>
            ) : tab.value === 'logger' ? (
              <TabPanel key={shortid.generate()} value={value} index={index}>
                <DeviceLoggerModule macAddress={device?.macAddress} />
              </TabPanel>
            ) : null
          )}
        </div>
      </div>
    </AppLayout>
  );
};

export default DevicePage;
