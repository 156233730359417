// @import dependencies
import React, { CSSProperties } from 'react';
// @end dependencies

// @import components
import TextComponent from 'components/commons/Text/TextComponent';
// @end components

// @import types
import { StoreApp } from 'redux/reducers/index';
// @end types

// @import services
// @end services

// @import hooks
import { useSelector } from 'react-redux';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './NotificationsModule.scss';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import UserService from 'services/user/user.service';
// @end styles
interface INotificationsModule {
  className?: string;
  style?: CSSProperties;
  id?: string;
  isAdmin?: boolean;
}

const NotificationsModule: React.FC<INotificationsModule> = (props) => {
  const user = useSelector((store: StoreApp) => store.auth?.user);
  const [emailNotification, setEmailNotification] = React.useState(false);

  React.useEffect(() => {
    setEmailNotification(
      Boolean(user?.configuration?.receiveNotifications?.email)
    );
  }, [user?.configuration?.receiveNotifications?.email]);

  // @INFO: Services
  const userService = new UserService();

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailNotification(event.target.checked);
    await userService.update({
      configuration: {
        receiveNotifications: {
          email: event.target.checked,
        },
      },
    });
  };

  return (
    <div
      className={`notifications-layout ${
        props.className ? props.className : ''
      }`}
      style={props.style}
      id={props.id}
    >
      <TextComponent type="h3" style={{ textAlign: 'center', width: '100%' }}>
        Notificaciones
      </TextComponent>
      <TextComponent style={{ textAlign: 'center' }}>
        Configuración de notificaciones
      </TextComponent>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch checked={emailNotification} onChange={handleChange} />
          }
          label="Notificaciones por correo"
        />
      </FormGroup>
    </div>
  );
};

export default NotificationsModule;
