// @import utils
import { ITypeThemeApp } from 'types/app/app.types';
import * as VariablesUtil from 'utils/variables/varibales.util';
// @end utils
import { PaletteOptions } from '@mui/material/styles';

class ColorService {
  public getColorFromIndex = (idx: number): string => {
    const length = VariablesUtil.DEFAULT_COLORS.length;
    const L = idx / length;
    let idxColor = Math.round((L - Math.trunc(L)) * length);
    return VariablesUtil.DEFAULT_COLORS[idxColor];
  };

  public getAllColors = (): string[] => {
    return VariablesUtil.DEFAULT_COLORS;
  };

  public getRandomColors = (length: number): string[] => {
    const colors: string[] = [];
    for (let i = 0; i < length; i++) {
      colors.push(this.getColorFromIndex(i));
    }
    return colors;
  };

  /**
   * @INFO Establecer el valor del tema en el localStorage
   * @param theme
   */
  public setThemeColorStorage = (theme: ITypeThemeApp) => {
    localStorage.setItem('theme', theme);
  };

  /**
   * @INFO Obtener el valor del tema desde el localStorage
   */
  public getThemeColorStorage = () => {
    const theme = localStorage.getItem('theme') as ITypeThemeApp | undefined;
    if (theme) {
      return theme;
    } else {
      return undefined;
    }
  };

  public getPalette = (mode: 'light' | 'dark') => {
    const darkPalette: PaletteOptions = {
      mode: 'dark',
      background: {
        default: '#26495e',
        paper: '#00253a',
      },
      primary: {
        main: 'rgb(38, 142, 80)',
      },
      secondary: {
        main: '#FBB03B',
      },
    };
    const lightPalette: PaletteOptions = {
      mode: 'light',
      background: {
        default: '#f8f8f8',
      },
      primary: {
        main: 'rgb(38, 142, 80)',
      },
      secondary: {
        main: '#FBB03B',
      },
    };
    return mode === 'dark' ? darkPalette : lightPalette;
  };
}

export default ColorService;
