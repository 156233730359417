import store from 'redux/store';
import HTTPUtil from 'utils/http/http.util';

// @import types
import { Forecast } from 'types/forecast/forecast.types';
// @end types

export class ForecastService {
  private storeObject = store;

  public byDevice = async (id: string): Promise<Forecast | undefined> => {
    const response = await HTTPUtil.send(
      {
        url: `/forecast/${id}`,
        method: 'GET',
        headers: {
          Authorization: this.storeObject.getState()?.auth?.token,
        },
      },
      {
        alertOnFailed: true,
      }
    );

    if (response?.status === 200) {
      return response.data as any;
    } else {
      return undefined;
    }
  };
}
